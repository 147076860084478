import {
  createStyle,
  flexColumnMixin,
  flexRowMixin,
  marginMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export interface UnitInputStyleProps {
  hasError: boolean;
  titleTextVariant: TextVariant;
}

export const UnitInputStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({})
    },
    title: (props: UnitInputStyleProps) => ({
      ...marginMixin({ bottom: 8, top: 0 }),
      ...themeTextMixin(theme, props.titleTextVariant, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }),
    inputsWrapper: {
      ...flexRowMixin({
        gap: 8
      }),
      width: "100%"
    },
    errorText: (props: UnitInputStyleProps) => ({
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 }),
      color: props.hasError
        ? getThemeStyleColor(theme, StyleVariant.Danger)
        : getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
    }),
    fullWidth: {
      width: "100%"
    }
  }),
  "UnitInput"
);
