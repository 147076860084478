import React from "react";
import { FC, useI18n } from "@laba/react-common";
import { ModelId } from "@laba/ts-common";
import { WhiteLabelConfigBrandDataKey } from "@laba/nexup-api";
import { TextInput } from "../TextInput/TextInput";
import { UploadFileImageInput } from "../UploadFileImageInput/UploadFileImageInput";
import { ThymeleafInput } from "../ThymeleafInput/ThymeleafInput";
import { LatLngInput } from "../LatLngInput/LatLngInput";
import { UrlInput } from "../UrlInput/UrlInput";
import { ColorInput } from "../ColorInput/ColorInput";
import { BooleanInput } from "../BooleanInput/BooleanInput";
import { tk } from "../../pages/NexupAdmin/resources/organization/OrganizationWhiteLabelContent";

export interface WhiteLabelBrandDataFieldsProps {
  source: string;
  organization?: ModelId;
}

const getBrandDataPath = (source: string, key: string) =>
  [source, key].join(".");
export const WhiteLabelBrandDataFields: FC<WhiteLabelBrandDataFieldsProps> = ({
  source,
  organization
}) => {
  const { t } = useI18n();
  return (
    <>
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.browserTitle
        )}
        label={t(tk.fields.whiteLabelConfigBrowserTitle)}
      />
      <UploadFileImageInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.browserIcon
        )}
        label={t(tk.fields.whiteLabelConfigBrowserIcon)}
        organization={organization}
      />
      <TextInput
        source={getBrandDataPath(source, WhiteLabelConfigBrandDataKey.name)}
        label={t(tk.fields.whiteLabelConfigName)}
      />
      <UploadFileImageInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.headerIcon
        )}
        label={t(tk.fields.whiteLabelConfigHeaderIcon)}
        organization={organization}
      />
      <UploadFileImageInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.rightHeaderIcon
        )}
        label={t(tk.fields.whiteLabelConfigRightHeaderIcon)}
        organization={organization}
      />
      <ThymeleafInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.rightHeaderIconUrl
        )}
        label={t(tk.fields.whiteLabelConfigRightHeaderIconURL)}
      />
      <ThymeleafInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.loginTitle
        )}
        label={t(tk.fields.whiteLabelConfigLoginTitle)}
      />
      <ThymeleafInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.loginText
        )}
        label={t(tk.fields.whiteLabelConfigLoginText)}
      />
      <UploadFileImageInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.loginIcon
        )}
        label={t(tk.fields.whiteLabelConfigLoginIcon)}
        organization={organization}
      />
      <LatLngInput
        latSrc={getBrandDataPath(source, WhiteLabelConfigBrandDataKey.latitude)}
        lngSrc={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.longitude
        )}
        label={t(tk.fields.whiteLabelConfigLatLng)}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.shareTitle
        )}
        label={t(tk.fields.whiteLabelConfigShareTitle)}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.shareDescription
        )}
        label={t(tk.fields.whiteLabelConfigShareDescription)}
      />
      <UploadFileImageInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.shareImage
        )}
        label={t(tk.fields.whiteLabelConfigShareImage)}
        organization={organization}
      />
      <UrlInput
        source={getBrandDataPath(source, WhiteLabelConfigBrandDataKey.shareUrl)}
        label={t(tk.fields.whiteLabelConfigShareUrl)}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.installName
        )}
        label={t(tk.fields.whiteLabelConfigInstallName)}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.installShortName
        )}
        label={t(tk.fields.whiteLabelConfigInstallShortName)}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.installDescription
        )}
        label={t(tk.fields.whiteLabelConfigInstallDescription)}
      />
      <ColorInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.installBackground
        )}
        label={t(tk.fields.whiteLabelConfigInstallBackground)}
      />
      <UploadFileImageInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.installIconSmall
        )}
        label={t(tk.fields.whiteLabelConfigInstallIconSmall)}
        organization={organization}
      />
      <UploadFileImageInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.installIconBig
        )}
        label={t(tk.fields.whiteLabelConfigInstallIconBig)}
        organization={organization}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.metadataTitle
        )}
        label={t(tk.fields.whiteLabelConfigMetadataTitle)}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.metadataDescription
        )}
        label={t(tk.fields.whiteLabelConfigMetadataDescription)}
      />
      <BooleanInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.showInstallAppBanner
        )}
        label={t(tk.fields.whiteLabelConfigShowInstallAppBanner)}
      />
      <TextInput
        source={getBrandDataPath(
          source,
          WhiteLabelConfigBrandDataKey.showInstallAppBannerText
        )}
        label={t(tk.fields.whiteLabelConfigShowInstallAppBannerText)}
      />
    </>
  );
};
