import {
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  flexRowMixin,
  hoverMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";
import { TextVariant, StyleVariant } from "model/themeVariant";

interface UrlLinkDynamicStylesProps {
  textVariant: TextVariant;
  disabled: boolean;
  multiline: boolean;
}

interface UrlLinkClassNames {
  root: string;
  text: string;
  underlinedOnHover: string;
}

const useUrlLinkStaticStyles = createStyle(
  (_theme: Theme) => ({
    underlinedOnHover: {
      ...hoverMixin({
        textDecoration: "underline"
      })
    }
  }),
  "UrlLinkStatic"
);

const useUrlLinkDynamicStyles = createStyle(
  (theme: Theme) => ({
    text: (props: UrlLinkDynamicStylesProps) => ({
      ...themeTextMixin(
        theme,
        props.textVariant,
        props.multiline ? undefined : 1
      ),
      ...flexItemMixin({ shrink: 1 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.disabled
          ? ThemeStyleColorVariant.GrayLight
          : ThemeStyleColorVariant.Main
      )
    }),
    root: (props: UrlLinkDynamicStylesProps) => ({
      ...flexRowMixin({
        gap: 4,
        alignItems: props.multiline
          ? FlexAlignItems.FlexStart
          : FlexAlignItems.Center
      }),
      maxWidth: "100%",
      color: getThemeStyleColor(theme, StyleVariant.Primary),
      cursor: "pointer",
      textAlign: "left"
    })
  }),
  "UrlLinkDynamic"
);

export const useUrlLinkStyle = (
  props: UrlLinkDynamicStylesProps
): UrlLinkClassNames => {
  const staticClasses = useUrlLinkStaticStyles();
  const dynamicClasses = useUrlLinkDynamicStyles(props);

  return {
    root: dynamicClasses.root,
    underlinedOnHover: staticClasses.underlinedOnHover,
    text: dynamicClasses.text
  };
};
