import {
  AppAction,
  CaseReducerActions,
  SliceCaseReducers,
  ThunkAction,
  ThunkDispatch
} from "store/types";
import {
  ApiPageRequestResponseBase,
  DefaultError,
  ListQueryParams,
  RequestFailureResponse
} from "@laba/ts-common";
import { SliceEvent } from "providers/GenericSliceProvider/types";
import { ReturnSelectors } from "./getPaginationSelectors";
import { SliceState } from "../types";
import { getPaginationEvents } from "./getPaginationEvents";
import { PaginationStateActions } from "./getPaginationState";

export interface GetPaginationSliceProviderEvents<
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState,
  AppDispatch extends ThunkDispatch<RootState>
> {
  onGetFirstElementListPage: () => SliceEvent<
    S,
    A,
    RootState,
    AppDispatch,
    void
  >;
  onGetNextElementListPage: () => SliceEvent<
    S,
    A,
    RootState,
    AppDispatch,
    void
  >;
}

const sliceSelectorToRootSelector = <PaginationElement, SliceState, RootState>(
  paginationSliceStateSelectors: ReturnSelectors<PaginationElement, SliceState>,
  sliceStateMapper: (state: RootState) => SliceState
): ReturnSelectors<PaginationElement, RootState> => {
  return {
    elementListSelector: (state: RootState) =>
      paginationSliceStateSelectors.elementListSelector(
        sliceStateMapper(state)
      ),
    isDownloadingElementSelector: (state: RootState) =>
      paginationSliceStateSelectors.isDownloadingElementSelector(
        sliceStateMapper(state)
      ),
    elementRequestPageIdSelector: (state: RootState) =>
      paginationSliceStateSelectors.elementRequestPageIdSelector(
        sliceStateMapper(state)
      ),
    elementListNextPageNumberSelector: (state: RootState) =>
      paginationSliceStateSelectors.elementListNextPageNumberSelector(
        sliceStateMapper(state)
      ),
    elementListLastPageNumberSelector: (state: RootState) =>
      paginationSliceStateSelectors.elementListLastPageNumberSelector(
        sliceStateMapper(state)
      ),
    elementTotalSizeSelector: (state: RootState) =>
      paginationSliceStateSelectors.elementTotalSizeSelector(
        sliceStateMapper(state)
      ),
    elementLisHasNextPageSelector: (state: RootState) =>
      paginationSliceStateSelectors.elementLisHasNextPageSelector(
        sliceStateMapper(state)
      ),
    elementHasNoResultSelector: (state: RootState) =>
      paginationSliceStateSelectors.elementHasNoResultSelector(
        sliceStateMapper(state)
      )
  };
};

export const getPaginationSliceProviderEvents = <
  S extends SliceState,
  A extends SliceCaseReducers<S>,
  RootState,
  AppDispatch extends ThunkDispatch<RootState>,
  PaginationElement,
  QueryParams extends ListQueryParams,
  Error extends DefaultError
>(
  sliceActionsMapper: (
    sliceActions: CaseReducerActions<A>
  ) => PaginationStateActions<PaginationElement>,
  paginationSliceStateSelectors: ReturnSelectors<PaginationElement, S>,
  getElementListRequest: (
    queryParams: QueryParams
  ) => Promise<ApiPageRequestResponseBase<PaginationElement, Error>>,
  getElementListRequestQueryParams: (state: RootState) => QueryParams,
  onRequestError: (
    errorResponse: RequestFailureResponse<Error>,
    dispatch: AppDispatch
  ) => Promise<void>
): GetPaginationSliceProviderEvents<S, A, RootState, AppDispatch> => {
  const onGetFirstElementListPage =
    (): SliceEvent<S, A, RootState, AppDispatch, void> =>
    async ({ sliceActions, dispatch, slicer }) => {
      const mappedSelectors = sliceSelectorToRootSelector(
        paginationSliceStateSelectors,
        slicer
      );
      const rootOnGetFirstElementListPage = getPaginationEvents<
        RootState,
        AppDispatch,
        PaginationElement,
        QueryParams,
        Error
      >(
        sliceActionsMapper(sliceActions),
        mappedSelectors,
        getElementListRequestQueryParams,
        onRequestError,
        getElementListRequest
      ).onGetFirstElementListPage;

      await dispatch(
        rootOnGetFirstElementListPage() as unknown as ThunkAction<
          Promise<void>,
          RootState,
          void,
          AppAction
        >
      );
    };
  const onGetNextElementListPage =
    (): SliceEvent<S, A, RootState, AppDispatch, void> =>
    async ({ sliceActions, dispatch, slicer }) => {
      const mappedSelectors = sliceSelectorToRootSelector(
        paginationSliceStateSelectors,
        slicer
      );
      const rootOnGetFirstElementListPage = getPaginationEvents(
        sliceActionsMapper(sliceActions),
        mappedSelectors,
        getElementListRequestQueryParams,
        onRequestError,
        getElementListRequest
      ).onGetNextElementListPage;

      await dispatch(
        rootOnGetFirstElementListPage() as unknown as ThunkAction<
          Promise<void>,
          RootState,
          void,
          AppAction
        >
      );
    };
  return { onGetFirstElementListPage, onGetNextElementListPage };
};
