import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  getClassName,
  pxToRem,
  RadiusVariant,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { ProfileImageSize } from "./ProfileImageInput";

export const useProfileImageInputStaticStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      height: pxToRem(80),
      width: pxToRem(80),
      position: "relative",
      overflow: "hidden",
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    icon: {
      position: "absolute",
      bottom: pxToRem(8),
      right: pxToRem(8)
    },
    img: {
      height: "100%",
      width: "100%"
    },
    [ProfileImageSize.Small]: {
      height: pxToRem(80),
      width: pxToRem(80)
    },
    [ProfileImageSize.Medium]: {
      height: pxToRem(148),
      width: pxToRem(148)
    },
    noImgDefaultText: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      )
    },
    noImgDefaultTextMobileVariant: {
      ...themeTextMixin(theme, TextVariant.Button)
    },
    noImgDefaultTextDesktopVariant: {
      ...themeTextMixin(theme, TextVariant.Subtitle1)
    }
  }),

  "ProfileImageInput"
);

interface UseProfileImageInputStylesProps {
  size: ProfileImageSize;
  isMobile?: boolean;
}

interface UseProfileImageInputStylesReturn {
  root: string;
  icon: string;
  img: string;
  noImgDefaultText: string;
}

export const useProfileImageInputStyles = ({
  size,
  isMobile
}: UseProfileImageInputStylesProps): UseProfileImageInputStylesReturn => {
  const classes = useProfileImageInputStaticStyles();

  return {
    icon: classes.icon,
    img: classes.img,
    root: getClassName(classes.root, classes[size]),
    noImgDefaultText: getClassName(
      classes.noImgDefaultText,
      isMobile
        ? classes.noImgDefaultTextMobileVariant
        : classes.noImgDefaultTextDesktopVariant
    )
  };
};
