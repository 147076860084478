import { FCC } from "components/types";
import React from "react";

interface Props {
  id?: string;
}

export const VideoPlayerContainer: FCC<Props> = ({ children, id }) => {
  return (
    <video-player-container
      id={id}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute"
      }}
    >
      {children}
    </video-player-container>
  );
};
