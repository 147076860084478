import {
  Organization,
  OrganizationHierarchySearchMode,
  OrganizationType
} from "model/resource/entities/organization/organization";
import { getFrontApiUrl, request } from "request/nexupRequest";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import {
  ElementOrArray,
  getKeyObj,
  HttpMethod,
  KeyObj,
  notUndefined,
  Optional,
  RequestFailureStatus
} from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";
import { WhiteLabelConfig } from "model/resource/app/whiteLabelConfig";
import { BackendError } from "model/resource/error/error";
import { produce } from "immer";
import { getProcessedWhiteLabelConfigWithDefaultValues } from "api/public/whiteLabelConfig";

const organizationBasePath = "/organization";

export interface OrganizationListQueryParams extends ListQueryParams {
  type?: ElementOrArray<OrganizationType>;
  content?: string;
  public?: boolean;
  partOf?: ModelId;
  organizationHierarchyMode?: OrganizationHierarchySearchMode;
  active?: boolean;
}

export const OrganizationListQueryParamsKey: KeyObj<OrganizationListQueryParams> =
  getKeyObj(createHydratedMock<OrganizationListQueryParams>());

// TODO delete patch when back parse an format WhiteLabelConfig in organization correctly
export const fixParseOrganization = (
  organization?: Organization
): Optional<Organization> => {
  return produce(organization, draftOrganization => {
    if (draftOrganization === undefined) return;
    const config = draftOrganization.whiteLabelConfig as unknown;
    if (typeof config === "string") {
      const whiteLabelConfigJson = JSON.parse(config);
      draftOrganization.whiteLabelConfig =
        getProcessedWhiteLabelConfigWithDefaultValues(whiteLabelConfigJson);
    }
  });
};

export const fixFormatOrganization = (
  organization: Organization
): Organization => {
  return produce(organization, draftOrganization => {
    const processedWhiteLabel = draftOrganization.whiteLabelConfig
      ? getProcessedWhiteLabelConfigWithDefaultValues(
          draftOrganization.whiteLabelConfig
        )
      : undefined;
    const config = processedWhiteLabel as unknown;
    if (typeof config !== "string") {
      draftOrganization.whiteLabelConfig = JSON.stringify(
        config
      ) as unknown as WhiteLabelConfig;
    }
  });
};

export const getOrganizationList = async (
  params: OrganizationListQueryParams
): Promise<ApiPageRequestResponse<Organization>> => {
  const response: ApiPageRequestResponse<Organization> = await request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(organizationBasePath),
    params
  });
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  return produce(response, draftResponse => {
    draftResponse.data.entries = draftResponse.data.entries
      .map(fixParseOrganization)
      .filter(notUndefined);
  });
};

export const getOrganization = async (
  id: ModelId
): Promise<ApiRequestResponse<Organization>> => {
  const response: ApiRequestResponse<Organization> = await request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${organizationBasePath}/${id}`)
  });
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  return produce(response, draftResponse => {
    const parsedOrganization = fixParseOrganization(draftResponse.data);
    if (parsedOrganization) {
      draftResponse.data = parsedOrganization;
    }
  });
};

export const createOrganization = async (
  data: Organization
): Promise<ApiRequestResponse<Organization>> => {
  const response = await request<Organization, BackendError>({
    method: HttpMethod.POST,
    url: getFrontApiUrl(organizationBasePath),
    data: fixFormatOrganization(data)
  });
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  return produce(response, draftResponse => {
    const parsedOrganization = fixParseOrganization(draftResponse.data);
    if (parsedOrganization) {
      draftResponse.data = parsedOrganization;
    }
  });
};

export const updateOrganization = async (
  data: Organization
): Promise<ApiRequestResponse<Organization>> => {
  const response = await request<Organization, BackendError>({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${organizationBasePath}/${data.id}`),
    data: fixFormatOrganization(data)
  });
  if (response.failureStatus === RequestFailureStatus.Failure) return response;
  return produce(response, draftResponse => {
    const parsedOrganization = fixParseOrganization(draftResponse.data);
    if (parsedOrganization) {
      draftResponse.data = parsedOrganization;
    }
  });
};
