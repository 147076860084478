import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  ResourceType,
  AppointmentStatus,
  KnownCodeSystemSystem,
  Practitioner,
  AppointmentKey,
  PractitionerListQueryParamsKey,
  Patient,
  PatientListQueryParamsKey,
  HealthcareService,
  HealthcareServiceListQueryParamsKey,
  AppointmentDefinition,
  AppointmentDefinitionListQueryParamsKey,
  CodeSystemCode,
  CancellationReason,
  AttachmentKey,
  CodingKey
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import {
  appointmentDefinitionOptionText,
  patientOptionText,
  practitionerOptionText,
  healthcareServiceOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";

export const tk = tkCP.adminPage[ResourceType.Appointment];
export const AppointmentMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);

  const consultationReasonSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.EncounterConsultationReason,
    workspaceOrganizationId
  );

  const scheduleSpecialitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ScheduleSpeciality,
    workspaceOrganizationId
  );

  const appointmentTagSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.AppointmentTag,
    workspaceOrganizationId
  );
  return (
    <>
      <TextInput disabled source={AppointmentKey.id} label={t(tk.fields.id)} />
      <ReferenceResourceInput<Practitioner>
        resourceType={ResourceType.Practitioner}
        label={t(tk.fields.practitioner)}
        source={AppointmentKey.practitioner}
        optionTextKey={practitionerOptionText}
        extraFilters={{
          [PractitionerListQueryParamsKey.organization]: workspaceOrganizationId
        }}
      />
      <ReferenceResourceInput<Patient>
        resourceType={ResourceType.Patient}
        label={t(tk.fields.patient)}
        source={AppointmentKey.patient}
        optionTextKey={patientOptionText}
        extraFilters={{
          [PatientListQueryParamsKey.organization]: workspaceOrganizationId
        }}
      />
      <EnumSelectorInput
        source={AppointmentKey.status}
        label={t(tk.fields.status)}
        enumValue={AppointmentStatus}
        resettable
      />
      <ReferenceResourceInput<AppointmentDefinition>
        resourceType={ResourceType.AppointmentDefinition}
        label={t(tk.fields.appointmentDefinition)}
        source={AppointmentKey.appointmentDefinition}
        optionTextKey={appointmentDefinitionOptionText}
        extraFilters={{
          [AppointmentDefinitionListQueryParamsKey.organization]:
            workspaceOrganizationId
        }}
      />
      <ReferenceResourceInput<HealthcareService>
        resourceType={ResourceType.HealthcareService}
        label={t(tk.fields.healthcareService)}
        source={AppointmentKey.healthcareService}
        optionTextKey={healthcareServiceOptionText}
        extraFilters={{
          [HealthcareServiceListQueryParamsKey.organization]:
            workspaceOrganizationId
        }}
      />
      <DateTimeInput
        source={AppointmentKey.startDate}
        label={t(tk.fields.startDate)}
      />
      <DateTimeInput
        source={AppointmentKey.admissionDate}
        label={t(tk.fields.admissionDate)}
      />
      <DateTimeInput
        source={AppointmentKey.cancelDate}
        label={t(tk.fields.cancelDate)}
      />
      <DateTimeInput
        source={AppointmentKey.fulfilledDate}
        label={t(tk.fields.fulfilledDate)}
      />
      <CodeSystemSelectorInput
        source={AppointmentKey.speciality}
        label={t(tk.fields.speciality)}
        codeSystem={scheduleSpecialitySystem}
        organizationId={workspaceOrganizationId}
        withCreate
      />
      <ArrayInput
        source={AppointmentKey.attachment}
        label={t(tk.fields.attachment)}
      >
        <TextInput
          source={AttachmentKey.name}
          label={t(tk.fields.attachamentName)}
        />
        <UploadFileImageInput
          source={AttachmentKey.url}
          label={t(tk.fields.attachmentUrl)}
          organization={workspaceOrganizationId}
        />
      </ArrayInput>
      <ArrayInput source={AppointmentKey.tagList} label={t(tk.fields.tagList)}>
        <TextInput source={CodingKey.code} label={t(tk.fields.tagListCode)} />
        <CodeSystemSelectorInput
          source={CodingKey.system}
          label={t(tk.fields.tagListSystem)}
          codeSystem={appointmentTagSystem}
          organizationId={workspaceOrganizationId}
          withCreate
        />
      </ArrayInput>
      <NumberInput
        source={AppointmentKey.minutesDuration}
        label={t(tk.fields.minutesDuration)}
        min={0}
        step={1}
      />
      <DateTimeInput
        source={AppointmentKey.endDate}
        label={t(tk.fields.endDate)}
        disabled
      />
      <BooleanInput
        source={AppointmentKey.overbooked}
        label={t(tk.fields.overbooked)}
      />
      <TextInput
        source={AppointmentKey.patientAccessInfo.link}
        label={t(tk.fields.patientAccessInfoLink)}
        disabled
      />
      <TextInput source={AppointmentKey.comment} label={t(tk.fields.comment)} />
      <TextInput
        source={AppointmentKey.patientInstruction}
        label={t(tk.fields.patientInstruction)}
      />
      <CodeSystemSelectorInput
        source={AppointmentKey.consultationReason.thisKey}
        label={t(tk.fields.consultationReason)}
        codeSystem={consultationReasonSystem}
        organizationId={workspaceOrganizationId}
        format={(formValue?: CodeSystemCode) => {
          return formValue?.code;
        }}
        parse={(selectValue?: string) => {
          const concept = consultationReasonSystem?.concept?.find(
            conceptElement => conceptElement.code === selectValue
          );
          if (concept) {
            return {
              code: concept.code,
              name: concept.display,
              system: consultationReasonSystem?.system
            };
          }
          return undefined;
        }}
      />
      <EnumSelectorInput
        source={AppointmentKey.cancellationReason}
        label={t(tk.fields.cancellationReason)}
        enumValue={CancellationReason}
        disabled
      />
    </>
  );
};
