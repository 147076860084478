export const organization = {
  title: "Organizaciones",
  exportCompleteOrganization: "Configuracion completa",
  organizationConfigFilename: "Configuracion completa {{organization}}",
  organizationConfigError:
    "Hubo un error en la configuracion completa de la organizacion",
  filters: {
    partOf: "Parte de",
    public: "Publica",
    active: "Activa",
    type: "Tipo"
  },
  fields: {
    id: "Id",
    name: "Nombre",
    type: "Tipo",
    photo: "Foto",
    photoName: "Nombre de la imagen",
    partOf: "Parte de",
    hospitalizationType: "Tipo de hospitalizacion",
    serviceList: "Servicios",
    serviceListElement: "Servicio",
    country: "Pais",
    timezone: "zona horaria (Z o +/-X:XX)",
    timezoneIana: "zona horaria iana",
    public: "Publica",
    active: "Activa",
    ipWhitelist: "Ip o Dns Habilitados",
    ipWhitelistElement: "Ip o Dns",
    specialtyList: "Especialidades",
    specialtyElement: "Especialidad",
    interestList: "Intereses",
    interestElement: "Interés",
    interestObservation: "Intereses (observaciones)",

    medicalRequestExpirationTimeList:
      "Horarios de expiracion indicaciones (HH:mm:ss)",
    medicalRequestExpirationTimeListElement: "Horario",
    medicalRequestExpirationWarningTimeList:
      "Horarios de pre-expiracion indicaciones (HH:mm:ss)",
    medicalRequestExpirationWarningTimeListElement: "Horario",
    dailyEvolutionClosePeriod: "Ventana de cierre evoluciones diarias (seg)",
    dailyEvolutionTitleTemplate: "Formato titulo evoluciones diarias",

    emailConfig: "Emails Automaticos",
    phoneConfig: "Whatsapp Automaticos",
    reportConfig: "Configuracion reportes",

    domainUrlList: "Dominios asociados",
    domainUrlListElement: "Dominio",
    dashboardList: "Tableros asociados",
    dashboardTitle: "Titulo",
    dashboardUrl: "Url",
    dashboardIcon: "Icono",
    dashboardCode: "Codigo",
    whiteLabelConfigOrganization:
      "Organizacion marca blanca (Luego de crear hay que ingresar a editar y guardar para generacion automatica)",
    templateOrganizationId: "Organizacion plantilla para organizaciones hijas",
    whiteLabelConfigBrowserTitle: "Titulo para la tab del navegador",
    whiteLabelConfigBrowserIcon: "Ícono para la tab del navegador",
    whiteLabelConfigName: "Nombre de la marca",
    whiteLabelConfigHeaderIcon: "Ícono para el header",
    whiteLabelConfigMaxDateInterval: "Intervalo de fecha maximo",
    whiteLabelConfigBottomDrawerButtonTitle:
      "Titulo del botón inferior del drawer",
    whiteLabelConfigShowBottomDrawerButton: "Ruta de boton inferior del drawer",
    whiteLabelConfigPhoneConfigSection: "Configuración de teléfonos",
    whiteLabelConfigPhoneConfigDefaultAreaCode: "Código de área por defecto",
    whiteLabelConfigPhoneConfigExcludedCountries:
      "Listado de código de área excluidos",
    whiteLabelConfigPhoneConfigPreferredCountries:
      "Listado de código de área favoritos",
    whiteLabelConfigPhoneConfigCountryAreaCode: "Código de área del país",
    whiteLabelConfigRightHeaderIcon:
      "Ícono que se ubica a la derecha del header",
    whiteLabelConfigRightHeaderIconURL:
      "URL al que redirige el click sobre el icono de la derecha del header",
    whiteLabelConfigLoginTitle: "Titulo para la pantalla de login",
    whiteLabelConfigLoginText: "Texto para la pantalla de login",
    whiteLabelConfigLoginIcon: "Icono para la pantalla de login",
    whiteLabelConfigLatLng: "Posicion por defecto",
    whiteLabelConfigShareTitle: "Titulo compartir por redes",
    whiteLabelConfigShareDescription: "Descripcion compartir por redes",
    whiteLabelConfigShareImage:
      "Imagen (png o jpg, 300x200 o mas, 300kb o menos) compartir por redes",
    whiteLabelConfigShareUrl: "Url compartir por redes",
    whiteLabelConfigInstallName: "Nombre app instalacion",
    whiteLabelConfigInstallShortName: "Nombre icono app instalacion",
    whiteLabelConfigInstallDescription: "Descripcion app instalacion",
    whiteLabelConfigInstallBackground: "Color fondo app instalacion",
    whiteLabelConfigInstallIconSmall:
      "Icono chico (png de 192x192) app instalacion",
    whiteLabelConfigInstallIconBig:
      "Icono grande (png de 512x512) app instalacion",
    whiteLabelConfigMetadataTitle: "Titulo indexacion google",
    whiteLabelConfigMetadataDescription: "Descripcion indexacion google",
    whiteLabelConfigShowInstallAppBanner: "Mostrar popup instalacion en app",
    whiteLabelConfigShowInstallAppBannerText: "Texto popup instalacion en app",
    whiteLabelConfigSection: "Secciones Habilitadas",
    whiteLabelConfigSectionItem: "Seccion",
    whiteLabelConfigDisableLogin: "Deshabilitar login",
    whiteLabelConfigCredentialColor: "Color/imagen de fondo de la credencial",
    whiteLabelConfigCredentialLogo: "Logo principal de la credencial (izq)",
    whiteLabelConfigCredentialIcon: "Ícono de la credencial (der)",
    whiteLabelConfigDesktopHomeBanner: "Banners home Desktop",
    whiteLabelConfigMobileHomeBanner: "Banners home Mobile",
    whiteLabelConfigBannerItemUrl: "Url Banner",
    whiteLabelConfigBannerIsVideo: "Url es video",
    whiteLabelConfigBannerTitle: "Titulo Banner",
    whiteLabelConfigBannerPrincipalText: "Texto Principal Banner",
    whiteLabelConfigBannerSecondaryText: "Texto Secundario Banner",
    whiteLabelConfigBannerButtonText: "Texto Boton Banner",
    whiteLabelConfigBannerButtonUrl: "Url Boton Banner",
    whiteLabelConfigBannerButtonType: "Tipo Boton Banner",
    whiteLabelConfigBannerButtonStyle: "Estilo Boton Banner",
    whiteLabelConfigDesktopHomeItem: "Items home Desktop",
    whiteLabelConfigMobileHomeItem: "Items home Mobile",
    whiteLabelConfigDesktopHomeColumns: "Columnas home Desktop",
    whiteLabelConfigMobileHomeColumns: "Columnas home Mobile",
    whiteLabelConfigItemType: "Tipo de item",
    whiteLabelConfigItemProperties: "Propiedades item",
    whiteLabelConfigItemPropertyType: "Tipo",
    whiteLabelConfigItemPropertyValue: "Valor",
    whiteLabelConfigDirectoryTitle: "Titulo cartilla",
    whiteLabelConfigDirectoryMap: "Mapa habilitado en cartilla",
    whiteLabelConfigDirectoryPlanFilter: "Filtro por plan en cartilla",
    whiteLabelConfigDirectorySpecialityFilter:
      "Filtro por especialidad en cartilla",
    whiteLabelConfigDirectoryCityFilter: "Filtro por ciudad en cartilla",

    whiteLabelConfigTheme: "Tema visual",
    whiteLabelConfigThemeStylePrimary: "Primary",
    whiteLabelConfigThemeStyleSecondary: "Secondary",
    whiteLabelConfigThemeStyleDanger: "Danger",
    whiteLabelConfigThemeStyleWarning: "Warning",
    whiteLabelConfigThemeStyleSuccess: "Success",
    whiteLabelConfigThemeStyleInfo: "Info",
    whiteLabelConfigThemeStyleBlackWhite: "BlackWhite",
    whiteLabelConfigThemeStyleMain: "Main Color",
    whiteLabelConfigThemeStyleBackground: "Background Color",
    whiteLabelConfigThemeStyleLight: "Light Color",
    whiteLabelConfigThemeStyleDark: "Dark Color",
    whiteLabelConfigThemeStyleContrast: "Contrast Color",
    whiteLabelConfigThemeStyleGrayDark: "GrayDark Color",
    whiteLabelConfigThemeStyleGrayMedium: "GrayMedium Color",
    whiteLabelConfigThemeStyleGrayLight: "GrayLight Color",
    whiteLabelConfigThemeStyleDisabled: "Disabled Color",
    whiteLabelConfigThemeStyleDisabledBackground: "Disabled Background Color",
    whiteLabelConfigThemeTextH1: "H1",
    whiteLabelConfigThemeTextH2: "H2",
    whiteLabelConfigThemeTextH3: "H3",
    whiteLabelConfigThemeTextH4: "H4",
    whiteLabelConfigThemeTextH5: "H5",
    whiteLabelConfigThemeTextH6: "H6",
    whiteLabelConfigThemeTextSubtitle1: "Subtitle1",
    whiteLabelConfigThemeTextSubtitle2: "Subtitle2",
    whiteLabelConfigThemeTextBody1: "Body1",
    whiteLabelConfigThemeTextBody2: "Body2",
    whiteLabelConfigThemeTextButton: "Button",
    whiteLabelConfigThemeTextCaption: "Caption",
    whiteLabelConfigThemeTextOverline: "Overline",
    whiteLabelConfigThemeTextFontFamily: "Familia de fuente",
    whiteLabelConfigThemeTextFontFamilyElement: "Fuente",
    whiteLabelConfigThemeTextFontWeight: "Peso",
    whiteLabelConfigThemeTextFontSize: "Tamaño",
    whiteLabelConfigThemeTextFontCase: "Mayuscula",
    whiteLabelConfigThemeTextFontSpacing: "Espaciado",
    whiteLabelConfigThemeRadiusLarge: "Radio grande",
    whiteLabelConfigThemeRadiusMedium: "Radio mediano",
    whiteLabelConfigThemeRadiusSmall: "Radio chico",
    whiteLabelConfigThemeElevation0: "Elevacion 0",
    whiteLabelConfigThemeElevation1: "Elevacion 1",
    whiteLabelConfigThemeElevation2: "Elevacion 2",
    whiteLabelConfigThemeElevation3: "Elevacion 3",
    whiteLabelConfigThemeElevation4: "Elevacion 4",
    whiteLabelConfigThemeElevation6: "Elevacion 6",
    whiteLabelConfigThemeElevation8: "Elevacion 8",
    whiteLabelConfigThemeElevation9: "Elevacion 9",
    whiteLabelConfigThemeElevation12: "Elevacion 12",
    whiteLabelConfigThemeElevation16: "Elevacion 16",
    whiteLabelConfigThemeElevation24: "Elevacion 24",
    whiteLabelConfigThemeElevationOffsetX: "offset x",
    whiteLabelConfigThemeElevationOffsetY: "offset y",
    whiteLabelConfigThemeElevationBlurRadius: "radio blue",
    whiteLabelConfigThemeElevationTransitionS: "translacion S",
    whiteLabelConfigThemeElevationColor: "color",

    phone: "Telefonos",
    phoneValue: "Numero",
    phoneType: "Typo",
    phoneRole: "Uso",
    email: "Email",
    emailValue: "Direccion",
    emailType: "Tipo",
    emailRole: "Uso",
    address: "Direccion",
    addressStreet: "Calle",
    addressStreetNumber: "Numero",
    addressDirections: "Piso / Unidad / Direcciones extras",
    addressCity: "Ciudad",
    addressState: "Provincia / Estado",
    addressCountry: "Pais",
    addressPostalCode: "Codigo Postal",

    whiteLabelConfigThemeMainGradient: "Main Gradient",
    whiteLabelConfigThemeGradient1: "Gradient 1",
    whiteLabelConfigThemeGradient2: "Gradient 2",
    whiteLabelConfigThemeGradient3: "Gradient 3",
    whiteLabelConfigThemeGradient4: "Gradient 4",
    whiteLabelConfigThemeGradientColorList: "Gradient color list",
    whiteLabelConfigThemeGradientColorListItem: "Gradient color",
    whiteLabelConfigThemeGradientType: "Gradient type",
    whiteLabelConfigThemeGradientLinearDirection: "Gradient linear direction",
    whiteLabelConfigThemeGradientRadialShape: "Gradient radial shape",
    whiteLabelConfigThemeGradientRadialSize: "Gradient radial size",
    whiteLabelConfigThemeGradientRadialPosition: "Gradient radial position"
  },
  tabs: {
    organization: "Organizacion",
    clinicHistory: "Historia clínica",
    emails: "Mails",
    phones: "Whatsapp",
    reports: "Reportes",
    whiteLabel: "Marca Blanca",
    whiteLabelDesktop: "Home Desktop",
    whiteLabelMobile: "Home Mobile",
    whiteLabelTheme: "Theme",
    whiteLabelHisBrandData: "Datos de la marca del portal médico",
    whiteLabelPatientBrandData: "Datos de la marca del portal paciente",
    user: "Usuario",
    dashboards: "Tableros"
  }
};
