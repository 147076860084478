import React, { useMemo, useState } from "react";
import { FC, getClassName, IconC, ReactElement } from "@laba/react-common";
import {
  SearchBar,
  SearchBarProps
} from "components/inputs/SearchBar/SearchBar";
import { countBy, groupBy, isEmpty, omit } from "lodash-es";
import { MenuParentNode, MenuType } from "components/menu/menuTreeTypes";
import { PopupMenuDropdown } from "components/menu/PopupMenuDropdown/PopupMenuDropdown";
import { PopoverMenuDropdown } from "components/menu/PopoverMenuDropdown/PopoverMenuDropdown";
import { Noop } from "@laba/ts-common";
import {
  useFiltersHeaderInputsGrowStyle,
  useFiltersHeaderStyle
} from "./useFiltersHeaderStyle";
import { FiltersButton } from "./FiltersButton/FiltersButton";
import {
  FiltersHeaderInput,
  FiltersHeaderInputsRow
} from "./FiltersHeaderInputsRow/FiltersHeaderInputsRow";
import { FiltersHeaderInputGrow, FiltersHeaderInputRow } from "./types";

export interface FiltersHeaderProps {
  isMobile?: boolean;
  className?: string;
  searchBarProps?: SearchBarProps;
  filtersButtonText?: string;
  onFiltersButtonClick?: Noop;
  isExpanded?: boolean;
  topActionButtonContainer?: ReactElement;
  bottomActionButtonContainer?: ReactElement;
  showAddButton?: boolean;
  addButtonClassname?: string;
  addButtonText?: string;
  addButtonCategories?: MenuParentNode[];
  addButtonNoCategoriesText?: string;
  addButtonIcon?: IconC;
  showAddButtonSearchBar?: boolean;
  addButtonSearchText?: string;
  onChangeAddButtonSearchText?: (newSearchText?: string) => void;
  filtersInputList: FiltersHeaderInput[];
  usePopoverOnMobile?: boolean;
  popupTitle?: string;
  onClickAdd?: Noop;
  searchBarGrow?: FiltersHeaderInputGrow;
}

const isFiltersRowEmpty = (filtersRow?: FiltersHeaderInput[]): boolean =>
  isEmpty(filtersRow?.filter(input => input.component));

export const FiltersHeader: FC<FiltersHeaderProps> = ({
  isMobile,
  className,
  searchBarProps,
  filtersButtonText,
  onFiltersButtonClick,
  topActionButtonContainer,
  bottomActionButtonContainer,
  isExpanded = false,
  showAddButton,
  addButtonClassname,
  addButtonText,
  addButtonCategories = [],
  addButtonNoCategoriesText,
  addButtonIcon,
  showAddButtonSearchBar,
  addButtonSearchText,
  onChangeAddButtonSearchText,
  filtersInputList,
  usePopoverOnMobile = false,
  popupTitle,
  onClickAdd,
  searchBarGrow
}) => {
  const withSearchBar = searchBarProps !== undefined;
  const classes = useFiltersHeaderStyle();
  const inputGrowClasses = useFiltersHeaderInputsGrowStyle();
  const [isSearchBarFocused, setIsSearchBarFocused] = useState<boolean>(false);

  const filtersData = useMemo(
    () => groupBy(filtersInputList, item => item.row),
    [filtersInputList]
  );
  const zeroRowInputs = useMemo(
    () => filtersData[FiltersHeaderInputRow.Zero],
    [filtersData]
  );
  const firstRowInputs = useMemo(
    () => filtersData[FiltersHeaderInputRow.First],
    [filtersData]
  );

  const appliedFiltersCount = useMemo(
    () =>
      countBy(filtersInputList, ({ row, isFilterApplied }) => {
        if (
          (!isMobile && row === FiltersHeaderInputRow.First) ||
          (isMobile && row === FiltersHeaderInputRow.Zero)
        )
          return;
        return isFilterApplied;
      }).true,
    [filtersInputList, isMobile]
  );

  const showSearchBarOnly = isMobile && isSearchBarFocused;
  const uniqueCategory: MenuParentNode[] = useMemo(
    () =>
      onClickAdd
        ? [
            {
              type: MenuType.Parent,
              children: [
                {
                  type: MenuType.Leaf,
                  title: "",
                  onClick: onClickAdd
                }
              ]
            }
          ]
        : [],
    [onClickAdd]
  );

  return (
    <div className={getClassName(classes.container, className)}>
      <div className={classes.row}>
        {showAddButton &&
          addButtonText &&
          !showSearchBarOnly &&
          (!isMobile || usePopoverOnMobile ? (
            <PopoverMenuDropdown
              isMobile={isMobile}
              buttonClassName={addButtonClassname}
              buttonTitle={addButtonText}
              categories={onClickAdd ? uniqueCategory : addButtonCategories}
              icon={addButtonIcon}
              showSearchBar={showAddButtonSearchBar}
              searchText={addButtonSearchText}
              onChangeSearchText={onChangeAddButtonSearchText}
              noCategoriesText={addButtonNoCategoriesText}
            />
          ) : (
            <PopupMenuDropdown
              popupTitle={popupTitle}
              buttonClassName={addButtonClassname}
              buttonTitle={addButtonText}
              categories={onClickAdd ? uniqueCategory : addButtonCategories}
              icon={addButtonIcon}
              showSearchbar={showAddButtonSearchBar}
              searchText={addButtonSearchText}
              onChangeSearchText={onChangeAddButtonSearchText}
              noCategoriesText={addButtonNoCategoriesText}
            />
          ))}
        {withSearchBar && searchBarProps && (
          <SearchBar
            {...searchBarProps}
            className={
              inputGrowClasses[searchBarGrow ?? FiltersHeaderInputGrow.Grow2]
            }
            onSubmit={v => {
              setIsSearchBarFocused(false);
              searchBarProps.onSubmit?.(v);
            }}
            onFocus={() => setIsSearchBarFocused(true)}
            onBlur={() => setIsSearchBarFocused(false)}
          />
        )}
        {!withSearchBar && isMobile && (
          <FiltersHeaderInputsRow inputs={zeroRowInputs} />
        )}
        {!showSearchBarOnly && (
          <>
            {!isMobile && <FiltersHeaderInputsRow inputs={firstRowInputs} />}
            {topActionButtonContainer}
            {onFiltersButtonClick && filtersButtonText && (
              <FiltersButton
                isMobile={isMobile}
                className={classes.filtersButton}
                appliedFiltersCount={appliedFiltersCount}
                filtersText={filtersButtonText}
                onClick={onFiltersButtonClick}
                isExpanded={isExpanded}
              />
            )}
          </>
        )}
      </div>
      {!showSearchBarOnly && (
        <>
          {bottomActionButtonContainer}
          {isExpanded && (
            <div className={classes.bottomFiltersContainer}>
              {isMobile &&
                firstRowInputs &&
                !isFiltersRowEmpty(firstRowInputs) && (
                  <div className={classes.row}>
                    <FiltersHeaderInputsRow inputs={firstRowInputs} />
                  </div>
                )}
              {Object.values(
                omit(FiltersHeaderInputRow, [
                  FiltersHeaderInputRow.First,
                  FiltersHeaderInputRow.Zero
                ])
              ).map((row, idx) => {
                const rowInputs = filtersData[row];
                return isEmpty(rowInputs) ||
                  isFiltersRowEmpty(rowInputs) ? null : (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={idx} className={classes.row}>
                    <FiltersHeaderInputsRow inputs={rowInputs} />
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
