import React, { MouseEvent } from "react";
import { FC, getClassName, useLongPress } from "@laba/react-common";
import {
  ButtonBase as MuiButtonBase,
  ButtonBaseProps as MuiButtonBaseProps
} from "@material-ui/core";
import {
  useButtonBaseAnchorStyles,
  useSelectableButtonBaseStyles
} from "components/buttons/ButtonBase/useButtonBaseStyles";
import { Noop } from "@laba/ts-common";

export interface ButtonBaseProps extends MuiButtonBaseProps {
  aHref?: string;
  anchorClassName?: string;
  selectable?: boolean;
  onLongPress?: Noop;
  enableLongPress?: boolean;
}

export const ButtonBase: FC<ButtonBaseProps> = ({
  aHref,
  anchorClassName,
  onLongPress,
  selectable = false,
  enableLongPress = false,
  ...rest
}) => {
  const anchorClasses = useButtonBaseAnchorStyles();
  const selectableButtonBase = useSelectableButtonBaseStyles();

  const { handlers } = useLongPress(rest.onClick, onLongPress);

  const handlePreventDefault = (e: MouseEvent) => {
    e.preventDefault();
  };

  if (aHref !== undefined) {
    return (
      <a
        className={getClassName(anchorClasses.anchor, anchorClassName)}
        href={aHref}
        onClick={handlePreventDefault}
        onContextMenu={handlePreventDefault}
      >
        <MuiButtonBase
          {...rest}
          {...(enableLongPress ? handlers : {})}
          classes={selectable ? selectableButtonBase : undefined}
        />
      </a>
    );
  }

  return (
    <MuiButtonBase
      {...rest}
      {...(enableLongPress ? handlers : {})}
      onContextMenu={handlePreventDefault}
      classes={selectable ? selectableButtonBase : undefined}
    />
  );
};
