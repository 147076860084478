import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  AppointmentDefinition,
  AppointmentDefinitionKey,
  AppointmentDefinitionListQueryParamsKey,
  AppointmentDefinitionNotificationChannel,
  AppointmentType,
  getModelReferenceId,
  KnownCodeSystemSystem,
  ModelReference,
  Organization,
  ResourceType,
  AppointmentDefinitionTemplateKey,
  AppointmentDefinitionTemplateCode
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { ThymeleafInput } from "components/generic/ThymeleafInput/ThymeleafInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { appointmentDefinitionOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { ActivityDefinitionCommonInput } from "components/generic/ActivityDefinition/ActivityDefinitionCommonInput/ActivityDefinitionCommonInput";

export const tk = tkCP.adminPage[ResourceType.AppointmentDefinition];
export const AppointmentDefinitionContent: FC = () => {
  const { t } = useI18n();

  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    AppointmentDefinitionKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;
  const codeSystemSystemSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.CodeSystemSystem,
    organizationId
  );

  return (
    <>
      <ActivityDefinitionCommonInput organizationId={organizationId} />
      <EnumSelectorInput
        source={AppointmentDefinitionKey.code}
        label={t(tk.fields.code)}
        enumValue={AppointmentType}
        withCreate
      />
      <NumberInput
        source={AppointmentDefinitionKey.slotConfig.minDuration}
        label={t(tk.fields.slotConfigMinDuration)}
        min={0}
        step={1}
      />
      <ArrayInput
        source={AppointmentDefinitionKey.slotConfig.durationList}
        label={t(tk.fields.slotConfigDurationList)}
      >
        <NumberInput
          source=""
          label={t(tk.fields.slotConfigDurationListElement)}
          min={0}
          step={1}
        />
      </ArrayInput>
      <CodeSystemSelectorInput
        source={AppointmentDefinitionKey.consultationReasonSystem}
        label={t(tk.fields.consultationReasonSystem)}
        codeSystem={codeSystemSystemSystem}
        withCreate
        organizationId={organizationId}
      />
      <ArrayInput
        source={AppointmentDefinitionKey.availableServiceList}
        label={t(tk.fields.availableServiceList)}
      >
        <ReferenceResourceInput<AppointmentDefinition>
          source=""
          label={t(tk.fields.availableServiceListElement)}
          resourceType={ResourceType.HealthcareService}
          optionTextKey={appointmentDefinitionOptionText}
          extraFilters={{
            [AppointmentDefinitionListQueryParamsKey.organization]:
              organizationId
          }}
        />
      </ArrayInput>
      <ArrayInput
        source={AppointmentDefinitionKey.notificationChannelList}
        label={t(tk.fields.notificationChannelList)}
      >
        <EnumSelectorInput
          source=""
          label={t(tk.fields.notificationChannelListElement)}
          enumValue={AppointmentDefinitionNotificationChannel}
        />
      </ArrayInput>
      <ArrayInput
        source={AppointmentDefinitionKey.templateList}
        label={t(tk.fields.templateList)}
      >
        <EnumSelectorInput
          source={AppointmentDefinitionTemplateKey.code}
          label={t(tk.fields.templateListCode)}
          enumValue={AppointmentDefinitionTemplateCode}
          withCreate
        />
        <ThymeleafInput
          source={AppointmentDefinitionTemplateKey.template}
          label={t(tk.fields.templateListTemplate)}
        />
      </ArrayInput>
    </>
  );
};
