import { ResourceType } from "model/primitives/resourceModel";
import { Gender, ValidationStatus } from "model/resource/person/person";
import { LocationStatus } from "model/resource/entities/location/location";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import { AppointmentStatus } from "../appointment";
import { AppointmentWithExtraData } from "../appointmentWithExtraData";
import { bookedAppointmentMock } from "./appointment";

export const appointmentWithExtraDataMock: AppointmentWithExtraData = {
  appointment: bookedAppointmentMock,
  encounter: "1"
};

export const appointmentWithExtraDataListMock: AppointmentWithExtraData[] = [
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Sebastaian",
          otherName: "Gonzalo",
          lastName: "Salamandra",
          motherLastName: "Achuras"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Booked,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.Arrived,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  },
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Geronimo",
          otherName: "Benicio",
          lastName: "Cuenca",
          motherLastName: "Ramirez"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Booked,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.InProgress,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  },
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Ivan",
          otherName: "Rodrigo",
          lastName: "Marciel",
          motherLastName: "Sabalon"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Cancelled,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.Cancelled,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  },
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Juan",
          otherName: "Ignacion",
          lastName: "Atun",
          motherLastName: "Desmenuzado"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Cancelled,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.Cancelled,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  }
];
