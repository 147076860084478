import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod } from "@laba/ts-common";
import {
  Encounter,
  EncounterClass,
  EncounterStatus
} from "model/resource/entities/encounter/encounter";

export interface CommonHospitalizationEncounterQueryParams {
  organization?: ModelId;
  status?: EncounterStatus[];
  class?: EncounterClass[];
  practitioner?: ModelId;
  creationDateAtLeast?: ApiDate;
}

export interface EncounterQueryParams {
  withPatient?: boolean;
  withPractitionerTeam?: boolean;
  withLocation?: boolean;
  startDate?: ApiDate;
  endDate?: ApiDate;
}

export interface EncounterListQueryParams
  extends ListQueryParams,
    EncounterQueryParams,
    CommonHospitalizationEncounterQueryParams {
  patient?: ModelId;
}

export const encounterBasePath = "/encounter";

export const getEncounterList = (
  params: EncounterListQueryParams
): Promise<ApiPageRequestResponse<Encounter>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(encounterBasePath),
    params
  });

export const getEncounter = (
  id: ModelId
): Promise<ApiRequestResponse<Encounter>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${encounterBasePath}/${id}`)
  });
