import {
  FCC,
  getClassName,
  ReactElement,
  stopPropagAndPrevDef,
  TextVariant
} from "@laba/react-common";
import { Accordion as MuiAccordion, AccordionDetails } from "@material-ui/core";
import React from "react";
import { Noop } from "@laba/ts-common";
import { useAccordionStyle } from "./useAccordionStyles";
import { AccordionSummary } from "./AccordionSummary/AccordionSummary";
import { AccordionSummaryMinHeight } from "./AccordionSummary/types";

export interface AccordionProps {
  title?: string;
  className?: string;
  summaryClassName?: string;
  defaultExpanded?: boolean;
  detailsClassName?: string;
  reducedTextVariant?: TextVariant;
  expandedTextVariant?: TextVariant;
  onAccordionClick?: Noop;
  isExpanded?: boolean;
  isSelfControlled?: boolean;
  hasBackgroundColor?: boolean;
  showArrowLeft?: boolean;
  summaryChildren?: ReactElement;
  withMarginSummary?: boolean;
  showArrowIcon?: boolean;
  withoutPadding?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  accordionSummaryMinHeightVariant?: AccordionSummaryMinHeight;
}

export const Accordion: FCC<AccordionProps> = ({
  title,
  children,
  className,
  summaryClassName,
  defaultExpanded,
  reducedTextVariant = TextVariant.Body2,
  expandedTextVariant = TextVariant.Subtitle2,
  onAccordionClick,
  isExpanded = false,
  isSelfControlled,
  hasBackgroundColor,
  showArrowLeft,
  summaryChildren,
  withMarginSummary,
  showArrowIcon,
  detailsClassName,
  withoutPadding = true,
  disabled = false,
  fullWidth,
  accordionSummaryMinHeightVariant
}) => {
  const classes = useAccordionStyle({
    hasBackgroundColor,
    withoutPadding,
    fullWidth
  });
  return (
    <MuiAccordion
      className={getClassName(classes.accordionRoot, className)}
      elevation={0}
      square
      classes={{ expanded: classes.expanded, disabled: classes.disabled }}
      onClick={onAccordionClick}
      expanded={isSelfControlled ? undefined : isExpanded}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        title={title}
        className={summaryClassName}
        textVariant={isExpanded ? expandedTextVariant : reducedTextVariant}
        isExpanded={isExpanded}
        showArrowLeft={showArrowLeft}
        showArrowIcon={showArrowIcon}
        withTopBottomMargin={withMarginSummary}
        accordionSummaryMinHeightVariant={accordionSummaryMinHeightVariant}
      >
        {summaryChildren}
      </AccordionSummary>
      <AccordionDetails
        className={getClassName(classes.detailsRoot, detailsClassName)}
        onClick={stopPropagAndPrevDef}
      >
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};
