import React from "react";
import { Skeleton as MuiSkeleton } from "@material-ui/lab";
import { FC } from "@laba/react-common";

export enum SkeletonAnimationVariant {
  Pulse = "pulse",
  Wave = "wave"
}

export enum SkeletonVariant {
  Text = "text",
  Rect = "rect",
  Circle = "circle"
}

export interface SkeletonProps {
  animation?: SkeletonAnimationVariant;
  className?: string;
  height?: number | string;
  variant?: SkeletonVariant;
  width?: number | string;
}

export const Skeleton: FC<SkeletonProps> = ({
  className,
  height,
  width,
  animation = SkeletonAnimationVariant.Pulse,
  variant = SkeletonVariant.Rect
}) => {
  return (
    <MuiSkeleton
      animation={animation}
      className={className}
      height={height}
      variant={variant}
      width={width}
    />
  );
};
