import React from "react";
import { FC, getClassName } from "@laba/react-common";
import { datesAreEqual, optionalDateInputToDateTime } from "@laba/ts-common";
import { getHeaderDateText, ResourceDateAndPractitioner } from "./utils";
import { useHeaderCreationOrLastEditDateTextStyle } from "./HeaderCreationOrLastEditDateTextStyle";

interface Props {
  creationData?: ResourceDateAndPractitioner;
  lastEditData?: ResourceDateAndPractitioner;
  showEditTextOnly?: boolean;
  hideCreationEditionHour?: boolean;
  className?: string;
}

export const HeaderCreationOrLastEditDateText: FC<Props> = ({
  creationData,
  lastEditData,
  showEditTextOnly,
  className,
  hideCreationEditionHour
}) => {
  const classes = useHeaderCreationOrLastEditDateTextStyle();
  const resourceLastEditData = getHeaderDateText(
    lastEditData,
    hideCreationEditionHour
  );
  const resourceCreationData = getHeaderDateText(
    creationData,
    hideCreationEditionHour
  );
  const creationDate = optionalDateInputToDateTime(creationData?.date);
  const lastEditDate = optionalDateInputToDateTime(lastEditData?.date);

  const content =
    creationDate && !lastEditDate
      ? resourceCreationData
      : creationDate &&
        lastEditDate &&
        datesAreEqual(creationDate, lastEditDate) &&
        !showEditTextOnly &&
        resourceCreationData
      ? resourceCreationData
      : resourceLastEditData;

  return content ? (
    <p className={getClassName(classes.root, className)} title={content}>
      {content}
    </p>
  ) : null;
};
