import { ApiRequestResponse } from "request/types";
import { HttpMethod } from "@laba/ts-common";
import { NotificationActionCode, ReceiverType } from "model/communication";
import { getInternalApiUrl, request } from "request/nexupRequest";

export interface InternalAppointmentNotificationDataParams {
  id?: string;
  appointmentId?: string;
  notificationActionCode?: NotificationActionCode;
  receiverType?: ReceiverType;
  patientId?: string;
  practitionerId?: string;
}

export interface AppointmentNotificationData {
  id?: string;
  messageTitle?: string;
  messageBody?: string;
  sessionInitMessage?: string;
  receiver?: string;
  senderId?: string;
}

export const getInternalAppointmentNotificationData = async (
  params?: InternalAppointmentNotificationDataParams[]
): Promise<ApiRequestResponse<AppointmentNotificationData[]>> => {
  return request({
    method: HttpMethod.GET,
    url: getInternalApiUrl("/appointment/notification-data"),
    data: params
  });
};
