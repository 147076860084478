import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";
import { Theme } from "model/theme";
import {
  createStyle,
  flexColumnMixin,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";

interface TitleContentDataVerticalItemStyleClassNames {
  root: string;
  principalTextItemList: string;
  secondaryTextItemList: string;
}

const useTitleContentDataVerticalItemStyleStatic = createStyle(
  (_theme: Theme) => ({
    root: {
      ...themeTextMixin(_theme, TextVariant.Body2),
      ...flexColumnMixin({}),
      color: getThemeStyleColor(
        _theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    principalTextItemList: {
      ...themeTextMixin(_theme, TextVariant.Subtitle2),
      color: getThemeStyleColor(
        _theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    secondaryTextItemList: {
      ...themeTextMixin(_theme, TextVariant.Body2),
      color: getThemeStyleColor(
        _theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "TitleContentDataVerticalItem"
);

export const useTitleContentDataVerticalItemStyle =
  (): TitleContentDataVerticalItemStyleClassNames => {
    const staticClasses = useTitleContentDataVerticalItemStyleStatic();

    return {
      root: staticClasses.root,
      principalTextItemList: staticClasses.principalTextItemList,
      secondaryTextItemList: staticClasses.secondaryTextItemList
    };
  };
