import React from "react";
import { Noop } from "@laba/ts-common";
import { FC, GoogleLogin, numberToPx } from "@laba/react-common";
import { CredentialResponse } from "@react-oauth/google";

enum GsiButtonConfigurationType {
  Standard = "standard",
  Icon = "icon"
}

enum GsiButtonConfigurationTheme {
  Outline = "outline",
  FilledBlue = "filled_blue",
  FilledBlack = "filled_black"
}

enum GsiButtonConfigurationSize {
  Large = "large",
  Medium = "medium",
  Small = "small"
}

enum GsiButtonConfigurationText {
  SigninWith = "signin_with",
  SignupWith = "signup_with",
  ContinueWith = "continue_with",
  Signin = "signin"
}

enum GsiButtonConfigurationShape {
  Rectangular = "rectangular",
  Pill = "pill",
  Circle = "circle",
  Square = "square"
}

enum GsiButtonConfigurationLogoAlignment {
  Left = "left",
  Center = "center"
}

export interface GoogleSignInButtonProps {
  onSuccessLogin: (credentials: string) => Promise<void>;
  onFailureLogin?: Noop;
  width?: number;
  isSignIn?: boolean;
}

export const GoogleLoginButton: FC<GoogleSignInButtonProps> = ({
  onSuccessLogin,
  onFailureLogin,
  width,
  isSignIn = true
}) => {
  return (
    <GoogleLogin
      type={GsiButtonConfigurationType.Standard}
      theme={GsiButtonConfigurationTheme.Outline}
      size={GsiButtonConfigurationSize.Large}
      text={
        isSignIn
          ? GsiButtonConfigurationText.SigninWith
          : GsiButtonConfigurationText.ContinueWith
      }
      shape={GsiButtonConfigurationShape.Rectangular}
      logo_alignment={GsiButtonConfigurationLogoAlignment.Left}
      onSuccess={async (credentialResponse: CredentialResponse) => {
        credentialResponse.credential &&
          (await onSuccessLogin(credentialResponse.credential));
      }}
      onError={onFailureLogin}
      useOneTap
      width={width ? numberToPx(width) : undefined}
      auto_select
    />
  );
};
