import { StyleVariant, TextVariant } from "model/themeVariant";
import {
  getThemeRadius,
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";
import {
  borderMixin,
  Color,
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  hoverMixin,
  marginMixin,
  paddingMixin,
  pxToRem,
  TypeVariant
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import { ClassNameDynamicGeneratedRecord } from "components/utils/getGenericButtonColors";

export interface SelectInputStyleProps {
  disabled: boolean;
  fullWidth: boolean;
  hasError: boolean;
  isOpen: boolean;
  type: TypeVariant;
  clearable: boolean;
  hasValue: boolean;
  showRightBorder: boolean;
  textVariant: TextVariant;
  startIconColor?: string;
}

interface FormControlStyleClassNames {
  root?: string;
}

interface SelectInputClassNames {
  container?: string;
  text?: string;
  startIcon?: string;
  endIcon?: string;
  clearButton?: string;
}

const getBorderColor = (
  theme: Theme,
  hasError: boolean,
  disabled: boolean,
  isOpen: boolean
): Color => {
  if (disabled) {
    return getThemeStyleState(theme, StyleVariant.Primary).disabled;
  }
  if (hasError) {
    return getThemeStyleColor(
      theme,
      StyleVariant.Danger,
      ThemeStyleColorVariant.Main
    );
  }
  if (isOpen) {
    return getThemeStyleColor(theme, StyleVariant.Primary);
  }
  return getThemeStyleColor(
    theme,
    StyleVariant.Primary,
    ThemeStyleColorVariant.GrayMedium
  );
};

const generatedStyleKeyTemplate = (
  typeVariant: TypeVariant,
  hasError: boolean,
  disabled: boolean,
  isOpen: boolean,
  showRightBorder: boolean
) =>
  `generatedStyle${typeVariant}${hasError ? "HasError" : "NotHasError"}${
    disabled ? "Disabled" : "NotDisabled"
  }${isOpen ? "IsOpen" : "IsOpen"}${
    showRightBorder ? "withoutLeftBorder" : "withLeftBorder"
  }`;

const generateDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(TypeVariant).forEach(typeVariant => {
    Object.values([true, false]).forEach(hasError => {
      [true, false].forEach(isOpen => {
        [true, false].forEach(disabled => {
          [true, false].forEach(showRightBorder => {
            classNameObject[
              generatedStyleKeyTemplate(
                typeVariant,
                hasError,
                disabled,
                isOpen,
                showRightBorder
              )
            ] = {
              ...borderMixin({
                color: getBorderColor(theme, hasError, disabled, isOpen),
                radius: { all: getThemeRadius(theme, RadiusVariant.Small) },
                side: {
                  all:
                    typeVariant === TypeVariant.Contained
                      ? undefined
                      : pxToRem(1),
                  right:
                    typeVariant === TypeVariant.Contained
                      ? undefined
                      : showRightBorder
                      ? pxToRem(1)
                      : 0
                },
                style: "solid"
              })
            };
          });
        });
      });
    });
  });
  return classNameObject;
};

const useFormControlStyle = createStyle(
  (_theme: Theme) => ({
    rootFullWidth: {
      width: "100%",
      position: "relative"
    },
    rootNotFullWidth: {
      width: pxToRem(323),
      position: "relative"
    }
  }),
  "FormControl"
);

export const useFormControlStyleClasses = (
  props: SelectInputStyleProps
): FormControlStyleClassNames => {
  const iconButtonClasses = useFormControlStyle();
  return {
    root: props.fullWidth
      ? iconButtonClasses.rootFullWidth
      : iconButtonClasses.rootNotFullWidth
  };
};

// TODO HIS-8570 Ver como optimizar estos estilos faltantes
const useSelectInputBaseStyle = createStyle(
  (theme: Theme) => ({
    ...generateDynamicStylesClassNames(theme)
  }),
  "SelectInputBase"
);

const useSelectInputBaseStaticStyle = createStyle(
  (theme: Theme) => ({
    containerStatic: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.SpaceBetween,
        gap: 2
      }),
      height: pxToRem(40),
      transition: "all 250ms",
      width: "100%",
      zIndex: 0
    },
    paddingContained: {
      ...paddingMixin({
        topBottom: pxToRem(8),
        leftRight: 12
      })
    },
    paddingNotContained: {
      ...paddingMixin({
        topBottom: pxToRem(7),
        left: 12,
        right: 4
      })
    },
    backgroundColorContained: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    hoverNotDisabledHasError: {
      ...hoverMixin({
        ...borderMixin({
          color: getThemeStyleColor(
            theme,
            StyleVariant.Danger,
            ThemeStyleColorVariant.Dark
          )
        }),
        backgroundColor: getThemeStyleState(theme, StyleVariant.Primary).hover,
        transition: "all 250ms"
      })
    },
    hoverNotDisabledIsOpen: {
      ...hoverMixin({
        ...borderMixin({
          color: getThemeStyleColor(theme, StyleVariant.Primary)
        }),
        backgroundColor: getThemeStyleState(theme, StyleVariant.Primary).hover,
        transition: "all 250ms"
      })
    },
    hoverNotDisabledOther: {
      ...hoverMixin({
        ...borderMixin({
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          )
        }),
        backgroundColor: getThemeStyleState(theme, StyleVariant.Primary).hover,
        transition: "all 250ms"
      })
    }
  }),
  "SelectInputBase"
);

const useSelectInputBaseStyleClasses = (props: SelectInputStyleProps) => {
  const classes = useSelectInputBaseStyle();
  const staticClasses = useSelectInputBaseStaticStyle();

  const hoverClassName = !props.disabled
    ? props.hasError
      ? staticClasses.hoverNotDisabledHasError
      : props.isOpen
      ? staticClasses.hoverNotDisabledIsOpen
      : staticClasses.hoverNotDisabledOther
    : undefined;
  return {
    container: getClassName(
      classes[
        generatedStyleKeyTemplate(
          props.type,
          props.hasError,
          props.disabled,
          props.isOpen,
          props.showRightBorder
        )
      ],
      staticClasses.containerStatic,
      props.type === TypeVariant.Contained
        ? staticClasses.paddingContained
        : staticClasses.paddingNotContained,
      props.type === TypeVariant.Contained
        ? staticClasses.backgroundColorContained
        : undefined,
      hoverClassName
    )
  };
};

const useSelectInputTextStyle = createStyle(
  (theme: Theme) => ({
    text: {
      ...marginMixin({ topBottom: "auto" }),
      textAlign: "start",
      width: "100%"
    },
    textClearable: {
      ...paddingMixin({ right: 36 })
    },
    textNotClearable: {
      ...paddingMixin({ right: 0 })
    },
    textDisabled: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    textHasError: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    textHasValue: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayLight
      )
    },
    textOther: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    }
  }),
  "SelectInputText"
);

const useSelectInputTextDynamicStyle = createStyle(
  (theme: Theme) => ({
    text: (props: SelectInputStyleProps) => ({
      ...themeTextMixin(theme, props.textVariant, 1)
    })
  }),
  "SelectInputTextDynamic"
);

const useSelectInputIconStyle = createStyle(
  (theme: Theme) => ({
    startIcon: {
      ...marginMixin({ right: pxToRem(8) }),
      height: pxToRem(24),
      width: pxToRem(24)
    },
    endIcon: {
      height: pxToRem(24),
      width: pxToRem(24)
    },
    iconColorDisabled: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    iconColorHasError: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    iconColorOther: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    },
    clearableButton: {
      position: "absolute",
      top: pxToRem(8),
      right: pxToRem(36)
    }
  }),
  "SelectInputIcon"
);

const useSelectInputIconDynamicStyle = createStyle(
  (_theme: Theme) => ({
    startIconColor: (props: SelectInputStyleProps) => ({
      color: props.startIconColor
    })
  }),
  "SelectInputIconDynamic"
);

const useSelectInputTextStyleClasses = (props: SelectInputStyleProps) => {
  const selectInputTextClasses = useSelectInputTextStyle();
  const selectInputTextDynamicClasses = useSelectInputTextDynamicStyle(props);

  const getTextColorClassName = (): Color => {
    if (props.disabled) {
      return selectInputTextClasses.textDisabled;
    }
    if (props.hasError) {
      return selectInputTextClasses.textHasError;
    }
    if (!props.hasValue) {
      return selectInputTextClasses.textHasValue;
    }
    return selectInputTextClasses.textOther;
  };
  const textClearableClassName = props.clearable
    ? selectInputTextClasses.textClearable
    : selectInputTextClasses.textNotClearable;

  return {
    text: getClassName(
      selectInputTextClasses.text,
      getTextColorClassName(),
      textClearableClassName,
      selectInputTextDynamicClasses.text
    )
  };
};

const useSelectInputIconStyleClasses = (props: SelectInputStyleProps) => {
  const iconButtonClasses = useSelectInputIconStyle();
  const iconButtonDynamicClasses = useSelectInputIconDynamicStyle(props);
  const getIconColorClassName = (): Color => {
    if (props.disabled) {
      return iconButtonClasses.iconColorDisabled;
    }
    if (props.hasError) {
      return iconButtonClasses.iconColorHasError;
    }
    return iconButtonClasses.iconColorOther;
  };

  const startIconClassName = getClassName(
    iconButtonClasses.startIcon,
    getIconColorClassName(),
    props.startIconColor ? iconButtonDynamicClasses.startIconColor : undefined
  );
  const endIconClassName = getClassName(
    iconButtonClasses.endIcon,
    getIconColorClassName()
  );
  const clearButtonClassName = getClassName(
    iconButtonClasses.endIcon,
    iconButtonClasses.clearableButton
  );

  return {
    startIcon: startIconClassName,
    endIcon: endIconClassName,
    clearButton: clearButtonClassName
  };
};

export const useSelectInputStyleClasses = (
  props: SelectInputStyleProps
): SelectInputClassNames => {
  const baseClasses = useSelectInputBaseStyleClasses(props);
  const textClasses = useSelectInputTextStyleClasses(props);
  const iconButtonClasses = useSelectInputIconStyleClasses(props);

  return {
    ...baseClasses,
    ...textClasses,
    ...iconButtonClasses
  };
};

export const useMenuStyle = createStyle(
  (_theme: Theme) => ({
    menu: {
      ...marginMixin({ top: pxToRem(8) })
    }
  }),
  "Menu"
);

export const useFormHelperTextStyle = createStyle(
  (theme: Theme) => ({
    root: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "FormHelperText"
);
