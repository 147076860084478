import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  getClassName,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { SizeVariant, StyleVariant, TextVariant } from "model/themeVariant";
import { getMapValueBuilder } from "@laba/ts-common";
import { ClassNameDynamicGeneratedRecord } from "components/utils/getGenericButtonColors";

const profilePictureSizeLarge = 80;
export const profilePictureSizeMedium = 40;
const profilePictureSizeSmall = 32;
const profilePictureSizeExtraSmall = 24;

const getSize = getMapValueBuilder({
  [SizeVariant.Large]: pxToRem(profilePictureSizeLarge),
  [SizeVariant.Medium]: pxToRem(profilePictureSizeMedium),
  [SizeVariant.Small]: pxToRem(profilePictureSizeSmall),
  [SizeVariant.ExtraSmall]: pxToRem(profilePictureSizeExtraSmall)
});

const getTextSize = getMapValueBuilder({
  [SizeVariant.Large]: TextVariant.H4,
  [SizeVariant.Medium]: TextVariant.Body1,
  [SizeVariant.Small]: TextVariant.Body2,
  [SizeVariant.ExtraSmall]: TextVariant.Body2
});

const generatedSizeStyleKeyTemplate = (sizeVariant: SizeVariant) =>
  `generatedStyle${sizeVariant}`;

const generateDynamicSizeStylesClassNames =
  (): ClassNameDynamicGeneratedRecord => {
    const classNameObject: ClassNameDynamicGeneratedRecord = {};
    Object.values(SizeVariant).forEach(sizeVariant => {
      classNameObject[generatedSizeStyleKeyTemplate(sizeVariant)] = {
        height: getSize(sizeVariant),
        width: getSize(sizeVariant)
      };
    });
    return classNameObject;
  };

const generateDynamicTextSizeStylesClassNames = (theme: Theme) => {
  return Object.fromEntries(
    Object.values(SizeVariant).map(sizeVariant => [
      sizeVariant,
      {
        ...themeTextMixin(theme, getTextSize(sizeVariant))
      }
    ])
  );
};

export interface ProfilePictureStyleClassNames {
  img: string;
  noImageContainer: string;
  noImageText: string;
}

export interface ProfilePictureStyleProps {
  style: StyleVariant;
  initials: string;
  size: SizeVariant;
}

const useProfilePictureStyleStatic = createStyle(
  (theme: Theme) => ({
    borderRadiusSmall: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small))
    },
    img: {
      objectFit: "cover"
    },
    noImageContainerStatic: {
      ...flexColumnMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center
      }),
      ...flexItemMixin({
        shrink: 0
      })
    },
    noImageTextStatic: {
      ...themeTextMixin(theme, TextVariant.Caption, 1),
      textAlign: "center"
    }
  }),
  "ProfilePictureStatic"
);

const useProfilePictureSizeStyle = createStyle<Theme, unknown, string>(
  (_theme: Theme) => ({
    ...generateDynamicSizeStylesClassNames()
  }),
  "ProfilePictureSize"
);

const useProfilePictureTextSizeStyle = createStyle<Theme, unknown, string>(
  (theme: Theme) => ({
    ...generateDynamicTextSizeStylesClassNames(theme)
  }),
  "ProfilePictureTextSize"
);

const useProfilePictureStyleDynamic = createStyle(
  (theme: Theme) => ({
    noImageContainerDynamic: (props: ProfilePictureStyleProps) => ({
      backgroundColor: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.Main
      )
    }),
    noImageTextDynamic: (props: ProfilePictureStyleProps) => ({
      color: getThemeStyleColor(
        theme,
        props.style,
        ThemeStyleColorVariant.Contrast
      )
    })
  }),
  "ProfilePictureDynamic"
);

export const useProfilePictureStyle = (
  props: ProfilePictureStyleProps
): ProfilePictureStyleClassNames => {
  const staticClasses = useProfilePictureStyleStatic();
  const dynamicClasses = useProfilePictureStyleDynamic(props);
  const sizeClasses = useProfilePictureSizeStyle();
  const textSizeClasses = useProfilePictureTextSizeStyle();

  return {
    img: getClassName(
      staticClasses.img,
      staticClasses.borderRadiusSmall,
      sizeClasses[generatedSizeStyleKeyTemplate(props.size)]
    ),
    noImageContainer: getClassName(
      staticClasses.noImageContainerStatic,
      staticClasses.borderRadiusSmall,
      dynamicClasses.noImageContainerDynamic,
      sizeClasses[generatedSizeStyleKeyTemplate(props.size)]
    ),
    noImageText: getClassName(
      staticClasses.noImageTextStatic,
      dynamicClasses.noImageTextDynamic,
      textSizeClasses[props.size]
    )
  };
};
