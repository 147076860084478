import { ErrorIssueType } from "./errorIssueType";
import { ResourceType } from "../../primitives/resourceModel";

export enum ErrorIssueParameterType {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  ResourceType = "ResourceType",
  ResourceId = "ResourceId",
  Field = "Field",
  InvalidFieldReason = "InvalidFieldReason",
  InvalidQueryReason = "InvalidQueryReason",
  InvalidFileReason = "InvalidFileReason",
  ErrorMessage = "ErrorMessage",
  BadRequestReason = "BadRequestReason"
}

export enum InvalidFieldReason {
  Required = "Required",
  Unavailable = "Unavailable",
  InvalidFormat = "InvalidFormat",
  InvalidReference = "InvalidReference",
  InvalidOption = "InvalidOption",
  NotModifiable = "NotModifiable",
  ModelFieldsInconsistency = "ModelFieldsInconsistency",
  ForbiddenField = "ForbiddenField"
}

export enum BadRequestReason {
  TimeConsumingOperation = "TimeConsumingOperation"
}

export enum InvalidQueryParamReason {
  InvalidOption = "InvalidOption",
  InvalidType = "InvalidType"
}

export enum InvalidFileReason {
  EmptyFile = "EmptyFile",
  SizeExceeded = "SizeExceeded",
  InvalidFilename = "InvalidFilename",
  InvalidExtension = "InvalidExtension",
  InvalidFile = "InvalidFile"
}

interface BaseErrorIssueParameter<T extends ErrorIssueParameterType> {
  type: T;
}

export type StringErrorIssueParameterType =
  | ErrorIssueParameterType.ResourceType
  | ErrorIssueParameterType.ResourceId
  | ErrorIssueParameterType.Field
  | ErrorIssueParameterType.InvalidFieldReason
  | ErrorIssueParameterType.InvalidQueryReason
  | ErrorIssueParameterType.InvalidFileReason
  | ErrorIssueParameterType.BadRequestReason
  | ErrorIssueParameterType.ErrorMessage;

interface BaseStringErrorIssueParameter<
  T extends StringErrorIssueParameterType,
  S extends string = string
> extends BaseErrorIssueParameter<T> {
  valueString?: S;
}

export type ResourceTypeErrorIssueParameter = BaseStringErrorIssueParameter<
  ErrorIssueParameterType.ResourceType,
  ResourceType
>;

export type ResourceIdErrorIssueParameter =
  BaseStringErrorIssueParameter<ErrorIssueParameterType.ResourceId>;

export type FieldErrorIssueParameter =
  BaseStringErrorIssueParameter<ErrorIssueParameterType.Field>;

export type InvalidFieldReasonErrorIssueParameter =
  BaseStringErrorIssueParameter<
    ErrorIssueParameterType.InvalidFieldReason,
    InvalidFieldReason
  >;

export type BadRequestReasonErrorIssueParameter = BaseStringErrorIssueParameter<
  ErrorIssueParameterType.BadRequestReason,
  BadRequestReason
>;

export type InvalidQueryReasonErrorIssueParameter =
  BaseStringErrorIssueParameter<
    ErrorIssueParameterType.InvalidQueryReason,
    InvalidQueryParamReason
  >;

export type InvalidFileReasonErrorIssueParameter =
  BaseStringErrorIssueParameter<
    ErrorIssueParameterType.InvalidFileReason,
    InvalidFileReason
  >;

export type ErrorMessageErrorIssueParameter =
  BaseStringErrorIssueParameter<ErrorIssueParameterType.ErrorMessage>;

export type ErrorIssueParameter<T extends ErrorIssueType> =
  T extends ErrorIssueType.NotFound
    ? ResourceTypeErrorIssueParameter | ResourceIdErrorIssueParameter
    : T extends ErrorIssueType.InvalidField
    ? FieldErrorIssueParameter | InvalidFieldReasonErrorIssueParameter
    : T extends ErrorIssueType.InvalidQueryParam
    ? FieldErrorIssueParameter | InvalidQueryReasonErrorIssueParameter
    : T extends ErrorIssueType.BadRequest
    ? ErrorMessageErrorIssueParameter | BadRequestReasonErrorIssueParameter
    : T extends ErrorIssueType.InvalidFile
    ?
        | InvalidFileReasonErrorIssueParameter
        | InvalidFieldReasonErrorIssueParameter
    : ErrorIssueParameterAll;

export type ErrorIssueParameterAll =
  | ResourceTypeErrorIssueParameter
  | ResourceIdErrorIssueParameter
  | InvalidFieldReasonErrorIssueParameter
  | FieldErrorIssueParameter
  | InvalidQueryReasonErrorIssueParameter
  | BadRequestReasonErrorIssueParameter
  | ErrorMessageErrorIssueParameter;
