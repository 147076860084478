import { tkCP } from "translation/i18n";
import { FC, useField, useI18n } from "@laba/react-common";
import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  AutocompleteOptionConfig,
  OptionsConfig,
  SearchableListSimpleInput,
  SelectInput
} from "@laba/nexup-components";
import range from "lodash-es/range";
import { SantaCatalinaPayrollRequestBodyParamsKey } from "@laba/nexup-api";

const tk = tkCP.adminPage.dashboard.santaCatalinaPayroll;
const yearOptions: OptionsConfig<string>[] = range(2020, 2050).map(x => ({
  value: String(x),
  itemId: String(x),
  text: String(x)
}));
const monthOptions: OptionsConfig<string>[] = range(1, 12).map(x => ({
  value: String(x),
  itemId: String(x),
  text: String(x)
}));
const organizationMap: Record<string, string> = {
  "6250": "RSC S.A.",
  "6251": "EGESAC S. A.",
  "6252": "CENTRO MEDICO AMENABAR SRL",
  "6253": " ",
  "6254": "SC PILAR S.A.",
  "6255": "Saint Germain S.R.L.",
  "6256": "Clinica Basilea SA"
};
const organizationOptionFromValue = (
  value: string
): AutocompleteOptionConfig<string> => {
  return {
    value,
    text: `${organizationMap[value]} (${value})`,
    title: `${organizationMap[value]} (${value})`
  };
};
export const organizationOptions = Object.keys(organizationMap);

export const SantaCatalinaPayrollFormContent: FC = () => {
  const { t } = useI18n();
  const {
    input: {
      value: startYearValue,
      onChange: startYearOnChange,
      onBlur: startYearOnBlur
    },
    meta: { error: startYearError, touched: startYearTouched }
  } = useField<string, string>(
    SantaCatalinaPayrollRequestBodyParamsKey.startYear
  );
  const {
    input: {
      value: endYearValue,
      onChange: endYearOnChange,
      onBlur: endYearOnBlur
    },
    meta: { error: endYearError, touched: endYearTouched }
  } = useField<string, string>(
    SantaCatalinaPayrollRequestBodyParamsKey.endYear
  );
  const {
    input: {
      value: startMonthValue,
      onChange: startMonthOnChange,
      onBlur: startMonthOnBlur
    },
    meta: { error: startMonthError, touched: startMonthTouched }
  } = useField<string, string>(
    SantaCatalinaPayrollRequestBodyParamsKey.startMonth
  );
  const {
    input: {
      value: endMonthValue,
      onChange: endMonthOnChange,
      onBlur: endMonthOnBlur
    },
    meta: { error: endMonthError, touched: endMonthTouched }
  } = useField<string, string>(
    SantaCatalinaPayrollRequestBodyParamsKey.endMonth
  );

  const {
    input: {
      value: organizationIdListValue,
      onChange: organizationIdListOnChange,
      onBlur: organizationIdListOnBlur
    },
    meta: { error: organizationIdListError, touched: organizationIdListTouched }
  } = useField<string[], string>(
    SantaCatalinaPayrollRequestBodyParamsKey.organizationIdList
  );
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={6}>
        <Typography title={t(tk.startYear)}>{t(tk.startYear)} </Typography>
        <SelectInput
          value={startYearValue}
          onChange={startYearOnChange}
          onBlur={startYearOnBlur}
          errorText={startYearError}
          showError={startYearTouched ?? true}
          placeholder={t(tk.startYear)}
          options={yearOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.startMonth)}>{t(tk.startMonth)} </Typography>
        <SelectInput
          value={startMonthValue}
          onChange={startMonthOnChange}
          onBlur={startMonthOnBlur}
          errorText={startMonthError}
          showError={startMonthTouched ?? true}
          placeholder={t(tk.startMonth)}
          options={monthOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.endYear)}>{t(tk.endYear)} </Typography>
        <SelectInput
          value={endYearValue}
          onChange={endYearOnChange}
          onBlur={endYearOnBlur}
          errorText={endYearError}
          showError={endYearTouched ?? true}
          placeholder={t(tk.endYear)}
          options={yearOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={6}>
        <Typography title={t(tk.endMonth)}>{t(tk.endMonth)} </Typography>
        <SelectInput
          value={endMonthValue}
          onChange={endMonthOnChange}
          onBlur={endMonthOnBlur}
          errorText={endMonthError}
          showError={endMonthTouched ?? true}
          placeholder={t(tk.endMonth)}
          options={monthOptions}
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <Typography title={t(tk.organizationId)}>
          {t(tk.organizationId)}
        </Typography>
        <SearchableListSimpleInput
          getOptionFromValue={organizationOptionFromValue}
          valueList={organizationIdListValue}
          optionList={organizationOptions}
          setOptionList={organizationIdListOnChange}
          onBlur={organizationIdListOnBlur}
          errorText={organizationIdListError}
          showError={organizationIdListTouched ?? true}
          noOptionsText={t(tk.organizationNoOptions)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
