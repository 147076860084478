import {
  borderMixin,
  borderMixinRadiusAll,
  createStyle,
  FlexAlignContent,
  flexColumnMixin,
  getClassName,
  paddingMixin,
  StyleVariant,
  TextVariant
} from "@laba/react-common";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeRadius,
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";

export interface PhoneInputStyleProps {
  fullWidth: boolean;
  disabled: boolean;
}

interface PhoneInputStylesClassNames {
  root?: string;
  phoneInput?: string;
}

const usePhoneInputStaticStyles = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({ gap: 4, alignContent: FlexAlignContent.FlexStart })
    },
    phoneInput: {
      // there is a bug in lib react-phone-input-2, https://github.com/bl00mber/react-phone-input-2/issues/597
      // the css is taking over the jss styles, write over the classes of react-phone-input-2/lib/material.css
      "& .form-control": {
        ...paddingMixin({ topBottom: 8 }),
        ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small)),
        ...borderMixin({
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayMedium
          )
        }),
        width: "100%",
        "&:focus": {
          ...borderMixin({
            color: getThemeStyleColor(
              theme,
              StyleVariant.Primary,
              ThemeStyleColorVariant.Main
            )
          })
        },
        "&:disabled": {
          ...borderMixin({
            color: getThemeStyleState(theme, StyleVariant.Primary).disabled
          }),
          color: getThemeStyleState(theme, StyleVariant.Primary).disabled,
          cursor: "default",
          "&::placeholder": {
            color: getThemeStyleState(theme, StyleVariant.Primary).disabled
          }
        }
      },
      "& .special-label": {
        ...themeTextMixin(theme, TextVariant.Body2, 1),
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      },
      "&:focus-within .special-label": {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayMedium
        )
      },
      "& .country-list": {
        position: "fixed"
      }
    },
    disabledFlag: {
      "& .selected-flag": {
        opacity: getThemeStyleState(theme, StyleVariant.Primary).disabledOpacity
      }
    },
    fullWidth: {
      width: "100%"
    }
  }),
  "PhoneInputStatic"
);

export const usePhoneInputStyles = (
  props: PhoneInputStyleProps
): PhoneInputStylesClassNames => {
  const staticClasses = usePhoneInputStaticStyles();

  return {
    phoneInput: getClassName(
      staticClasses.phoneInput,
      props.disabled ? staticClasses.disabledFlag : undefined
    ),
    root: getClassName(
      staticClasses.root,
      props.fullWidth ? staticClasses.fullWidth : undefined
    )
  };
};
