import { FC, getClassName, ThemeStyleColorVariant } from "@laba/react-common";
import React from "react";
import { TextVariant } from "model/themeVariant";
import { isEmpty } from "lodash-es";
import { useTitleContentDataStyle } from "./useTitleContentDataStyle";
import { TitleContentDataVerticalItem } from "./TitleContentDataVerticalItem";
import { TitleContentDataHorizontalItem } from "./TitleContentDataHorizontalItem";
import {
  TitleContentDataItemListTextAlign,
  TitleContentDataTextItem
} from "./types";

export interface TitleContentDataProps {
  className?: string;
  label?: string;
  title?: string;
  titleVariant?: TextVariant;
  textList?: TitleContentDataTextItem[];
  principalTextVariant?: TextVariant;
  secondaryTextVariant?: TextVariant;
  maxLines?: number;
  fullWidth?: boolean;
  columnGap?: number;
  titleColorVariant?: ThemeStyleColorVariant;
  textColorVariant?: ThemeStyleColorVariant;
  hide?: boolean;
  reverseOrder?: boolean;
  titleContentDataItemListTextAlign?: TitleContentDataItemListTextAlign;
  hideTextIfEmpty?: boolean;
  isMarkdown?: boolean;
}

const buildLabelTitle = (title?: string) => {
  if (!title) return undefined;
  if (title.endsWith(":")) return `${title.trim()} `;
  return `${title.trim()}: `;
};

const buildDefaultDataLabel = (
  title?: string,
  textList?: TitleContentDataTextItem[]
) =>
  [
    buildLabelTitle(title),
    textList
      ?.map(item =>
        [item.principalText, item.secondaryText].filter(Boolean).join("")
      )
      .join(" / ")
  ]
    .filter(Boolean)
    .join("");

export const TitleContentData: FC<TitleContentDataProps> = ({
  className,
  title,
  textList,
  titleVariant = TextVariant.Caption,
  principalTextVariant = TextVariant.Subtitle2,
  secondaryTextVariant = TextVariant.Body2,
  maxLines,
  fullWidth = false,
  label,
  columnGap = 4,
  titleColorVariant = ThemeStyleColorVariant.GrayMedium,
  textColorVariant = ThemeStyleColorVariant.GrayDark,
  hide,
  reverseOrder = false,
  titleContentDataItemListTextAlign = TitleContentDataItemListTextAlign.Horizontal,
  hideTextIfEmpty = false,
  isMarkdown = false
}) => {
  const classes = useTitleContentDataStyle({
    maxLines,
    titleVariant,
    principalTextVariant,
    secondaryTextVariant,
    fullWidth,
    columnGap,
    titleColorVariant,
    textColorVariant,
    reverseOrder,
    titleContentDataItemListTextAlign
  });
  const textIsEmpty = isEmpty(textList);
  const dataLabel = label ?? buildDefaultDataLabel(title, textList);
  return !hide ? (
    <div className={getClassName(classes.root, className)} title={dataLabel}>
      {title && <p className={classes.title}>{title}</p>}
      {textIsEmpty
        ? !hideTextIfEmpty && <p className={classes.secondaryText}>-</p>
        : textList?.map(item =>
            titleContentDataItemListTextAlign ===
            TitleContentDataItemListTextAlign.Horizontal ? (
              <TitleContentDataHorizontalItem
                principalText={item.principalText}
                secondaryText={item.secondaryText}
                key={`${item.principalText} ${item.secondaryText}`}
                isMarkdown={isMarkdown}
              />
            ) : (
              <TitleContentDataVerticalItem
                principalText={item.principalText}
                secondaryText={item.secondaryText}
                key={`${item.principalText} ${item.secondaryText}`}
                isMarkdown={isMarkdown}
              />
            )
          )}
    </div>
  ) : null;
};
