import React from "react";
import { IconButton } from "@material-ui/core";
import { Noop } from "@laba/ts-common";
import { Color, FC, IconC } from "@laba/react-common";
import { CloseIcon } from "components/icons";
import { NexupColor } from "model/nexupColor";
import { StyleVariant } from "model/themeVariant";
import { useBasePopupHeaderTitleStyle } from "components/popup/BasePopup/BasePopupHeaderTitle/useBasePopupHeaderTitleStyle";

export interface BasePopupHeaderTitleProps {
  style?: StyleVariant;
  color?: Color;
  onCloseClick?: Noop;
  headerTitle?: string;
  headerSubtitle?: string;
  disableClose?: boolean;
  withColor?: boolean;
  ExtraRightIconHeader?: IconC;
  onExtraRightIconClick?: Noop;
  disabledExtraRightIconHeader?: boolean;
  disabledExtraLeftIconHeader?: boolean;
  ExtraLeftIconHeader?: IconC;
  leftIconStyle?: StyleVariant;
}

export const BasePopupHeaderTitle: FC<BasePopupHeaderTitleProps> = ({
  onCloseClick,
  headerTitle,
  headerSubtitle,
  ExtraRightIconHeader,
  onExtraRightIconClick,
  ExtraLeftIconHeader,
  disabledExtraRightIconHeader = false,
  disabledExtraLeftIconHeader = false,
  style = StyleVariant.Primary,
  color = NexupColor.MainPurple,
  disableClose = false,
  withColor = false,
  leftIconStyle = StyleVariant.BlackWhite
}) => {
  const classes = useBasePopupHeaderTitleStyle({
    style,
    color,
    leftIconStyle
  });

  return (
    <div className={classes.container}>
      {withColor && <div className={classes.color} />}
      {ExtraLeftIconHeader && (
        <IconButton
          disabled={disabledExtraLeftIconHeader}
          className={classes.leftHeaderIconButton}
        >
          <ExtraLeftIconHeader className={classes.headerIcon} />
        </IconButton>
      )}
      <div className={classes.headerTexts}>
        <p className={classes.headerTitle}>{headerTitle}</p>
        {headerSubtitle && (
          <p className={classes.headerSubtitle}>{headerSubtitle}</p>
        )}
      </div>
      <div className={classes.rightIconContainer}>
        {ExtraRightIconHeader && (
          <IconButton
            className={classes.headerIconButton}
            disabled={disabledExtraRightIconHeader}
            onClick={onExtraRightIconClick}
          >
            <ExtraRightIconHeader className={classes.headerIcon} />
          </IconButton>
        )}
        <IconButton
          className={classes.headerIconButton}
          onClick={onCloseClick}
          disabled={disableClose}
        >
          <CloseIcon className={classes.headerIcon} />
        </IconButton>
      </div>
    </div>
  );
};
