import { isSomeEnum, Optional } from "@laba/ts-common";
import { ResourceType } from "model/primitives/resourceModel";
import { head, some } from "lodash-es";
import { getModelReferenceId } from "model/primitives/modelReference/utils";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import {
  CancellationReason,
  Encounter,
  EncounterClass,
  EncounterLocation,
  EncounterLocationStatus,
  EncounterParticipant,
  EncounterService,
  EncounterStatus,
  KnownEncounterService,
  RoleCodes
} from "model/resource/entities/encounter/encounter";
import { Location, LocationType } from "model/resource";

export const isEncounterArrived = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter?: Encounter<RType, Class>
): boolean => encounter?.status === EncounterStatus.Arrived;

export const isEncounterTriaged = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter?: Encounter<RType, Class>
): boolean => encounter?.status === EncounterStatus.Triaged;

export const isEncounterInProgress = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter?: Encounter<RType, Class>
): boolean => encounter?.status === EncounterStatus.InProgress;

export const isEncounterFinished = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter?: Encounter<RType, Class>
): boolean => encounter?.status === EncounterStatus.Finished;

export const isEncounterCancelled = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter?: Encounter<RType, Class>
): boolean => encounter?.status === EncounterStatus.Cancelled;

export const isKnownService = (
  service?: EncounterService
): service is KnownEncounterService =>
  isSomeEnum(KnownEncounterService)(service);

export const isEncounterCancellationReasonCode = (
  code: CancellationReason,
  encounter?: Encounter
): boolean => encounter?.cancellationReason?.code === code;

export const isEncounterCancellationReasonPatientCancelled = (
  encounter?: Encounter
): boolean =>
  isEncounterCancellationReasonCode(
    CancellationReason.PatientCancelled,
    encounter
  );

export const isEncounterCancellationReasonPatientTriageCancelled = (
  encounter?: Encounter
): boolean =>
  isEncounterCancellationReasonCode(
    CancellationReason.PatientTriageCancelled,
    encounter
  );

export const isEncounterCancellationReasonPractitionerCancelled = (
  encounter?: Encounter
): boolean =>
  isEncounterCancellationReasonCode(
    CancellationReason.PractitionerCancelled,
    encounter
  );

export const isEncounterCancellationReasonPractitionerTriageCancelled = (
  encounter?: Encounter
): boolean =>
  isEncounterCancellationReasonCode(
    CancellationReason.PractitionerTriageCancelled,
    encounter
  );

const getEncounterTeamMember = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter: Encounter<RType, Class>,
  practitionerId: string
): EncounterParticipant[] => {
  return (
    encounter.practitionerTeam?.filter(
      member => getModelReferenceId(member.practitioner) === practitionerId
    ) ?? []
  );
};

export const encounterHasMemberWithRole = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter: Encounter<RType, Class>,
  practitionerId: string,
  role: RoleCodes
): boolean => {
  const memberList = getEncounterTeamMember(encounter, practitionerId);
  return some(memberList, member => member.role.code === role);
};

export const getPractitionersFromEncounterParticipantsWithRole = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  role: RoleCodes,
  encounter?: Encounter<RType, Class>
): ModelReference<Practitioner>[] => {
  return (
    encounter?.practitionerTeam
      ?.filter(p => p.role.code === role)
      ?.map(p => p.practitioner) ?? []
  );
};

export const getFirstGeneralPractitioner = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter?: Encounter<RType, Class>
): Optional<ModelReference<Practitioner>> => {
  return head(
    getPractitionersFromEncounterParticipantsWithRole(
      RoleCodes.GeneralPractitioner,
      encounter
    )
  );
};

export const getFirstPhysiatristPractitioner = <
  RType extends ResourceType = ResourceType.Encounter,
  Class extends EncounterClass = EncounterClass
>(
  encounter?: Encounter<RType, Class>
): Optional<ModelReference<Practitioner>> => {
  return head(
    getPractitionersFromEncounterParticipantsWithRole(
      RoleCodes.Physiatrist,
      encounter
    )
  );
};

const getEncounterParticipantsFromRole = (
  role: RoleCodes,
  encounterParticipants?: EncounterParticipant[]
): EncounterParticipant[] => {
  return encounterParticipants?.filter(ep => ep.role.code === role) ?? [];
};

export const getFirstGeneralPractitionerFromEncounterParticipants = (
  encounterParticipants?: EncounterParticipant[]
): Optional<ModelReference<Practitioner>> => {
  return head(
    getEncounterParticipantsFromRole(
      RoleCodes.GeneralPractitioner,
      encounterParticipants
    )
  )?.practitioner;
};

export const getFirstPhysiatristPractitionerFromEncounterParticipants = (
  encounterParticipants?: EncounterParticipant[]
): Optional<ModelReference<Practitioner>> => {
  return head(
    getEncounterParticipantsFromRole(
      RoleCodes.Physiatrist,
      encounterParticipants
    )
  )?.practitioner;
};

export const createBaseEncounterLocation = (
  location: ModelReference<Location>,
  physicalType: LocationType,
  status = EncounterLocationStatus.Active
): EncounterLocation => {
  return {
    location,
    physicalType,
    status
  };
};
