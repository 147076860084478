import { Optional } from "@laba/ts-common";
import { forEach, get, isEmpty, isNil } from "lodash-es";

export enum EnvVar {
  REACT_APP_BASE_URL = "REACT_APP_BASE_URL",
  REACT_APP_API_TIMEOUT = "REACT_APP_API_TIMEOUT",
  REACT_APP_GMAP_API_KEY = "REACT_APP_GMAP_API_KEY"
}
type EnvVarObj = Record<EnvVar, Optional<string>>;

const staticEnvData = process.env as unknown as EnvVarObj;
const dynamicEnvData = get(window, "_env_", {}) as EnvVarObj;
const envData: EnvVarObj = {
  [EnvVar.REACT_APP_API_TIMEOUT]: "600000",
  ...staticEnvData,
  ...dynamicEnvData
};

const checkEnvs = (): void => {
  const missingEnvs: string[] = [];
  forEach(EnvVar, envVar => {
    if (isNil(envData[envVar])) missingEnvs.push(envVar);
  });

  if (!isEmpty(missingEnvs))
    throw new Error(
      `${JSON.stringify(missingEnvs)} is not defined as an environment variable`
    );
};

export const getEnvVar = (envVar: EnvVar): string => envData[envVar] ?? "";

checkEnvs();
