export const appointment = {
  title: "Turno",
  filters: {
    organization: "Organizacion",
    practitioner: "Profesional",
    patient: "Paciente",
    status: "Estado",
    startStartDate: "Desde",
    endStartDate: "Hasta",
    healthcareService: "Servicio",
    appointmentDefinition: "Tipo de turno"
  },
  fields: {
    id: "Id",
    practitioner: "Profesional",
    patient: "Paciente",
    status: "Estado",
    appointmentDefinition: "Tipo de turno",
    healthcareService: "Servicio",

    startDate: "Fecha de inicio",
    admissionDate: "Fecha de admision",
    cancelDate: "Fecha de cancelación",
    fulfilledDate: "Fecha de llenado",
    attachment: "Archivos",
    attachamentName: "Nombre del archivo",
    attachmentUrl: "Url del archivo",
    tagList: "Listado de tags",
    tagListCode: "Codigo",
    tagListSystem: "Code system",
    practitionerTeam: "Equipo de profesionales",
    speciality: "Especialidad",

    endDate: "Fecha de finalizacion",
    minutesDuration: "Duracion (minutos)",
    overbooked: "Sobreturno",

    patientAccessInfoLink: "Link para el paciente",
    comment: "Comentarios",
    patientInstruction: "Instrucciones para el paciente",
    consultationReason: "Razon de consulta",
    cancellationReason: "Razon de cancelacion",

    notificationChannelList: "Canales de notificacion",
    notificationChannelListCode: "Canal",
    notificationChannelListContactPointValue: "Contacto",
    notificationChannelListContactPointRole: "Rol de contacto",
    notificationChannelListContactPointType: "Tipo de contacto"
  },
  tabs: {
    main: "Turno",
    notificationChannel: "Notificacion"
  }
};
