import { first, isEmpty, last, sortBy } from "lodash-es";
import { ApiHour, DateTime } from "@laba/ts-common";
import { Day, Hour } from "./time";

export const getHourDisplay = (hour: Hour): string => hour.substr(0, 2);
export const hourToNumber = (hour: Hour): number => {
  return Number(getHourDisplay(hour));
};

export const dateTimeToHour = (date: DateTime): Hour => {
  let { hour } = date;
  if (hour === 0) hour = 24;
  return Object.values(Hour)[hour - 1] ?? Hour.Twentyfour;
};

export const dayToIsoWeekday: Record<Day, number> = {
  [Day.Monday]: 1,
  [Day.Tuesday]: 2,
  [Day.Wednesday]: 3,
  [Day.Thursday]: 4,
  [Day.Friday]: 5,
  [Day.Saturday]: 6,
  [Day.Sunday]: 7
};

export const sortDayArrayByIsoWeekDay = (days: Day[]): Day[] => {
  return sortBy(days, d => dayToIsoWeekday[d]);
};

export const isContinuousRange = (days: Day[]): boolean => {
  if (isEmpty(days)) return true;
  const sortedArray = sortDayArrayByIsoWeekDay(days);
  const lastElement = last(sortedArray);
  const firstElement = first(sortedArray);
  if (lastElement && firstElement) {
    const range =
      dayToIsoWeekday[lastElement] - dayToIsoWeekday[firstElement] + 1;
    return range === sortedArray.length;
  }
  return false;
};

export const sortDayArrayAsContinuous = (days: Day[]): Day[] => {
  if (isEmpty(days)) return days;
  return sortDayArrayByIsoWeekDay(days);
};

export const getApiHourText = (hour: ApiHour): string => {
  const firstCharacterIndex = hour.startsWith("0") ? 1 : 0;
  if (hour.endsWith(":00:00"))
    return hour.substring(firstCharacterIndex, hour.length - 6);
  if (hour.endsWith(":00"))
    return hour.substring(firstCharacterIndex, hour.length - 3);
  return hour.substring(firstCharacterIndex, hour.length);
};
