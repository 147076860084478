import { Model, OpenCode } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Quantity } from "model/primitives/quantity";
import { ResourceType } from "model/primitives/resourceModel";
import { EmailConfig } from "model/primitives/emailConfig";
import { MedicalDevice } from "model/resource/prescription/medicalDevice";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { PhoneConfig } from "model/primitives/phoneConfig";
import { ProcedureReportDefinition } from "model/resource/procedures/procedureReportDefinition/procedureReportDefinition";
import {
  ActivityDefinition,
  ActivityDefinitionKind
} from "model/resource/activity/activityDefinition";
import { MedicalRequestDefinitionField } from "./medicalRequestDefinitionField";

export enum MedicalRequestDefinitionPharmacologyCategory {
  Drug = "Drug",
  AerosolTherapy = "AerosolTherapy",
  InsulinCorrection = "InsulinCorrection",
  SkinCare = "SkinCare",
  Food = "Food",
  Parenteral = "Parenteral"
}

export enum MedicalRequestDefinitionNonPharmacologyCategory {
  NursingCare = "NursingCare",
  NursingProcedures = "NursingProcedures",
  Implements = "Implements",
  RespiratoryTractProcedure = "RespiratoryTractProcedure",
  MedicalProcedure = "MedicalProcedure",
  Other = "Other"
}

export type KnownMedicalRequestDefinitionCategory =
  | MedicalRequestDefinitionPharmacologyCategory
  | MedicalRequestDefinitionNonPharmacologyCategory;

export type MedicalRequestDefinitionCategory =
  OpenCode<KnownMedicalRequestDefinitionCategory>;

export enum MedicalRequestDefinitionDeviceCondition {
  Always = "Always",
  Pediatric = "Pediatric",
  Adult = "Adult",
  WithTracheostomy = "WithTracheostomy",
  WithoutTracheostomy = "WithoutTracheostomy",
  Jeyunostomy = "Jeyunostomy",
  Gastrostomy = "Gastrostomy",
  NasogastricTube = "NasogastricTube"
}

export enum MedicalRequestDefinitionDeviceUse {
  Suggested = "Suggested",
  AlwaysOnReport = "AlwaysOnReport"
}

export interface MedicalRequestDefinitionDevice extends Quantity, Model {
  device?: ModelReference<MedicalDevice>;
  condition?: MedicalRequestDefinitionDeviceCondition[];
  use?: MedicalRequestDefinitionDeviceUse;
}

export const MedicalRequestDefinitionDeviceKey =
  getKeyObj<MedicalRequestDefinitionDevice>(
    createHydratedMock<MedicalRequestDefinitionDevice>()
  );

export enum MedicalRequestDefinitionAdministrationTimeframeType {
  ByHour = "ByHour",
  ByDay = "ByDay",
  ByShift = "ByShift"
}

export enum MedicalRequestDefinitionFlowConfig {
  IsAdministrable = "IsAdministrable",
  IsHospitalization = "IsHospitalization",
  IsPrescribable = "IsPrescribable",
  IsExpirable = "IsExpirable",
  IsProcedureReport = "IsProcedureReport",
  IsRegistrationRequired = "IsRegistrationRequired",
  NonPrintable = "NonPrintable"
}

export interface MedicalRequestDefinition
  extends ActivityDefinition<
    ResourceType.MedicalRequestDefinition,
    ActivityDefinitionKind.MedicalRequest
  > {
  category: MedicalRequestDefinitionCategory;
  fields: MedicalRequestDefinitionField[];
  renewPeriod?: number;
  medicalDevice: MedicalRequestDefinitionDevice[];
  emailConfig?: EmailConfig[];
  phoneConfig?: PhoneConfig[];
  administrationTimeframeType?: MedicalRequestDefinitionAdministrationTimeframeType;
  order?: number;
  color?: string;
  administrationEditPeriod?: number;
  prescriptionDatePeriod?: number;
  flowConfig?: MedicalRequestDefinitionFlowConfig[];
  procedureReportDefinition?: ModelReference<ProcedureReportDefinition>;
}

export const MedicalRequestDefinitionKey = getKeyObj<MedicalRequestDefinition>(
  createHydratedMock<MedicalRequestDefinition>()
);
