export const notification = {
  title: "Notificaciones",
  fields: {
    id: "Id",
    sender: "Sender",
    senderId: "Sender id",
    message: "Mensaje",
    receiverType: "Tipo de sender",
    receiver: "Receiver",
    status: "Estado",
    notificationActionCode: "Código de notificacion",
    scheduledDate: "Fecha programada",
    creationDate: "Fecha de creacion",
    lastUpdatedDate: "Fecha de última edición",
    logicType: "Lógica de procesamiento",
    sessionInitMessage: "Mensaje de inicio se sesión",
    messageTitle: "Título del mensaje (solo para emails)",
    messageBody: "Cuerpo del mensaje",
    mediaUrl: "Archivos adjuntos",
    mediaUrlItem: "Archivo",
    appointmentId: "Id de turno",
    patientId: "Id de paciente",
    practitionerId: "Id de practitioner",
    organizationId: "Id de organización",

    whatsappNotificationList: "Whatsapps",
    whatsappNotificationId: "Id",
    whatsappNotificationVersion: "Version",
    whatsappNotificationMessage: "Mensaje",
    whatsappNotificationStatus: "Estado",
    whatsappNotificationExternalId: "Id externa",
    whatsappNotificationMessageType: "Tipo de mensaje",
    whatsappNotificationLastUpdated: "Ultima actualización",
    whatsappNotificationCreationUpdated: "Fecha de creación"
  },
  tabs: {
    main: "Notificación",
    whatsapps: "Whatsapps"
  },
  filters: {
    id: "Id",
    organization: "Organizacion",
    practitioner: "Profesional",
    patient: "Paciente",
    appointment: "Turno",
    status: "Estado",
    receiver: "Destinatario",
    receiverType: "Tipo"
  }
};
