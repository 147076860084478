import { ResourceType } from "model/primitives/resourceModel";
import {
  ApiDate,
  getKeyObj,
  ModelId,
  notUndefined,
  Optional
} from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { BedContainer } from "model/resource/entities/location/bed";
import {
  Encounter,
  EncounterClass,
  EncounterStatus
} from "model/resource/entities/encounter/encounter";
import { PatientTag } from "model/resource";
import { Code } from "model/resource/entities/codeSystem";
import { Patient } from "model/resource/person";
import { getModelReferenceId, ModelReference } from "model/primitives";
import {
  createBaseEncounterLocation,
  Location,
  LocationType
} from "model/resource/entities";
import {
  KnownPatientTagObservationCode,
  ObservationStatus
} from "../observation";

export interface Hospitalization
  extends Encounter<ResourceType.Hospitalization, EncounterClass.Inpatient> {
  currentBed?: BedContainer;
  admitSource?: string;
  dischargeDisposition?: string;
  dischargeObservation?: string;
}

export interface HospitalizationWithExtraData {
  hospitalization: Hospitalization;
  tagList?: PatientTag[];
}

export const HospitalizationKey = getKeyObj<Hospitalization>(
  createHydratedMock<Hospitalization>()
);

export const getHospitalizationWithExtraDataId = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<ModelId> => {
  return hospitalizationWithExtraData?.hospitalization.id;
};

export const HospitalizationWithExtraDataKey =
  getKeyObj<HospitalizationWithExtraData>(
    createHydratedMock<HospitalizationWithExtraData>()
  );

interface CreateBaseHospitalizationProps {
  organizationId: ModelId;
  patientId?: ModelId;
  status?: EncounterStatus;
  bed?: ModelReference<Location>;
  building?: ModelReference<Location>;
  startDate?: ApiDate;
}

export enum HospitalizationPatientWristbandSize {
  Child = "child",
  Adult = "adult",
  Big = "big"
}

export interface HospitalizationWristbandSizeData {
  size?: HospitalizationPatientWristbandSize;
}

export const HospitalizationWristbandSizeDataKey =
  getKeyObj<HospitalizationWristbandSizeData>(
    createHydratedMock<HospitalizationWristbandSizeData>()
  );

export const createBaseHospitalization = ({
  status,
  organizationId,
  patientId,
  bed,
  building,
  startDate
}: CreateBaseHospitalizationProps): Hospitalization => {
  const bedEncounterLocation = bed
    ? createBaseEncounterLocation(bed, LocationType.Bed)
    : undefined;
  const buildingEncounterLocation = building
    ? createBaseEncounterLocation(building, LocationType.Building)
    : undefined;
  return {
    resourceType: ResourceType.Hospitalization,
    organization: organizationId,
    patient: patientId,
    status: status ?? EncounterStatus.Planned,
    location:
      bed || building
        ? [bedEncounterLocation, buildingEncounterLocation].filter(notUndefined)
        : undefined,
    startDate
  };
};

export const createBaseHospitalizationWithExtraData = (
  props: CreateBaseHospitalizationProps
): HospitalizationWithExtraData => {
  return {
    tagList: [],
    hospitalization: createBaseHospitalization(props)
  };
};

export const createBasePatientTag = (
  patientTagCode: Code,
  patient?: ModelReference<Patient>,
  hospitalization?: ModelReference<Hospitalization>
): PatientTag => ({
  resourceType: ResourceType.PatientTag,
  type: KnownPatientTagObservationCode.Tag,
  status: ObservationStatus.Preliminary,
  value: patientTagCode,
  patient: getModelReferenceId(patient),
  encounter: getModelReferenceId(hospitalization)
});
