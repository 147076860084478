import { Model, OpenCode } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { QuantityUnit } from "model/primitives/quantity";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Code } from "model/resource/entities/codeSystem";
import { Identifier } from "model/primitives/identifier";
import { MedicationSubstance } from "../medicationSubstance";
import { Organization } from "../../entities/organization/organization";
import { RouteOption } from "../medicalRequest/medicalRequestRoute";

export enum MedicationAmountKnownUnit {
  Meter = "m",
  Kilogram = "kg",
  Unit = "unidad",
  Milliliters = "ml",
  Milligrams = "mg",
  Gram = "g",
  Microgram = "ug",
  Nanogram = "ng",
  InternationalUnits = "IU",
  InternationalMiliunits = "mIU",
  Application = "aplicación",
  Drops = "gotas",
  Tablet = "comprimido",
  Ampoule = "ampolla",
  Capsule = "cápsula",
  Container = "envase",
  Envelope = "sobre",
  VaginalOvum = "óvulo vaginal",
  Puff = "puff",
  Flask = "frasco"
}
export type MedicationAmountUnit = OpenCode<MedicationAmountKnownUnit>;

export enum MedicationStatus {
  Active = "active",
  Inactive = "inactive"
}

export enum MedicationKnownForm {
  Aerosol = "aerosol",
  Ampoule = "ampolla",
  Capsule = "cápsula",
  SoftCapsule = "cápsula blanda",
  CapsuleWithPowder = "cápsula con polvo para inhalar",
  ExtendedReleaseCapsule = "cápsula de liberación prolongada",
  Tablet = "comprimido",
  ControlledReleaseTablet = "comprimido de liberación controlada",
  ProlongedReleaseTablet = "comprimido de liberación prolongada",
  EffervescentTablet = "comprimido efervescente",
  ScoredTablet = "comprimido ranurado",
  CoatedTablet = "comprimido recubierto",
  SublingualTablet = "comprimido sublingual",
  VaginalTablet = "comprimido vaginal",
  Cream = "crema",
  Emulsion = "emulsión",
  Foam = "espuma",
  BlisterVial = "frasco ampolla",
  DermalGel = "gel dérmico",
  OphthalmicGel = "gel oftálmico",
  Drops = "gotas",
  EyeDrops = "gotas oftálmicas",
  OralDrops = "gotas orales",
  EarDrops = "gotas oticas",
  Syrup = "jarabe",
  PrefilledSyringe = "jeringa prellenada",
  Lotion = "loción",
  VaginalOvum = "óvulo vaginal",
  TransdermalPatch = "parche transdérmico",
  Powder = "polvo",
  LyophilizedPowder = "polvo liofilizado",
  InhalePowder = "polvo para inhalar",
  Pomade = "pomada",
  Pot = "pote",
  Shampoo = "shampoo",
  Solution = "solución",
  InjectableSolution = "solución inyectable",
  Spray = "spray",
  NasalSpray = "spray nasal",
  Suppository = "supositorio",
  Suspension = "suspensión",
  ExtemporaneousSuspension = "suspensión extemporanea",
  OralSuspension = "suspensión oral",
  RectalSuspension = "suspensión rectal",
  Ointment = "ungüento",
  OphthalmicOintment = "ungüento oftalmico"
}
export type MedicationForm = OpenCode<MedicationKnownForm>;

export enum MedicationKnownPresentationUnit {
  Unit = "unidad",
  Tablet = "comprimido",
  Capsule = "cápsula",
  BlisterVial = "frasco ampolla",
  Flask = "frasco",
  Tube = "tubo",
  VaginalOvum = "óvulo vaginal",
  Bag = "bolsa",
  Suppository = "supositorio",
  Sachet = "sachet",
  Syringe = "jeringa",
  Patch = "parche",
  Ampoule = "ampolla"
}

export type MedicationPresentationUnit =
  OpenCode<MedicationKnownPresentationUnit>;

export interface MedicationAmount {
  value?: number;
  unit?: QuantityUnit;
  denominatorValue?: number;
  denominatorUnit?: QuantityUnit;
}

export const MedicationAmountKey = getKeyObj<MedicationAmount>(
  createHydratedMock<MedicationAmount>()
);

export interface MedicationIngredient extends Model {
  substance?: ModelReference<MedicationSubstance>;
  amount?: MedicationAmount;
}

export const MedicationIngredientKey = getKeyObj<MedicationIngredient>(
  createHydratedMock<MedicationIngredient>()
);

export enum MedicationGenericType {
  Generic = "Generic",
  Commercial = "Commercial"
}

export interface Medication extends ResourceModel<ResourceType.Medication> {
  name?: string;
  alternativeName: string[];
  description?: string;
  snomedCode?: string;
  status: MedicationStatus;
  identifier?: Identifier[];
  form?: MedicationForm;
  presentationUnit?: MedicationPresentationUnit;
  presentationUnitAmount?: MedicationAmount;
  presentationUnitFractions?: number;
  healthcareUnits: MedicationAmount[];
  routeList: RouteOption[];
  ingredient: MedicationIngredient[];
  characteristic?: Code[];
  organization: ModelReference<Organization>;
  functionCode?: string;
  presentationList: string[];
  genericType?: MedicationGenericType;
  instanceOf?: ModelReference<Medication>;
}

export const MedicationKey = getKeyObj<Medication>(
  createHydratedMock<Medication>()
);
