// Token reference https://moment.github.io/luxon/#/formatting?id=table-of-tokens

export enum DateFormat {
  FilenameSafeSpanish = "dd-LL-y", // 05-04-1993
  ReportDate = "y-LL-dd", // 1996-02-28
  Spanish = "dd/LL/yyyy", // 05/04/1993
  TwoDigitsYear = "ddLLyy", // 050493
  DetailDate = "d LLLL y'.'", // 5 April 2001.
  DetailDateTime = "d LLLL y hh':'mm a", // 5 April 1924 12:00 AM
  MedicalRequestTimestampedDate = "dd/LL/yy hh':'mm a", // 05/04/93 12:00 AM
  DayMonthAbb = "dd.LLL", // 05.Apr.
  OnlyHour = "HH", // 00
  HourMinuteAndText = "H':'mm' HS'", // 00:00 HS
  NoSecTime = "HH:mm", // 00:00
  SecTime = "HH:mm:ss", // 00:00:00
  SecTimeIn12HourTime = "hh:mm:ss", // 00:00:00
  DateTimeLong = "dd/LL/yy H':'mm'HS.'", // 05/04/93 0:00HS.
  InputDate = "dd/LL/yy", // 05/04/93
  WeekAndNumber = "cccc d", // martes 05
  WeekDayMonthDayAndMonth = "cccc dd LLL", // Viernes 06 Nov
  MonthDayYear = "LLLL dd',' yyyy", // July 20, 2021,
  FullDateWithWeekday = "DDDD", // Miércoles 28 de Agosto de 2021
  ShortWeekdayInputDate = "ccc dd.LL.yy", // Lun 27.12.21
  ApiDay = "yyyy-LL-dd",
  MonthAbbAndYear = "LLL yyyy", // OCT 2022,
  FullMonthAndYear = "LLLL yyyy", // OCTUBRE 2022
  FullDayMonthAndYear = "cccc dd LLLL", // martes 28 febrero
  HourMinuteAndMeridian = "HH':'mm' 'a", // 12:00 AM
  ApiHour = "HH:mm:ss.SSSZZ",
  WeekDayMonthDayMonthAndYear = "cccc, dd LLLL yyyy", // MIÉRCOLES, 04 JULIO 2022
  WeekDayAbbMonthDayMonthAbbAndYear = "ccc, dd LLL yyyy", // MIÉ, 04 JULIO 2022
  JotformDate = "yyyy-LL-dd HH:mm",
  ExcelDateTime = "d/L/y H':'mm",
  ExcelDate = "d/L/y",
  ExcelDateUS = "L/d/yy"
}
