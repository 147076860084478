import { createStyle, marginMixin } from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useErrorTextStyles = createStyle(
  (theme: Theme) => ({
    errorText: {
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ left: 12 }),
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    }
  }),
  "ErrorText"
);
