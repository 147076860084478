import {
  Attachment,
  getIdentifierValueBySystem,
  getModelOrUndefined,
  KnownIdentifierSystem
} from "model/primitives";
import { Patient } from "model/resource/person";
import {
  ApiDate,
  applyFormatToDate,
  DateFormat,
  dateIsAfter,
  DateTime,
  getDaysAgo,
  getDaysDiff,
  getEnumOrUndefined,
  Optional
} from "@laba/ts-common";
import { isEqual } from "lodash-es";
import {
  Hospitalization,
  HospitalizationWithExtraData,
  isEncounterInProgress
} from "model/resource";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import {
  CodeSystemCode,
  Location,
  LocationType,
  Organization
} from "model/resource/entities";
import { Diagnosis, KnownDiagnosisRole } from "../condition";
import { PatientTag } from "../observation";

export const getPatientFromHospitalizationWithExtraData = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<Patient> =>
  getModelOrUndefined(hospitalizationWithExtraData?.hospitalization.patient);

const getHospitalizationWithExtraDataStartDate = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<ApiDate> => hospitalizationWithExtraData?.hospitalization.startDate;

export const getHospitalizationWithExtraDataFormattedStartDate = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData,
  dateFormat = DateFormat.Spanish
): Optional<string> =>
  applyFormatToDate(
    getHospitalizationWithExtraDataStartDate(hospitalizationWithExtraData),
    dateFormat
  );

export const getHospitalizationWithExtraDataAdmitSource = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<string> =>
  hospitalizationWithExtraData?.hospitalization.admitSource;

export const getDischargeDiagnosisFromHospitalizationWithExtraData = (
  hospitalizationWithExtraData: HospitalizationWithExtraData
): Optional<Diagnosis> =>
  hospitalizationWithExtraData.hospitalization.diagnosis?.find(
    diagnosis => diagnosis.use === KnownDiagnosisRole.DD
  )?.condition;

const getAdmissionDiagnosisFromHospitalizationWithExtraData = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<Diagnosis> =>
  hospitalizationWithExtraData?.hospitalization.diagnosis?.find(
    diagnosis => diagnosis.use === KnownDiagnosisRole.AD
  )?.condition;

export const getAdmissionDiagnosisCodeSystemCodeFromHospitalizationWithExtraData =
  (
    hospitalizationWithExtraData?: HospitalizationWithExtraData
  ): Optional<CodeSystemCode> =>
    getModelOrUndefined(
      getAdmissionDiagnosisFromHospitalizationWithExtraData(
        hospitalizationWithExtraData
      )
    )?.code;

export const getStatusFromHospitalizationWithExtraData = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<EncounterStatus> =>
  getEnumOrUndefined(EncounterStatus)(
    hospitalizationWithExtraData?.hospitalization.status
  );

export const getHospitalizationTypeFromHospitalizationWithExtraData = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<string> => hospitalizationWithExtraData?.hospitalization.type;

export const isHospitalizationWithExtraDataInProgress = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): boolean =>
  isEqual(
    getStatusFromHospitalizationWithExtraData(hospitalizationWithExtraData),
    EncounterStatus.InProgress
  );

export const isHospitalizationWithExtraDataFinished = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): boolean =>
  isEqual(
    getStatusFromHospitalizationWithExtraData(hospitalizationWithExtraData),
    EncounterStatus.Finished
  );

export const isHospitalizationWithExtraDataPlanned = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): boolean =>
  isEqual(
    getStatusFromHospitalizationWithExtraData(hospitalizationWithExtraData),
    EncounterStatus.Planned
  );

export const getHospitalizedDays = (
  hospitalization?: Hospitalization
): Optional<number> => {
  const startDate = DateTime.fromApiDateOrUndefined(hospitalization?.startDate);
  const finishDate = DateTime.fromApiDateOrUndefined(
    hospitalization?.finishDate
  );
  return isEncounterInProgress(hospitalization)
    ? getDaysAgo(startDate) + 1
    : startDate && finishDate && dateIsAfter(finishDate, startDate)
    ? getDaysDiff(startDate, finishDate) + 1
    : undefined;
};

const getHospitalizationEncounterLocation = (
  hospitalization: Hospitalization,
  locationType: LocationType
): Optional<Location> => {
  return getModelOrUndefined(
    hospitalization.location?.find(l => l.physicalType === locationType)
      ?.location
  );
};

export const getHospitalizationBuilding = (
  hospitalization: Hospitalization
): Optional<Location> => {
  return getHospitalizationEncounterLocation(
    hospitalization,
    LocationType.Building
  );
};

export const getHospitalizationBed = (
  hospitalization: Hospitalization
): Optional<Location> => {
  return getHospitalizationEncounterLocation(hospitalization, LocationType.Bed);
};

export const getHospitalizationWithExtraDataIdentifierValue = (
  knownIdentifierSystem: KnownIdentifierSystem,
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<string> =>
  getIdentifierValueBySystem(
    knownIdentifierSystem,
    hospitalizationWithExtraData?.hospitalization.identifier
  );

export const getHospitalizationWithExtraDataObservation = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<string> =>
  hospitalizationWithExtraData?.hospitalization.observation;

export const getHospitalizationWithExtraDataFileList = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Attachment[] => hospitalizationWithExtraData?.hospitalization.file ?? [];

export const getHospitalizationWithExtraDataTagList = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): PatientTag[] => hospitalizationWithExtraData?.tagList ?? [];

export const getHospitalizationWithExtraDataHospitalizationPayer = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<Organization> =>
  getModelOrUndefined(hospitalizationWithExtraData?.hospitalization.payer);

export const getHospitalizationWithExtraDataHospitalizationPayerName = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<string> =>
  getHospitalizationWithExtraDataHospitalizationPayer(
    hospitalizationWithExtraData
  )?.name;

export const getHospitalizationWithExtraDataHospitalizationPlan = (
  hospitalizationWithExtraData?: HospitalizationWithExtraData
): Optional<string> => hospitalizationWithExtraData?.hospitalization.plan;

export const getHospitalizationWithExtraDataBed = (
  hospitalization: HospitalizationWithExtraData
): Optional<Location> => {
  return getHospitalizationBed(hospitalization.hospitalization);
};

export const getHospitalizationWithExtraDataBuilding = (
  hospitalization: HospitalizationWithExtraData
): Optional<Location> => {
  return getHospitalizationBuilding(hospitalization.hospitalization);
};
