import { ReactElement } from "@laba/react-common";
import {
  SelectInput as ReactAdminSelectInput,
  Validator,
  RaRecord,
  OnCreateHandler
} from "react-admin";
import React, { ChangeEvent } from "react";
import { Optional } from "@laba/ts-common";

export enum SelectInputVariant {
  Standar = "standard",
  Filled = "filled",
  Outlined = "outlined"
}

export interface SelectInputChoice {
  id: string;
  name: string;
}

export interface SelectInputProps<T, R> {
  source: string;
  label: string;
  className?: string;
  variant?: SelectInputVariant;
  fullWidth?: boolean;
  choices?: SelectInputChoice[];
  alwaysOn?: boolean;
  resettable?: boolean;
  onCreate?: OnCreateHandler;
  createLabel?: string;
  defaultValue?: T[keyof T];
  validate?: Validator | Validator[];
  format?: (formValue: Optional<R>) => Optional<string>;
  parse?: (selectValue: Optional<string>) => Optional<R>;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement> | RaRecord) => void;
  create?: ReactElement;
}

export const SelectInput = <T, R>({
  source,
  label,
  className,
  variant = SelectInputVariant.Outlined,
  fullWidth,
  choices,
  alwaysOn,
  resettable,
  onCreate,
  createLabel,
  defaultValue,
  validate,
  format,
  parse,
  disabled,
  onChange,
  create
}: SelectInputProps<T, R>): ReactElement => {
  return (
    <ReactAdminSelectInput
      source={source}
      label={label}
      className={className}
      variant={variant}
      fullWidth={fullWidth}
      choices={choices}
      alwaysOn={alwaysOn}
      resettable={resettable}
      onCreate={onCreate}
      createLabel={createLabel}
      defaultValue={defaultValue}
      validate={validate}
      format={format}
      parse={parse}
      disabled={disabled}
      onChange={onChange}
      create={create}
    />
  );
};
