import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { ModelId } from "model/primitives/model/model";
import {
  CodeSystem,
  CodeSystemStatus,
  CodeSystemSystem
} from "model/resource/entities/codeSystem";
import { ElementOrArray, HttpMethod } from "@laba/ts-common";

export interface PatientCodeSystemListQueryParams extends ListQueryParams {
  id?: ModelId[];
  organization?: ModelId;
  status?: CodeSystemStatus;
  system?: ElementOrArray<CodeSystemSystem>;
}

const codeSystemBasePath = "/code-system";

export const getPatientCodeSystemList = (
  params?: PatientCodeSystemListQueryParams
): Promise<ApiPageRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(codeSystemBasePath),
    params
  });

export const getPatientCodeSystem = (
  id: ModelId
): Promise<ApiRequestResponse<CodeSystem>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontPatientPortalApiUrl(`${codeSystemBasePath}/${id}`)
  });
