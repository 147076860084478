import { ReactElement, useI18n } from "@laba/react-common";
import {
  AutocompleteInput,
  required as requiredValidate,
  Validator
} from "react-admin";
import React from "react";
import {
  SelectInput,
  SelectInputChoice,
  SelectInputVariant
} from "components/generic/SelectInput/SelectInput";
import { CodeSystemGroup, ModelId } from "@laba/nexup-api";
import { useCodeSystemSelectorInputStyle } from "components/generic/CodeSystemSelectorInput/codeSystemSelectorInputStyle";
import { tkCC } from "translation/i18n";
import { getAsArray, notNull, Optional } from "@laba/ts-common";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { isEmpty } from "lodash-es";
import { CodeSystemCreateOptionDialog } from "components/generic/CodeSystemSelectorInput/CodeSystemCreateOptionDialog";

export interface CodeSystemSelectorInputProps<T> {
  source: string;
  label: string;
  codeSystem?: CodeSystemGroup;
  organizationId?: ModelId;
  alwaysOn?: boolean;
  fullWidth?: boolean;
  resettable?: boolean;
  withCreate?: boolean;
  validate?: Validator | Validator[];
  required?: boolean;
  autocomplete?: boolean;
  parse?: (selectValue: Optional<string>) => Optional<T>;
  format?: (formValue: Optional<T>) => Optional<string>;
  extraOptions?: SelectInputChoice[];
}

const tk = tkCC.inputs.codeSystemSelectorInput;
export const CodeSystemSelectorInput = <T,>({
  source,
  label,
  codeSystem,
  alwaysOn = false,
  fullWidth = true,
  resettable = false,
  withCreate = false,
  validate,
  required,
  autocomplete,
  parse,
  format,
  organizationId,
  extraOptions
}: CodeSystemSelectorInputProps<T>): ReactElement => {
  const classes = useCodeSystemSelectorInputStyle();
  const currentValue = useWatchResourceForm<string>(source);
  const { t } = useI18n();
  const validateArray = getAsArray(validate).filter(notNull);
  if (required) validateArray.push(requiredValidate());

  const choices =
    codeSystem?.concept?.map(value => ({
      id: value.code,
      name: isEmpty(value.display)
        ? value.code
        : `${value.code} (${value.display})`
    })) ?? [];
  if (extraOptions) {
    const toPush: SelectInputChoice[] = extraOptions.filter(
      option => choices.find(x => x.id === option.id) == null
    );
    choices.push(...toPush);
  }
  if (currentValue && !choices.find(element => element.id === currentValue)) {
    choices.push({
      id: currentValue,
      name: currentValue
    });
  }

  const isAutocomplete = autocomplete ?? choices.length > 15;

  return isAutocomplete ? (
    <AutocompleteInput
      source={source}
      variant="outlined"
      fullWidth={fullWidth}
      choices={choices}
      shouldRenderSuggestions={() => true}
      noOptionsText={t(tk.noOptionsText)}
      className={classes.root}
      label={label}
      validate={validateArray}
      alwaysOn={alwaysOn}
      create={
        withCreate && codeSystem && organizationId ? (
          <CodeSystemCreateOptionDialog
            codeSystemGroup={codeSystem}
            organizationId={organizationId}
          />
        ) : undefined
      }
      createLabel={t(tk.createLabel)}
      parse={value => {
        return (parse ? parse(value) : value) ?? null;
      }}
      format={value => {
        return format ? format(value) : value;
      }}
    />
  ) : (
    <SelectInput
      variant={SelectInputVariant.Outlined}
      fullWidth={fullWidth}
      source={source}
      label={label}
      choices={choices}
      alwaysOn={alwaysOn}
      className={classes.root}
      resettable={resettable}
      create={
        withCreate && codeSystem && organizationId ? (
          <CodeSystemCreateOptionDialog
            codeSystemGroup={codeSystem}
            organizationId={organizationId}
          />
        ) : undefined
      }
      createLabel={t(tk.createLabel)}
      validate={validateArray}
      parse={parse}
      format={format}
    />
  );
};
