import React from "react";
import { FC, StyleVariant, TypeVariant } from "@laba/react-common";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import {
  OnClickUrlAction,
  useUrlLinkProps
} from "components/link/UrlLink/utils";
import { Noop } from "@laba/ts-common";
import { Snackbar } from "components/snackbar/Snackbar/Snackbar";
import { ContentCopyIcon } from "components/icons";

export interface UrlLinkButtonProps {
  className?: string;
  url?: string;
  snackbarText?: string;
  onClick?: Noop;
  onClickUrlAction: OnClickUrlAction;
  buttonText: string;
  type?: TypeVariant;
  onClickGetClipboardLink?: () => Promise<string>;
  disabled?: boolean;
}

export const UrlLinkButton: FC<UrlLinkButtonProps> = ({
  className,
  url,
  onClick,
  snackbarText,
  onClickUrlAction,
  buttonText,
  onClickGetClipboardLink,
  type = TypeVariant.Outlined,
  disabled
}) => {
  const { closeSnackbar, wrappedOnClick, isSnackBarOpen } = useUrlLinkProps({
    onClick,
    onClickUrlAction,
    snackbarText,
    url,
    onClickGetClipboardLink
  });
  return (
    <>
      <DefaultButton
        className={className}
        onClick={wrappedOnClick}
        StartIcon={ContentCopyIcon}
        text={buttonText}
        type={type}
        disabled={disabled}
      />
      <Snackbar
        isOpen={isSnackBarOpen}
        style={StyleVariant.Success}
        text={snackbarText}
        onClose={closeSnackbar}
      />
    </>
  );
};
