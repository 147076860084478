export const scheduleDefinition = {
  title: "Tipo de Agenda",
  filters: {
    organization: "Organizacion",
    status: "Estado"
  },
  tabs: {
    scheduleDefinition: "Definición de Agendas"
  },
  fields: {
    notificationConfigPatient: {
      reminderList: "Listado de recordatorios paciente",
      reminder: "Recordatorio minutos paciente",
      active: "Notificacion Activa paciente",
      notifyPhone: "Notificar al celular paciente",
      notifyEmail: "Notificar al mail paciente",
      codeList: "Listado de codigos paciente",
      code: "Codigo paciente"
    },
    notificationConfigPractitioner: {
      reminderList: "Listado de recordatorios profesional",
      reminder: "Recordatorio minutos profesional",
      active: "Notificacion Activa profesional",
      notifyPhone: "Notificar al celular profesional",
      notifyEmail: "Notificar al mail profesional",
      codeList: "Listado de codigos profesional",
      code: "Codigo profesional"
    }
  }
};
