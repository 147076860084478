import React from "react";
import { FC, ReactElementOrNull } from "@laba/react-common";
import { isEmpty } from "lodash-es";
import { getEnumOrUndefined } from "@laba/ts-common";
import { useFiltersHeaderInputsGrowStyle } from "../useFiltersHeaderStyle";
import { FiltersHeaderInputGrow, FiltersHeaderInputRow } from "../types";

export interface FiltersHeaderInput {
  row: FiltersHeaderInputRow;
  grow?: FiltersHeaderInputGrow;
  component: ReactElementOrNull;
  isFilterApplied: boolean;
}

export interface FiltersHeaderInputsRowProps {
  inputs?: FiltersHeaderInput[];
}

export const FiltersHeaderInputsRow: FC<FiltersHeaderInputsRowProps> = ({
  inputs
}) => {
  const classes = useFiltersHeaderInputsGrowStyle();

  if (!inputs || isEmpty(inputs)) return null;

  return (
    <>
      {inputs.map((item, idx) => {
        if (!item.component) return;
        const growEnum = getEnumOrUndefined(FiltersHeaderInputGrow)(item.grow);
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className={classes[growEnum ?? FiltersHeaderInputGrow.Grow1]}
          >
            {item.component}
          </div>
        );
      })}
    </>
  );
};
