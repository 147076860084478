import {
  ApiDate,
  applyFormatToDate,
  DateFormat,
  joinText,
  Optional
} from "@laba/ts-common";

export interface ResourceDateAndPractitioner {
  date?: ApiDate;
  practitionerName: string;
  label: string;
}

export const getHeaderDateText = (
  data?: ResourceDateAndPractitioner,
  hideHour = false
): Optional<string> => {
  const day = applyFormatToDate(data?.date, DateFormat.Spanish);
  const hour = applyFormatToDate(data?.date, DateFormat.HourMinuteAndText);
  const labelAndPractitioner = joinText(
    [data?.label, data?.practitionerName],
    " "
  );
  return data?.practitionerName
    ? joinText([labelAndPractitioner, day, !hideHour ? hour : undefined], " | ")
    : undefined;
};
