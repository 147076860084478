import { ApiRequestResponse } from "request/types";
import { VideoAppointmentEncounter } from "model/resource/entities/encounter/appointmentEncounter";
import { getFrontPatientPortalApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";

const AppointmentBasePath = "/appointment";

export const attendPatientAppointment = (
  hash: string // HIS-6871: change param to receive id: ModelId
): Promise<ApiRequestResponse<VideoAppointmentEncounter>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontPatientPortalApiUrl(`${AppointmentBasePath}/attend`),
    data: { hash }
  });
