import {
  ElevationVariant,
  StyleVariant,
  TextVariant,
  TextWeight
} from "model/themeVariant";
import { NexupColor } from "model/nexupColor";
import {
  colorChangeAlpha,
  FontConfigCase,
  GradientType,
  GradientVariant
} from "@laba/react-common";
import {
  RadiusVariant,
  Theme,
  ThemeStyleColorVariant,
  ThemeStyleVariantStateData
} from "model/theme";
import { DeepRequired } from "@laba/ts-common";

const getStateData = (
  mainColor: NexupColor,
  disabled = NexupColor.MediumGray,
  disabledBackground = NexupColor.LightGray
): ThemeStyleVariantStateData => ({
  hoverOpacity: 0.08,
  focusOpacity: 0.24,
  selectedOpacity: 0.16,
  activatedOpacity: 0.24,
  hover: colorChangeAlpha(mainColor, 0.08),
  focus: colorChangeAlpha(mainColor, 0.24),
  selected: colorChangeAlpha(mainColor, 0.16),
  active: colorChangeAlpha(mainColor, 0.24),
  disabledOpacity: 0.38,
  disabled,
  disabledBackground
});

const getGrayData = () => ({
  [ThemeStyleColorVariant.GrayDark]: NexupColor.Black,
  [ThemeStyleColorVariant.GrayMedium]: NexupColor.DarkGray,
  [ThemeStyleColorVariant.GrayLight]: NexupColor.MediumGray
});

const getBackgroundColorData = () => ({
  [ThemeStyleColorVariant.Background]: NexupColor.Background
});

export const defaultTheme: DeepRequired<Theme> = {
  isNexupComponentTheme: true,
  style: {
    [StyleVariant.Primary]: {
      state: getStateData(NexupColor.MainPurple),
      color: {
        [ThemeStyleColorVariant.Light]: NexupColor.LightPurple,
        [ThemeStyleColorVariant.Main]: NexupColor.MainPurple,
        [ThemeStyleColorVariant.Dark]: NexupColor.DarkPurple,
        [ThemeStyleColorVariant.Contrast]: NexupColor.White,
        ...getGrayData(),
        ...getBackgroundColorData()
      }
    },
    [StyleVariant.Danger]: {
      state: getStateData(NexupColor.Salmon),
      color: {
        [ThemeStyleColorVariant.Light]: NexupColor.MediumSalmon,
        [ThemeStyleColorVariant.Main]: NexupColor.Salmon,
        [ThemeStyleColorVariant.Dark]: NexupColor.DarkSalmon,
        [ThemeStyleColorVariant.Contrast]: NexupColor.White,
        ...getGrayData(),
        ...getBackgroundColorData()
      }
    },
    [StyleVariant.Warning]: {
      state: getStateData(NexupColor.Orange),
      color: {
        [ThemeStyleColorVariant.Light]: NexupColor.MediumOrange,
        [ThemeStyleColorVariant.Main]: NexupColor.Orange,
        [ThemeStyleColorVariant.Dark]: NexupColor.DarkOrange,
        [ThemeStyleColorVariant.Contrast]: NexupColor.White,
        ...getGrayData(),
        ...getBackgroundColorData()
      }
    },
    [StyleVariant.Success]: {
      state: getStateData(NexupColor.Green),
      color: {
        [ThemeStyleColorVariant.Light]: NexupColor.LightGreen,
        [ThemeStyleColorVariant.Main]: NexupColor.Green,
        [ThemeStyleColorVariant.Dark]: NexupColor.DarkGreen,
        [ThemeStyleColorVariant.Contrast]: NexupColor.White,
        ...getGrayData(),
        ...getBackgroundColorData()
      }
    },
    [StyleVariant.Info]: {
      state: getStateData(NexupColor.Blue),
      color: {
        [ThemeStyleColorVariant.Light]: NexupColor.LightBlue,
        [ThemeStyleColorVariant.Main]: NexupColor.Blue,
        [ThemeStyleColorVariant.Dark]: NexupColor.DarkBlue,
        [ThemeStyleColorVariant.Contrast]: NexupColor.White,
        ...getGrayData(),
        ...getBackgroundColorData()
      }
    },
    [StyleVariant.BlackWhite]: {
      state: getStateData(NexupColor.Black),
      color: {
        [ThemeStyleColorVariant.Light]: NexupColor.MediumGray,
        [ThemeStyleColorVariant.Main]: NexupColor.Black,
        [ThemeStyleColorVariant.Dark]: NexupColor.DarkGray,
        [ThemeStyleColorVariant.Contrast]: NexupColor.White,
        ...getGrayData(),
        ...getBackgroundColorData()
      }
    }
  },
  text: {
    [TextVariant.H1]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Light,
      fontSize: 96,
      case: FontConfigCase.None,
      letterSpacing: -1.5
    },
    [TextVariant.H2]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Light,
      fontSize: 60,
      case: FontConfigCase.None,
      letterSpacing: -0.5
    },
    [TextVariant.H3]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Bold,
      fontSize: 48,
      case: FontConfigCase.None,
      letterSpacing: 0
    },
    [TextVariant.H4]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Bold,
      fontSize: 34,
      case: FontConfigCase.None,
      letterSpacing: 0.25
    },
    [TextVariant.H5]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Bold,
      fontSize: 22,
      case: FontConfigCase.None,
      letterSpacing: 0
    },
    [TextVariant.H6]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Medium,
      fontSize: 18,
      case: FontConfigCase.None,
      letterSpacing: 0.15
    },
    [TextVariant.Subtitle1]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Medium,
      fontSize: 16,
      case: FontConfigCase.None,
      letterSpacing: 0.15
    },
    [TextVariant.Subtitle2]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Medium,
      fontSize: 14,
      case: FontConfigCase.None,
      letterSpacing: 0.1
    },
    [TextVariant.Body1]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Regular,
      fontSize: 16,
      case: FontConfigCase.None,
      letterSpacing: 0.5
    },
    [TextVariant.Body2]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Regular,
      fontSize: 14,
      case: FontConfigCase.None,
      letterSpacing: 0.25
    },
    [TextVariant.Button]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Medium,
      fontSize: 14,
      case: FontConfigCase.UpperCase,
      letterSpacing: 1.25
    },
    [TextVariant.Caption]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Regular,
      fontSize: 12,
      case: FontConfigCase.None,
      letterSpacing: 0.4
    },
    [TextVariant.Overline]: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: TextWeight.Medium,
      fontSize: 10,
      case: FontConfigCase.UpperCase,
      letterSpacing: 1.5
    }
  },
  radius: {
    [RadiusVariant.Large]: 8,
    [RadiusVariant.Medium]: 4,
    [RadiusVariant.Small]: 2
  },
  elevation: {
    [ElevationVariant.Elevation0]: {
      offsetX: 0,
      offsetY: 0,
      blurRadius: 0,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation1]: {
      offsetX: 0,
      offsetY: 1,
      blurRadius: 3,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation2]: {
      offsetX: 0,
      offsetY: 1,
      blurRadius: 5,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation3]: {
      offsetX: 0,
      offsetY: 1,
      blurRadius: 8,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation4]: {
      offsetX: 0,
      offsetY: 2,
      blurRadius: 4,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation6]: {
      offsetX: 0,
      offsetY: 3,
      blurRadius: 5,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation8]: {
      offsetX: 0,
      offsetY: 5,
      blurRadius: 5,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation9]: {
      offsetX: 0,
      offsetY: 5,
      blurRadius: 6,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation12]: {
      offsetX: 0,
      offsetY: 7,
      blurRadius: 8,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation16]: {
      offsetX: 0,
      offsetY: 8,
      blurRadius: 10,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    },
    [ElevationVariant.Elevation24]: {
      offsetX: 0,
      offsetY: 11,
      blurRadius: 15,
      transitionS: 0.5,
      color: colorChangeAlpha(NexupColor.Black, 0.2)
    }
  },
  gradient: {
    [GradientVariant.MainGradient]: {
      colorList: [
        NexupColor.LightPurple,
        NexupColor.MainPurple,
        NexupColor.Lilac
      ],
      gradientType: GradientType.Linear,
      linearDirection: "90deg",
      radialShape: "",
      radialSize: "",
      radialPosition: ""
    },
    [GradientVariant.Gradient1]: {
      colorList: [
        NexupColor.DarkPurple,
        NexupColor.MainPurple,
        NexupColor.LightPurple
      ],
      gradientType: GradientType.Linear,
      linearDirection: "90deg",
      radialShape: "",
      radialSize: "",
      radialPosition: ""
    },
    [GradientVariant.Gradient2]: {
      colorList: [
        NexupColor.Lilac,
        NexupColor.MainPurple,
        NexupColor.LightPurple
      ],
      gradientType: GradientType.Linear,
      linearDirection: "90deg",
      radialShape: "",
      radialSize: "",
      radialPosition: ""
    },
    [GradientVariant.Gradient3]: {
      colorList: [NexupColor.DarkBlue, NexupColor.Blue, NexupColor.LightBlue],
      gradientType: GradientType.Linear,
      linearDirection: "90deg",
      radialShape: "",
      radialSize: "",
      radialPosition: ""
    },
    [GradientVariant.Gradient4]: {
      colorList: [
        NexupColor.SuperDarkPurple,
        NexupColor.DarkPurple,
        NexupColor.LightBlue
      ],
      gradientType: GradientType.Linear,
      linearDirection: "90deg",
      radialShape: "",
      radialSize: "",
      radialPosition: ""
    }
  }
};
