import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  AddressKey,
  ContactPointKey,
  ContactPointRole,
  ContactPointType,
  KnownCodeSystemSystem,
  ModelId,
  OrganizationCombinedType,
  OrganizationFlowConfig,
  OrganizationHospitalizationType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  OrganizationType,
  ResourceType
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { IdentifierInput } from "components/generic/IdentifierInput/IdentifierInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { UploadFileImageInput } from "components/generic/UploadFileImageInput/UploadFileImageInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { FlowConfigInput } from "components/generic/FlowConfigInput/FlowConfigInput";

export const tk = tkCP.adminPage[ResourceType.Organization];
export const OrganizationMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organizationId = useWatchResourceForm<ModelId>(OrganizationKey.id);

  const currentOrWorkspaceOrganizationId =
    organizationId ?? workspaceOrganizationId;

  const organizationSpecialitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationSpeciality,
    organizationId ?? workspaceOrganizationId
  );

  const organizationInterestSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationInterest,
    organizationId ?? workspaceOrganizationId
  );

  const organizationTimezoneIanaSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationTimezoneIana,
    organizationId ?? workspaceOrganizationId
  );

  const organizationCountrySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.OrganizationCountry,
    organizationId ?? workspaceOrganizationId
  );

  return (
    <>
      <TextInput disabled source={OrganizationKey.id} label={t(tk.fields.id)} />
      <TextInput source={OrganizationKey.name} label={t(tk.fields.name)} />
      <TextInput
        source={OrganizationKey.photo.name}
        label={t(tk.fields.photoName)}
      />
      <UploadFileImageInput
        source={OrganizationKey.photo.url}
        label={t(tk.fields.photo)}
        organization={currentOrWorkspaceOrganizationId}
      />
      <EnumSelectorInput
        source={OrganizationKey.type}
        label={t(tk.fields.type)}
        enumValue={OrganizationType}
      />
      <OrganizationReferenceInput
        source={OrganizationKey.partOf}
        label={t(tk.fields.partOf)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        defaultValue={
          organizationId === workspaceOrganizationId
            ? undefined
            : workspaceOrganizationId
        }
        disabled={workspaceOrganizationId !== undefined}
      />
      <EnumSelectorInput
        source={OrganizationKey.hospitalizationType}
        label={t(tk.fields.hospitalizationType)}
        enumValue={OrganizationHospitalizationType}
      />
      <IdentifierInput
        source={OrganizationKey.identifier}
        organization={organizationId}
        system={KnownCodeSystemSystem.Identifier}
      />
      <ArrayInput
        source={OrganizationKey.serviceList}
        label={t(tk.fields.serviceList)}
      >
        <TextInput source="" label={t(tk.fields.serviceListElement)} />
      </ArrayInput>
      <CodeSystemSelectorInput
        source={OrganizationKey.country}
        label={t(tk.fields.country)}
        codeSystem={organizationCountrySystem}
        organizationId={currentOrWorkspaceOrganizationId}
      />
      <TextInput
        source={OrganizationKey.timezone}
        label={t(tk.fields.timezone)}
      />
      <CodeSystemSelectorInput
        source={OrganizationKey.timezoneIana}
        label={t(tk.fields.timezoneIana)}
        codeSystem={organizationTimezoneIanaSystem}
        organizationId={currentOrWorkspaceOrganizationId}
      />
      <BooleanInput
        source={OrganizationKey.public}
        label={t(tk.fields.public)}
      />
      <FlowConfigInput
        enumOptions={OrganizationFlowConfig}
        source={OrganizationKey.flowConfig}
      />
      <ArrayInput
        source={OrganizationKey.ipWhitelist}
        label={t(tk.fields.ipWhitelist)}
      >
        <TextInput source="" label={t(tk.fields.ipWhitelistElement)} />
      </ArrayInput>
      <BooleanInput
        source={OrganizationKey.active}
        label={t(tk.fields.active)}
        defaultValue
      />
      <ArrayInput source={OrganizationKey.phone} label={t(tk.fields.phone)}>
        <TextInput
          source={ContactPointKey.value}
          label={t(tk.fields.phoneValue)}
        />
        <EnumSelectorInput
          source={ContactPointKey.role}
          label={t(tk.fields.phoneRole)}
          enumValue={ContactPointRole}
        />
        <EnumSelectorInput
          source={ContactPointKey.type}
          label={t(tk.fields.phoneType)}
          enumValue={ContactPointType}
        />
      </ArrayInput>
      <ArrayInput source={OrganizationKey.email} label={t(tk.fields.email)}>
        <TextInput
          source={ContactPointKey.value}
          label={t(tk.fields.emailValue)}
        />
        <EnumSelectorInput
          source={ContactPointKey.role}
          label={t(tk.fields.emailRole)}
          enumValue={ContactPointRole}
        />
        <EnumSelectorInput
          source={ContactPointKey.type}
          label={t(tk.fields.emailType)}
          enumValue={ContactPointType}
        />
      </ArrayInput>
      <ArrayInput source={OrganizationKey.address} label={t(tk.fields.address)}>
        <TextInput
          source={AddressKey.street}
          label={t(tk.fields.addressStreet)}
        />
        <TextInput
          source={AddressKey.streetNumber}
          label={t(tk.fields.addressStreetNumber)}
        />
        <TextInput
          source={AddressKey.directions}
          label={t(tk.fields.addressDirections)}
        />
        <TextInput source={AddressKey.city} label={t(tk.fields.addressCity)} />
        <TextInput
          source={AddressKey.state}
          label={t(tk.fields.addressState)}
        />
        <TextInput
          source={AddressKey.country}
          label={t(tk.fields.addressCountry)}
        />
        <TextInput
          source={AddressKey.postalCode}
          label={t(tk.fields.addressPostalCode)}
        />
      </ArrayInput>
      <ArrayInput
        source={OrganizationKey.speciality}
        label={t(tk.fields.specialtyList)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.specialtyElement)}
          codeSystem={organizationSpecialitySystem}
          organizationId={currentOrWorkspaceOrganizationId}
        />
      </ArrayInput>
      <ArrayInput
        source={OrganizationKey.interest}
        label={t(tk.fields.interestList)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.interestElement)}
          codeSystem={organizationInterestSystem}
          organizationId={currentOrWorkspaceOrganizationId}
        />
      </ArrayInput>
      <TextInput
        source={OrganizationKey.interestObservation}
        label={t(tk.fields.interestObservation)}
      />
    </>
  );
};
