import { ThemeProvider } from "@material-ui/core/styles";
import { borderMixin, FC, useTheme } from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { currentLocale, DateTime, isSameDay } from "@laba/ts-common";
import {
  BadgedComponent,
  BadgePosition
} from "components/containers/BadgedComponent/BadgedComponent";
import { some } from "lodash-es";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { getThemeStyleColor } from "model/themeUtils";
import { useMuiTheme } from "model/useMuiTheme";

export interface CalendarProps {
  style?: StyleVariant;
  className?: string;
  value?: DateTime;
  onChange: (d?: DateTime) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
  badgedDays?: DateTime[];
}

const dateStyleOverrides = (theme: Theme) => {
  return {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      },
      iconButton: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      }
    },
    MuiPickersDay: {
      day: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        )
      },
      current: {
        color: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.GrayDark
        ),
        ...borderMixin({
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayDark
          ),
          side: {
            all: 1
          },
          style: "solid"
        })
      },
      daySelected: {
        ...borderMixin({
          side: {
            all: 0
          }
        })
      }
    }
  };
};

export const Calendar: FC<CalendarProps> = ({
  className,
  value,
  onChange,
  maxDate,
  minDate,
  badgedDays,
  style = StyleVariant.Primary
}) => {
  const theme = useTheme<Theme>();
  const overrides = dateStyleOverrides(theme);
  const muiTheme = useMuiTheme(style, overrides);

  const renderDayWrapper = (
    dayComponent: JSX.Element,
    day?: DateTime,
    _selectedDate?: DateTime,
    _dayInCurrentMonth?: boolean
  ) => {
    const isBadged = some(badgedDays, bd => isSameDay(day, bd));
    return (
      <BadgedComponent
        position={BadgePosition.BottomRight}
        style={StyleVariant.Success}
        isVisible={isBadged}
      >
        {dayComponent}
      </BadgedComponent>
    );
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider locale={currentLocale} utils={LuxonUtils}>
        <DatePicker
          className={className}
          variant="static"
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            return renderDayWrapper(
              dayComponent,
              day ?? undefined,
              selectedDate ?? undefined,
              dayInCurrentMonth
            );
          }}
          onChange={newValue => onChange(newValue ?? undefined)}
          value={value ?? undefined}
          disableToolbar
          maxDate={maxDate}
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
