export const inputs = {
  resourceFormToolbar: {
    cancel: "cancelar"
  },
  jsonTab: {
    label: "Json"
  },
  jsonInput: {
    title: "Json"
  },
  referenceResourceInput: {
    noOptionsText: "Ingrese al menos 3 caracteres para buscar",
    createOption: "Crear nueva opcion"
  },
  enumSelectorInput: {
    createPrompt: "Ingrese el nuevo valor",
    createLabel: "Ingresar nuevo valor"
  },
  codeSystemSelectorInput: {
    createLabel: "Ingresar nuevo valor",
    noOptionsText: "Ingrese al menos 3 caracteres para buscar",
    createDialog: {
      title: "Nuevo concepto en system {{system}}",
      code: "Codigo",
      display: "Texto Alternativo",
      create: "Crear"
    }
  },
  conceptPropertyInput: {
    createLabel: "Ingresar nuevo valor",
    noOptionsText: "Ingrese al menos 3 caracteres para buscar"
  },
  latLngInput: {
    lat: "Latitud",
    lng: "Longitud",
    set: "Seleccionar centro"
  },
  referenceManyResourceInput: {
    add: "Añadir",
    edit: "Editar"
  },
  reportConfigInput: {
    reportConfigType: "Tipo de reportes",
    reportConfigProperties: "Propiedades de reporte",
    reportConfigPropertyType: "Tipo",
    reportConfigPropertyValue: "Valor"
  },
  emailConfigInput: {
    emailConfigCode: "Evento",
    emailConfigEmailId: "Id mail de envio",
    emailConfigEmail: "Mails",
    emailConfigEmailElement: "Mail",
    emailConfigSubjectTemplate: "Formato Titulo",
    emailConfigBodyTemplate: "Formato Cuerpo",
    emailConfigWaitDelay: "Delay de envio de mail (seg)"
  },
  phoneConfigInput: {
    phoneConfigCode: "Evento",
    phoneConfigPhoneId: "Id telefono de envio",
    phoneConfigBodyTemplate: "Formato mensaje",
    phoneConfigWaitDelay: "Delay de envio de mensaje (seg)"
  },
  configurationCodeSystemInput: {
    code: "Código",
    url: "url"
  },
  flowConfigInput: {
    flowConfigSelect: "Funcionalidades",
    flowConfigElement: "Funcionalidad"
  },
  activityDefinitionCommonInput: {
    id: "Id",
    name: "Nombre",
    type: "Tipo",
    organization: "Organización",
    status: "Estado",
    emailConfig: "Emails",
    phoneConfig: "Whatsapp",
    configurationCodeSystem: "Configuración del code system",
    templateList: "Listado de Formatos",
    templateListCode: "Codigo",
    templateListTemplate: "Formato",
    default: "Default",
    permissionConfig: "Configuración de permisos"
  },
  activityDefinitionCommonListItemInput: {
    id: "Id",
    name: "Nombre",
    organization: "Organizacion",
    status: "Estado"
  },
  uploadFileImageInput: {
    uploadLabel: "Subir archivo"
  },
  identifierInput: {
    identifier: "Identificadores",
    identifierValue: "Valor",
    identifierSystem: "System",
    property: "Propiedades",
    propertyType: "Tipo",
    propertyValueCode: "Valor Codigo",
    propertyValueNumber: "Valor Numero",
    propertyValueBool: "Valor Verdadero/Falso",
    propertyValueString: "Valor Texto"
  },
  identifierFilesInput: {
    title: "Archivos",
    attachmentName: "Nombre archivo",
    attachmentURL: "URL archivo"
  },
  permissionConfigInput: {
    creationRequiredAppConfig: "Permisos requeridos para la creación",
    creationExcludedAppconfig: "Permisos de exclusión para la creación",
    editionRequiredAppConfig: "Permisos requeridos para la edición",
    editionExcludedAppconfig: "Permisos de exclusión para la edición",
    specialityRequiredPermission: "Permisos requeridos para las especialidades",
    specialityExcludedPermission:
      "Permisos de exclusión para las especialidades",
    appConfigPermission: "Código"
  }
};
