import React, { useState } from "react";
import { FC, useI18n } from "@laba/react-common";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title, useNotify, useRedirect } from "react-admin";
import { Grid, Typography } from "@mui/material";
import { UploadFileButton } from "components/generic/UploadFileButton/UploadFileButton";
import { SpinnerDialog, UploadArrow } from "@laba/nexup-components";
import {
  importDosubaDirectoryPharmaXlsConfiguration,
  importDosubaDirectoryXlsConfiguration,
  importDosubaPatientXlsConfiguration,
  importOrganizationConfigurationFiles
} from "store/organization/event";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { logger } from "@laba/ts-common";
import { ResourceType } from "@laba/nexup-api";
import { AdminPanelSpecialPermissions } from "models/permissions/adminPanelPermissions";
import { useHasPermission } from "components/hook/UseHasPermission";
import { SantaCatalinaPayrollInput } from "components/pages/Dashboard/SantaCatalinaPayrollInput/SantaCatalinaPayrollInput";
import { PatientImportInput } from "components/pages/Dashboard/PatientImport/PatientImportInput";
import { VademecumImportInput } from "components/pages/Dashboard/VademecumImport/VademecumImportInput";
import { PractitionerImportInput } from "components/pages/Dashboard/PractitionerImport/PractitionerImportInput";
import { workspaceOrganizationSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { MaintenancePanelInput } from "components/pages/Dashboard/MaintenancePanel/MaintenancePanel";
import { RolUpdateInput } from "components/pages/Dashboard/RolUpdateInput/RolUpdateInput";
import { QuestionnaireResponseImportInput } from "components/pages/Dashboard/QuestionnaireResponseImport/QuestionnaireResponseImportInput";
import { AlfabetaProductSyncInput } from "components/pages/Dashboard/AlfabetaProductSync/AlfabetaProductSyncInput";
import { AlfabetaMedicationSyncInput } from "components/pages/Dashboard/AlfabetaMedicationSync/AlfabetaMedicationSyncInput";
import { AlfabetaContractGenerationInput } from "components/pages/Dashboard/AlfabetaContractGeneration/AlfabetaContractGenerationInput";
import { WhatsappSessionList } from "components/pages/Dashboard/WhatsappSessionList/WhatsappSessionList";
import { CreatePrescriptionDocument } from "components/pages/Dashboard/CreatePrescriptionDocument/CreatePrescriptionDocument";

export const tk = tkCP.adminPage.dashboard;
export const Dashboard: FC = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const hasDosubaPermission = useHasPermission([
    AdminPanelSpecialPermissions.Dosuba,
    AdminPanelSpecialPermissions.AllDashboard
  ]);
  const hasPayrollPermission = useHasPermission([
    AdminPanelSpecialPermissions.AllDashboard
  ]);
  const hasImportCompleteOrganizationPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );
  const hasImportPatientPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );
  const hasImportPractitionerPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );
  const hasImportVademecumPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );
  const hasMaintenancePanelPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );
  const hasMigrateRolPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );

  const hasWhatsappSessionPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );

  const hasCreatePrescriptionDocumentPermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );

  const hasImportQuestionnaireResponsePermission = useHasPermission(
    AdminPanelSpecialPermissions.AllDashboard
  );

  const hasAlfabetaProductSyncPermission = useHasPermission([
    AdminPanelSpecialPermissions.AlfabetaProduct,
    AdminPanelSpecialPermissions.AllDashboard
  ]);

  const hasAlfabetaMedicationSyncPermission = useHasPermission([
    AdminPanelSpecialPermissions.AlfabetaMedication,
    AdminPanelSpecialPermissions.AllDashboard
  ]);

  const hasAlfabetaContractGenerationPermission = useHasPermission([
    AdminPanelSpecialPermissions.AlfabetaContract,
    AdminPanelSpecialPermissions.AllDashboard
  ]);

  const workspaceName = useSelector(workspaceOrganizationSelector)?.name;
  return (
    <Card>
      <Title title={t(tk.title, { workspace: workspaceName })} />
      <CardContent>
        <Typography title={t(tk.title, { workspace: workspaceName })}>
          {t(tk.title, { workspace: workspaceName })}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {hasImportCompleteOrganizationPermission && (
            <Grid item sm={12} alignSelf="center">
              <UploadFileButton
                fileType=".json"
                label={t(tk.importCompleteOrganization)}
                Icon={UploadArrow}
                onFileAdded={async e => {
                  setLoading(true);
                  const result = await dispatch(
                    importOrganizationConfigurationFiles(
                      e.target.files ?? undefined,
                      error => logger.error(error)
                    )
                  );
                  setLoading(false);
                  if (result) {
                    redirect(
                      "edit",
                      ResourceType.Organization,
                      result.organization?.id
                    );
                  } else {
                    // eslint-disable-next-line no-alert
                    alert(t(tk.organizationConfigError));
                  }
                }}
              />
            </Grid>
          )}
          {hasDosubaPermission && (
            <Grid item sm={12} alignSelf="center">
              <UploadFileButton
                label={t(tk.importDosubaDirectory)}
                Icon={UploadArrow}
                onFileAdded={async e => {
                  setLoading(true);
                  await dispatch(
                    importDosubaDirectoryXlsConfiguration(
                      e.target.files ?? undefined
                    )
                  );
                  setLoading(false);
                }}
                fileType=".xls,.xlsx"
              />
            </Grid>
          )}
          {hasDosubaPermission && (
            <Grid item sm={12} alignSelf="center">
              <UploadFileButton
                label={t(tk.importDosubaDirectoryPharma)}
                Icon={UploadArrow}
                onFileAdded={async e => {
                  setLoading(true);
                  await dispatch(
                    importDosubaDirectoryPharmaXlsConfiguration(
                      e.target.files ?? undefined
                    )
                  );
                  setLoading(false);
                }}
                fileType=".xls,.xlsx"
              />
            </Grid>
          )}
          {hasDosubaPermission && (
            <Grid item sm={12} alignSelf="center">
              <UploadFileButton
                label={t(tk.importDosubaPatient)}
                Icon={UploadArrow}
                onFileAdded={async e => {
                  setLoading(true);
                  await dispatch(
                    importDosubaPatientXlsConfiguration(
                      e.target.files ?? undefined
                    )
                  );
                  setLoading(false);
                }}
                fileType=".xls,.xlsx"
              />
            </Grid>
          )}
          {hasPayrollPermission && (
            <Grid item sm={12} alignSelf="center">
              <SantaCatalinaPayrollInput
                onImportFinished={success => {
                  if (!success)
                    notify(t(tk.santaCatalinaPayroll.errorImporting), {
                      type: "error"
                    });
                }}
              />
            </Grid>
          )}
          {hasImportPatientPermission && (
            <Grid item sm={12} alignSelf="center">
              <PatientImportInput
                onImportFinished={success => {
                  if (!success)
                    notify(t(tk.importPatient.errorImporting), {
                      type: "error"
                    });
                }}
              />
            </Grid>
          )}
          {hasImportVademecumPermission && (
            <Grid item sm={12} alignSelf="center">
              <VademecumImportInput
                onImportFinished={success => {
                  if (!success)
                    notify(t(tk.importVademecum.errorImporting), {
                      type: "error"
                    });
                }}
              />
            </Grid>
          )}
          {hasImportPractitionerPermission && (
            <Grid item sm={12} alignSelf="center">
              <PractitionerImportInput
                onImportFinished={success => {
                  if (!success)
                    notify(t(tk.importPatient.errorImporting), {
                      type: "error"
                    });
                }}
              />
            </Grid>
          )}
          {hasMaintenancePanelPermission && (
            <Grid item sm={12} alignSelf="center">
              <MaintenancePanelInput />
            </Grid>
          )}
          {hasMigrateRolPermission && (
            <Grid item sm={12} alignSelf="center">
              <RolUpdateInput />
            </Grid>
          )}
          {hasWhatsappSessionPermission && (
            <Grid item sm={12} alignSelf="center">
              <WhatsappSessionList />
            </Grid>
          )}
          {hasCreatePrescriptionDocumentPermission && (
            <Grid item sm={12} alignSelf="center">
              <CreatePrescriptionDocument />
            </Grid>
          )}
          {hasImportQuestionnaireResponsePermission && (
            <Grid item sm={12} alignSelf="center">
              <QuestionnaireResponseImportInput />
            </Grid>
          )}
          {hasAlfabetaProductSyncPermission && (
            <Grid item sm={12} alignSelf="center">
              <AlfabetaProductSyncInput />
            </Grid>
          )}
          {hasAlfabetaMedicationSyncPermission && (
            <Grid item sm={12} alignSelf="center">
              <AlfabetaMedicationSyncInput />
            </Grid>
          )}
          {hasAlfabetaContractGenerationPermission && (
            <Grid item sm={12} alignSelf="center">
              <AlfabetaContractGenerationInput />
            </Grid>
          )}
        </Grid>
        <SpinnerDialog open={loading} />
      </CardContent>
    </Card>
  );
};
