import { isArray, Optional } from "@laba/ts-common";
import { Day, Hour } from "model/primitives/time/time";
import { MedicalRequestWithExtraData } from "model/resource/prescription/medicalRequest/medicalRequestWithExtraData";
import {
  MedicalRequestStatus,
  MedicationRequestStatus
} from "model/resource/prescription/medicationRequest/medicationRequest";
import {
  MedicalRequest,
  MedicalRequestExtraData,
  MedicationStructureElement
} from "./medicalRequest";
import {
  MedicalRequestDefinitionField,
  MRDFieldPropertyType
} from "../medicalRequestDefinition/medicalRequestDefinitionField";
import { getFieldProperty } from "../medicalRequestDefinition/utils";

export const getExtraDataEntryIdx = (
  extraData: MedicalRequestExtraData[],
  requestType: string
): number => {
  return extraData.findIndex(data => data.type === requestType);
};

export const getExtraDataFieldEntry = (
  field: MedicalRequestDefinitionField,
  medicalRequest: MedicalRequest
): Optional<MedicalRequestExtraData> => {
  const extraData = medicalRequest.requestData?.extraData;
  const requestType = getFieldProperty(
    field,
    MRDFieldPropertyType.RequestType
  )?.value;

  return extraData?.find(data => data.type === requestType);
};
export const createEmptyMedicationStructure =
  (): MedicationStructureElement => ({
    additives: []
  });

export const activateMedicalRequest = (
  medicalRequest: MedicalRequest
): MedicalRequest => ({
  ...medicalRequest,
  statusReason: MedicalRequestStatus.Active
});

export const defaultHourOrder = (hour1: Hour, hour2: Hour): number => {
  const hour1Idx = Object.values(Hour).indexOf(hour1);
  const hour2Idx = Object.values(Hour).indexOf(hour2);

  if (hour1Idx < hour2Idx) return -1;
  if (hour1Idx === hour2Idx) return 0;
  return 1;
};

export const defaultDayOrder = (day1: Day, day2: Day): number => {
  const day1Idx = Object.values(Day).indexOf(day1);
  const day2Idx = Object.values(Day).indexOf(day2);

  if (day1Idx < day2Idx) return -1;
  if (day1Idx === day2Idx) return 0;
  return 1;
};

export const activateMedicalRequestWithExtraData = (
  medicalRequestWithExtraData: MedicalRequestWithExtraData
): MedicalRequestWithExtraData => ({
  ...medicalRequestWithExtraData,
  medicalRequest: {
    ...medicalRequestWithExtraData.medicalRequest,
    status: MedicationRequestStatus.Active
  }
});

export type MedReqModificationChecker = (
  mr?: MedicalRequestWithExtraData
) => boolean;

export const medicalRequestHasStatus = (
  status: MedicalRequestStatus | MedicalRequestStatus[],
  mr?: MedicalRequestWithExtraData
): boolean => {
  if (!mr) return false;
  if (isArray(status)) {
    return status.includes(mr.medicalRequest.statusReason);
  }
  return status === mr.medicalRequest.statusReason;
};

export const medReqWithExtraDataHasEditableStatus: MedReqModificationChecker =
  mr =>
    medicalRequestHasStatus(
      [
        MedicalRequestStatus.Active,
        MedicalRequestStatus.Expired,
        MedicalRequestStatus.ToExpire
      ],
      mr
    );

export const medReqWithExtraDataHasRestorableStatus: MedReqModificationChecker =
  mr => medicalRequestHasStatus([MedicalRequestStatus.DischargeCancelled], mr);

export const medReqWithExtraDataHasCancellableStatus: MedReqModificationChecker =
  mr =>
    medicalRequestHasStatus(
      [
        MedicalRequestStatus.Active,
        MedicalRequestStatus.ToExpire,
        MedicalRequestStatus.Expired,
        MedicalRequestStatus.DischargeCancelled
      ],
      mr
    );

export const medReqWithExtraDataHasRenewableStatus: MedReqModificationChecker =
  mr =>
    medicalRequestHasStatus(
      [
        MedicalRequestStatus.Active,
        MedicalRequestStatus.Expired,
        MedicalRequestStatus.ToExpire
      ],
      mr
    );

export const medReqWithExtraDataHasPrescribableStatus: MedReqModificationChecker =
  mr =>
    medicalRequestHasStatus(
      [MedicalRequestStatus.Active, MedicalRequestStatus.ToExpire],
      mr
    );
