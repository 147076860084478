import { Color, getClassName, IconC, ReactElement } from "@laba/react-common";
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from "material-ui-popup-state/hooks";
import {
  HorizontalPosition,
  Menu,
  MenuItemConfig,
  VerticalPosition
} from "components/menu/Menu/Menu";
import React from "react";
import { Noop } from "@laba/ts-common";
import { ProfilePicture } from "components/profile/ProfilePicture/ProfilePicture";
import { SizeVariant, StyleVariant } from "model/themeVariant";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import {
  BadgedComponent,
  BadgePosition,
  BadgeVariant
} from "components/containers/BadgedComponent/BadgedComponent";
import { isNil } from "lodash-es";
import { NexupColor } from "model/nexupColor";
import { useUserMenuButtonStyle } from "./UserMenuButtonStyle";
import { UserMenuData } from "./UserMenuData/UserMenuData";

export interface UserMenuButtonConfig {
  name?: string;
  surname?: string;
  photoSource?: string;
}

export interface UserMenuButtonProps<V> {
  style: StyleVariant;
  user: UserMenuButtonConfig;
  userExtraData?: string;
  isMobile?: boolean;
  showDataOnDropdown?: boolean;
  onUserMenuOptionSelected?: (itemId: V) => void;
  userOptions?: MenuItemConfig<V>[];
  profilePictureClassName?: string;
  onUserMenuDataClick?: Noop;
  withUserDataRightIcon?: boolean;
  userDataRightIcon?: IconC;
  userPendingActions?: number;
  pendingActionsBadgeColor?: Color;
  className?: string;
}

const getFullName = (
  user?: UserMenuButtonConfig,
  upperCase = false
): string => {
  const fullName = [user?.name, user?.surname].filter(Boolean).join(" ");
  return upperCase ? fullName.toUpperCase() : fullName;
};

export const UserMenuButton = <V,>({
  isMobile,
  user,
  userExtraData,
  onUserMenuOptionSelected,
  userOptions = [],
  style = StyleVariant.Primary,
  showDataOnDropdown,
  profilePictureClassName,
  userDataRightIcon,
  withUserDataRightIcon,
  userPendingActions,
  pendingActionsBadgeColor = NexupColor.DarkFuchsia,
  className
}: UserMenuButtonProps<V>): ReactElement => {
  const isClickable = userOptions.length > 0;
  const popupState = usePopupState({
    variant: "popover",
    popupId: "userPopover"
  });

  const showProfileDataOnDropdown = showDataOnDropdown || isMobile;
  const classes = useUserMenuButtonStyle({ style, showProfileDataOnDropdown });
  const hasNameWithUpperCase = !showProfileDataOnDropdown;
  const userFullName = getFullName(user, hasNameWithUpperCase);

  return (
    <>
      <ButtonBase
        disabled={!isClickable}
        {...bindTrigger(popupState)}
        className={getClassName(classes.userContainer, className)}
        title={userFullName}
      >
        <BadgedComponent
          position={BadgePosition.TopRight}
          isVisible={!isNil(userPendingActions) && userPendingActions > 0}
          variant={BadgeVariant.Standard}
          badgeContent={userPendingActions}
          isClickable={false}
          color={pendingActionsBadgeColor}
        >
          <ProfilePicture
            className={getClassName(
              profilePictureClassName,
              classes.profilePicture
            )}
            size={SizeVariant.Medium}
            firstName={user.name}
            lastName={user.surname}
            imageSource={user.photoSource}
          />
        </BadgedComponent>

        <UserMenuData
          userFullName={userFullName}
          userExtraData={userExtraData}
          hide={showProfileDataOnDropdown}
          showProfileDataOnDropdown={showProfileDataOnDropdown}
          styleVariant={style}
        />
      </ButtonBase>
      <Menu
        anchorOriginVertical={VerticalPosition.Bottom}
        transformOriginVertical={VerticalPosition.Top}
        anchorOriginHorizontal={HorizontalPosition.Right}
        transformOriginHorizontal={HorizontalPosition.Right}
        onOptionSelected={onUserMenuOptionSelected}
        menuItems={userOptions}
        {...bindPopover(popupState)}
        popoverPapperClassName={classes.menu}
      >
        <UserMenuData
          userFullName={userFullName}
          userExtraData={userExtraData}
          hide={!showProfileDataOnDropdown}
          showProfileDataOnDropdown={showProfileDataOnDropdown}
          styleVariant={style}
          withRightIcon={withUserDataRightIcon}
          RightIcon={userDataRightIcon}
        />
      </Menu>
    </>
  );
};
