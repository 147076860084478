import { toNumber, compact, groupBy, maxBy, values } from "lodash-es";
import { isSomeEnum, notUndefined, Optional } from "@laba/ts-common";
import {
  KnownMedicalRequestDefinitionCategory,
  MedicalRequestDefinition,
  MedicalRequestDefinitionCategory,
  MedicalRequestDefinitionNonPharmacologyCategory,
  MedicalRequestDefinitionPharmacologyCategory
} from "model/resource/prescription/medicalRequestDefinition/medicalRequestDefinition";
import { MedicalAppConfigPermissionCode } from "model/resource/app/appConfig";
import {
  filterCreationByDefinitionPermissionConfig,
  validateActivityDefinitionEditPermission
} from "model/resource/activity/utils";
import { ResourceType } from "model/primitives/resourceModel";
import { ActivityDefinitionKind } from "model/resource/activity/activityDefinition";
import {
  MedicalRequestDefinitionField,
  MedicalRequestDefinitionFieldType,
  MRDFieldBase,
  MRDFieldPropertyBase,
  MRDFieldPropertyType
} from "./medicalRequestDefinitionField";

// The type of one element of the array properties
type MRDFieldProperty<
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
> = MRDFieldBase<T, P>["properties"][number];

export const getFieldProperty = <
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
>(
  field: MRDFieldBase<T, P>,
  propertyName: P
): MRDFieldPropertyBase<P> | undefined => {
  return field.properties.find(prop => prop.type === propertyName);
};

const valueIsBoolean = (v?: string): boolean =>
  v !== undefined && v !== "false";

export const getFieldPropertyValueNumber = <
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
>(
  field: MRDFieldBase<T, P>,
  propertyName: P
): Optional<number> => {
  const valueFromProperty = field.properties.find(
    prop => prop.type === propertyName
  );
  const value = valueFromProperty?.value ?? valueFromProperty?.text;
  return value ? toNumber(value) : undefined;
};

export const getFieldPropertyValueBoolean = <
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
>(
  field: MRDFieldBase<T, P>,
  propertyName: P
): boolean => {
  const property = getFieldProperty(field, propertyName);
  const requiredValue = property?.value ?? property?.text;
  return valueIsBoolean(requiredValue);
};

export const getFieldPropertyArray = <
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
>(
  field: MRDFieldBase<T, P>,
  propertyName: P
): MRDFieldPropertyBase<P>[] => {
  return field.properties.filter(prop => prop.type === propertyName);
};

export const propertyHasTypeAndValue =
  <T extends MedicalRequestDefinitionFieldType, P extends string = string>(
    type: MRDFieldPropertyType
  ) =>
  (
    property: MRDFieldProperty<T, P>
  ): property is { type: P; value: string; text?: string } => {
    return property.type === type && property.value !== undefined;
  };

export const propertyHasTypeAndText =
  <T extends MedicalRequestDefinitionFieldType, P extends string = string>(
    type: MRDFieldPropertyType
  ) =>
  (
    property: MRDFieldProperty<T, P>
  ): property is { type: P; value?: string; text: string } => {
    return property.type === type && property.text !== undefined;
  };

export const getFieldPropertyArrayValue = <
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
>(
  field: MRDFieldBase<T, P>,
  propertyName: P
): string[] => {
  return compact(
    getFieldPropertyArray(field, propertyName).map(({ value }) => value)
  );
};

export const getFieldPropertyArrayValueNumber = <
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
>(
  field: MRDFieldBase<T, P>,
  propertyName: P
): number[] => {
  const arrayProperty = getFieldPropertyArray(field, propertyName);
  return arrayProperty
    .map(({ value, text }) => value ?? text)
    .filter(notUndefined)
    .map(v => toNumber(v));
};

export const getFieldPropertyArrayValueBoolean = <
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
>(
  field: MRDFieldBase<T, P>,
  propertyName: P
): boolean[] => {
  const arrayProperty = getFieldPropertyArray(field, propertyName);
  return arrayProperty
    .map(({ value, text }) => value ?? text)
    .map(valueIsBoolean);
};

export const filterFieldPropertyArrayValuesByType = <T>(
  array: string[],
  e: T
): T[keyof T][] => {
  const arrayFilter = array.filter(value => isSomeEnum(e)(value));
  return arrayFilter as unknown as T[keyof T][];
};

export const propertyHasTypeTextAndValue =
  <T extends MedicalRequestDefinitionFieldType, P extends string = string>(
    type: MRDFieldPropertyType
  ) =>
  (
    property: MRDFieldProperty<T, P>
  ): property is { type: P; value: string; text: string } => {
    return (
      property.type === type &&
      property.value !== undefined &&
      property.text !== undefined
    );
  };
export const isPharmacological = (
  category: MedicalRequestDefinitionCategory
): category is MedicalRequestDefinitionPharmacologyCategory =>
  isSomeEnum(MedicalRequestDefinitionPharmacologyCategory)(category);
export const isNonPharmacological = (
  category: MedicalRequestDefinitionCategory
): category is MedicalRequestDefinitionNonPharmacologyCategory =>
  isSomeEnum(MedicalRequestDefinitionNonPharmacologyCategory)(category);
export const isKnownCategory = (
  category: MedicalRequestDefinitionCategory
): category is KnownMedicalRequestDefinitionCategory =>
  isPharmacological(category) || isNonPharmacological(category);
export const getRequiredFieldsList = (
  medicalRequestDefinition: MedicalRequestDefinition
): MedicalRequestDefinitionField[] => {
  return medicalRequestDefinition.fields.filter(
    field =>
      getFieldProperty(field, MRDFieldPropertyType.Required) !== undefined
  );
};

export const filterMRCreationByDefinitionPermissionConfig = (
  definitions: MedicalRequestDefinition[],
  permissionList?: MedicalAppConfigPermissionCode[]
): MedicalRequestDefinition[] => {
  return filterCreationByDefinitionPermissionConfig<
    ResourceType.MedicalRequestDefinition,
    ActivityDefinitionKind.MedicalRequest
  >(definitions, permissionList) as MedicalRequestDefinition[];
};

export const validateMRDefinitionEditPermission = (
  definition?: MedicalRequestDefinition,
  availablePermissions?: MedicalAppConfigPermissionCode[]
): boolean => {
  return validateActivityDefinitionEditPermission(
    definition,
    availablePermissions
  );
};

export const filterMRDefinitionsByPriority = (
  definitionList: MedicalRequestDefinition[]
): MedicalRequestDefinition[] => {
  const groupedDefinitions = groupBy(definitionList, def => def.type);
  return values(groupedDefinitions)
    .map(group => maxBy(group, gr => gr.order ?? 0))
    .filter(notUndefined);
};
