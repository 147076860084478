import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  getZIndexValue,
  marginMixin,
  paddingMixin,
  pxToRem,
  ZIndexVariant
} from "@laba/react-common";
import {
  getThemeCardRadius,
  getThemeStyleColor,
  themeElevationMixin
} from "model/themeUtils";
import { ElevationVariant, StyleVariant } from "model/themeVariant";
import { ClassNameDynamicGeneratedRecord } from "components/utils/getGenericButtonColors";
import { CardVariant } from "../cardVariants";

export interface CardStyleProps {
  style: StyleVariant;
  cardVariant: CardVariant;
  fullWidth: boolean;
  withStickyHeader?: boolean;
  top?: number;
  showBottomButtons?: boolean;
}

interface CardClassNames {
  root?: string;
  mainContent?: string;
  secondaryButton?: string;
  horizontalButtonsContainer?: string;
  verticalButtonsContainer?: string;
  loadingScreen?: string;
  buttonAndDividerContainer?: string;
  headerWrapper?: string;
}

const generatedStyleKeyTemplate = (
  style: StyleVariant,
  cardVariant: CardVariant,
  fullWidth: boolean
) =>
  `generatedStyle${style}${cardVariant}${
    fullWidth ? "fullWidth" : "NotfullWidth"
  }`;

const generateDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(StyleVariant).forEach(styleVariant => {
    Object.values(CardVariant).forEach(cardVariant => {
      Object.values([true, false]).forEach(fullWidth => {
        classNameObject[
          generatedStyleKeyTemplate(styleVariant, cardVariant, fullWidth)
        ] = {
          backgroundColor: getThemeStyleColor(
            theme,
            styleVariant,
            ThemeStyleColorVariant.Contrast
          ),
          ...(!fullWidth && {
            maxWidth:
              cardVariant === CardVariant.Horizontal
                ? pxToRem(780)
                : pxToRem(312)
          })
        };
      });
    });
  });
  return classNameObject;
};

const useCardBaseStyle = createStyle(
  (theme: Theme) => ({
    ...generateDynamicStylesClassNames(theme)
  }),
  "CardBase"
);

const useCardBaseStaticStyle = createStyle(
  (theme: Theme) => ({
    rootStatic: {
      ...flexColumnMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...borderMixinRadiusAll(getThemeCardRadius(theme)),
      ...themeElevationMixin(theme, ElevationVariant.Elevation24)
    },
    mainContentStatic: {
      width: "100%",
      position: "relative"
    },
    mainContentVertical: {
      ...paddingMixin({
        leftRight: 24
      })
    },
    mainContentHorizontal: {
      ...paddingMixin({
        leftRight: 56
      })
    },
    mainContentBottomPadding: {
      ...paddingMixin({ bottom: 32 })
    },
    secondaryButtonVertical: {
      ...flexItemMixin({
        order: 0
      })
    },
    secondaryButtonHorizontal: {
      ...flexItemMixin({
        order: -1
      })
    },
    horizontalButtonsContainer: {
      ...flexRowMixin({ gap: 16 }),
      ...marginMixin({
        left: "auto",
        right: 32,
        topBottom: 32
      })
    },
    verticalButtonsContainer: {
      ...flexColumnMixin({ gap: 16 }),
      ...paddingMixin({
        leftRight: 24,
        bottom: 24
      }),
      width: "100%"
    },
    buttonTopPadding: {
      ...paddingMixin({
        top: 24
      })
    },
    buttonAndDividerContainer: {
      ...flexColumnMixin({ gap: 24 }),
      width: "100%"
    },
    loadingScreen: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.Center
      }),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      ),
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100%",
      // TO-DO: HIS-14122-replace-forms-with-FormCardWithHeader ver de cambiar este valor cuando se haga el reemplazo con FormWithCardHeader
      zIndex: ZIndexVariant.ListItemElevation
    },
    headerWrapper: {
      width: "100%"
    }
  }),
  "CardBaseStatic"
);

const useCardBaseDynamicStyles = createStyle(
  (theme: Theme) => ({
    stickyHeader: (props: CardStyleProps) => ({
      position: "sticky",
      top: props.top,
      zIndex: getZIndexValue(ZIndexVariant.HeaderElevation),
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    })
  }),
  "CardBaseDynamic"
);

export const useCardStyleClasses = (props: CardStyleProps): CardClassNames => {
  const classes = useCardBaseStyle();
  const staticClasses = useCardBaseStaticStyle();
  const dynamicClasses = useCardBaseDynamicStyles(props);

  return {
    root: getClassName(
      classes[
        generatedStyleKeyTemplate(
          props.style,
          props.cardVariant,
          props.fullWidth
        )
      ],
      staticClasses.rootStatic
    ),
    mainContent: getClassName(
      staticClasses.mainContentStatic,
      props.cardVariant === CardVariant.Horizontal
        ? staticClasses.mainContentHorizontal
        : staticClasses.mainContentVertical,
      props.showBottomButtons
        ? undefined
        : staticClasses.mainContentBottomPadding
    ),
    secondaryButton:
      props.cardVariant === CardVariant.Horizontal
        ? staticClasses.secondaryButtonHorizontal
        : staticClasses.secondaryButtonVertical,
    horizontalButtonsContainer: staticClasses.horizontalButtonsContainer,
    verticalButtonsContainer: staticClasses.verticalButtonsContainer,
    loadingScreen: staticClasses.loadingScreen,
    buttonAndDividerContainer: getClassName(
      staticClasses.buttonAndDividerContainer,
      props.cardVariant === CardVariant.Vertical
        ? staticClasses.buttonTopPadding
        : undefined
    ),
    headerWrapper: getClassName(
      staticClasses.headerWrapper,
      props.withStickyHeader ? dynamicClasses.stickyHeader : undefined
    )
  };
};
