import { Optional } from "@laba/ts-common";
import { ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Identifier, KnownIdentifierSystem } from "model/primitives/identifier";
import { Code } from "model/resource/entities/codeSystem";
import {
  Attachment,
  getIdentifierFirstNotEmptyFile,
  getModelReferenceId
} from "model/primitives";
import {
  Organization,
  OrganizationAvailability,
  OrganizationHospitalizationType,
  OrganizationType
} from "model/resource/entities/organization";
import { isEqual } from "lodash-es";

const PrivatePayer = "Paciente Particular";
const PrivatePayerId = "Private";

const getOrganizationIdentifierBySystem = (
  identifierSystem: KnownIdentifierSystem,
  organization?: Organization
): Optional<Identifier> =>
  organization?.identifier?.find(id => isEqual(id.system, identifierSystem));

export const getOrganizationIdentifierValue = (
  identifierSystem: KnownIdentifierSystem,
  organization?: Organization
): Optional<Code> =>
  getOrganizationIdentifierBySystem(identifierSystem, organization)?.value;

export const getOrganizationIdentifierFirstNotEmptyFile = (
  identifierSystem: KnownIdentifierSystem,
  organization?: Organization
): Optional<Attachment> => {
  const identifier = getOrganizationIdentifierBySystem(
    identifierSystem,
    organization
  );
  return getIdentifierFirstNotEmptyFile(identifier);
};

export const getOrganizationPayerCode = (
  organization?: Organization
): Optional<Code> =>
  getOrganizationIdentifierValue(
    KnownIdentifierSystem.OrganizationPayerCode,
    organization
  );

export const createBaseOrganizationAvailability =
  (): OrganizationAvailability => ({ nationalHolidaysDisabled: false });

export const createPrivatePayer = (): Organization => ({
  name: PrivatePayer,
  id: PrivatePayerId,
  resourceType: ResourceType.Organization,
  type: OrganizationType.Payer
});

export const isOrganizationPrivatePayer = (
  organization: ModelReference<Organization>
): boolean => {
  const organizationId = getModelReferenceId(organization);
  return organizationId === PrivatePayerId;
};

export const getOrganizationName = (
  organization?: Organization
): Optional<string> => organization?.name;

export const getOrganizationHospitalizationType = (
  organization?: Organization
): Optional<OrganizationHospitalizationType> =>
  organization?.hospitalizationType;
