import React, { FC } from "react";
import { NexupMarkdown } from "components/text/NexupMarkdown/NexupMarkdown";
import { useTitleContentDataHorizontalItemStyle } from "./useTitleContentDataHorizontalItemStyle";

export interface TitleContentDataHorizontalItemProps {
  principalText?: string;
  secondaryText?: string;
  isMarkdown?: boolean;
}

export const TitleContentDataHorizontalItem: FC<
  TitleContentDataHorizontalItemProps
> = ({ principalText, secondaryText, isMarkdown = false }) => {
  const classes = useTitleContentDataHorizontalItemStyle();
  return (
    <p className={classes.root}>
      {isMarkdown ? (
        <NexupMarkdown
          className={classes.principalTextItemList}
          text={principalText}
        />
      ) : (
        <span className={classes.principalTextItemList}>{principalText}</span>
      )}{" "}
      {isMarkdown ? (
        <NexupMarkdown
          className={classes.secondaryTextItemList}
          text={secondaryText}
        />
      ) : (
        <span className={classes.secondaryTextItemList}>{secondaryText}</span>
      )}
    </p>
  );
};
