import React, { useEffect, useState } from "react";
import { FC, TypeVariant } from "@laba/react-common";
import { DefaultButton } from "components/buttons/DefaultButton/DefaultButton";
import { ShareIcon } from "components/icons";
import { OnClickUrlAction, UrlLinkButton } from "components/link";

interface ShareButtonProps {
  onClick: (hideSuccessMsg?: boolean) => Promise<string>;
  snackbarText?: string;
  className?: string;
  getTextToShare: (link: string) => string;
  shareButtonText: string;
  copyLinkButtonText: string;
  disabled?: boolean;
  withNativeShare?: boolean;
}

export const ShareButton: FC<ShareButtonProps> = ({
  onClick,
  className,
  getTextToShare,
  shareButtonText,
  copyLinkButtonText,
  snackbarText,
  disabled,
  withNativeShare
}) => {
  const [isNativeShareAvailable, setIsNativeShareAvailable] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.share !== undefined) {
      setIsNativeShareAvailable(true);
    }
  }, []);

  return withNativeShare && isNativeShareAvailable ? (
    <DefaultButton
      className={className}
      onClick={async () => {
        const link = await onClick(true);
        const textToShare = getTextToShare(link);
        await navigator.share({ text: textToShare });
      }}
      StartIcon={ShareIcon}
      text={shareButtonText}
      type={TypeVariant.Outlined}
      disabled={disabled}
    />
  ) : (
    <UrlLinkButton
      className={className}
      onClickUrlAction={OnClickUrlAction.CopyToClipboard}
      buttonText={copyLinkButtonText}
      snackbarText={snackbarText}
      onClickGetClipboardLink={onClick}
      disabled={disabled}
    />
  );
};
