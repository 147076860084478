import {
  ApiPageRequestResponseBase,
  DefaultError,
  getCurrentDateTimestamp,
  ListQueryParams,
  ModelId,
  Optional,
  RequestFailureResponse,
  RequestFailureStatus
} from "@laba/ts-common";
import { AppAction, BaseEvent, ThunkAction, ThunkDispatch } from "store/types";
import { produce } from "immer";
import { isEmpty } from "lodash-es";
import { PaginationStateActions } from "./getPaginationState";
import { ReturnSelectors } from "./getPaginationSelectors";

export interface ReturnEvents<
  RootState,
  AppDispatch extends ThunkDispatch<RootState>
> {
  onGetFirstElementListPage: () => BaseEvent<void, RootState, AppDispatch>;
  onGetNextElementListPage: () => BaseEvent<void, RootState, AppDispatch>;
}

export const getPaginationEvents = <
  RootState,
  AppDispatch extends ThunkDispatch<RootState>,
  PaginationElement,
  QueryParams extends ListQueryParams,
  Error extends DefaultError
>(
  paginationSliceStateActions: PaginationStateActions<PaginationElement>,
  paginationSliceStateSelectors: ReturnSelectors<PaginationElement, RootState>,
  getElementListRequestQueryParams: (state: RootState) => QueryParams,
  onRequestError: (
    errorResponse: RequestFailureResponse<Error>,
    dispatch: AppDispatch
  ) => Promise<void>,
  getElementListRequest?: (
    queryParams: QueryParams
  ) => Promise<ApiPageRequestResponseBase<PaginationElement, Error>>,
  getElementListRequestWithIdPathParam?: (
    id: ModelId,
    queryParams: QueryParams
  ) => Promise<ApiPageRequestResponseBase<PaginationElement, Error>>,
  getElementListRequestIdPathParam?: (state: RootState) => Optional<ModelId>
): ReturnEvents<RootState, AppDispatch> => {
  const { elementRequestPageIdSelector, elementListNextPageNumberSelector } =
    paginationSliceStateSelectors;
  const onGetElementList =
    (isFirstPage?: boolean): BaseEvent<void, RootState, AppDispatch> =>
    async (dispatch, getState) => {
      if (isFirstPage) {
        dispatch(paginationSliceStateActions.initOnGetElementList());
      }
      const searchId = getCurrentDateTimestamp();
      dispatch(
        paginationSliceStateActions.initElementListDownload({ searchId })
      );

      const nextPageNumber = elementListNextPageNumberSelector(getState());
      const page = isFirstPage ? 1 : nextPageNumber;
      const queryParams = getElementListRequestQueryParams(getState());
      const fixedQueryParams = produce(queryParams, draftQueryParams => {
        draftQueryParams.page = page;
      });

      const someId = getElementListRequestIdPathParam?.(getState());

      const response =
        someId && !isEmpty(someId)
          ? await getElementListRequestWithIdPathParam?.(
              someId,
              fixedQueryParams
            )
          : await getElementListRequest?.(fixedQueryParams);

      if (response?.failureStatus === RequestFailureStatus.Failure) {
        dispatch(paginationSliceStateActions.cleanElementDownloadingData());
        return onRequestError(response, dispatch);
      }

      const latestId = elementRequestPageIdSelector(getState());
      if (latestId === searchId) {
        const responseData = response?.data;
        if (isFirstPage) {
          responseData &&
            dispatch(
              paginationSliceStateActions.setElementListResult({
                page: responseData
              })
            );
        } else {
          responseData &&
            dispatch(
              paginationSliceStateActions.addElementNextPageResult({
                page: responseData
              })
            );
        }
      }
    };
  const onGetFirstElementListPage =
    (): BaseEvent<void, RootState, AppDispatch> => async dispatch => {
      await dispatch(
        onGetElementList(true) as unknown as ThunkAction<
          Promise<void>,
          RootState,
          void,
          AppAction
        >
      );
    };
  const onGetNextElementListPage =
    (): BaseEvent<void, RootState, AppDispatch> => async dispatch => {
      await dispatch(
        onGetElementList(false) as unknown as ThunkAction<
          Promise<void>,
          RootState,
          void,
          AppAction
        >
      );
    };
  return {
    onGetFirstElementListPage,
    onGetNextElementListPage
  };
};
