import { FC, useI18n } from "@laba/react-common";
import React, { useMemo } from "react";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { useSelector } from "react-redux";
import {
  AvailableTimeFormDataParamsKey,
  Day,
  getModelReferenceId,
  KnownCodeSystemSystem,
  KnownScheduleDuration,
  KnownScheduleType,
  Location,
  ModelReference,
  Organization,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  PatientListQueryParamsKey,
  Practitioner,
  ResourceType,
  ScheduleDefinition,
  ScheduleFormDataParamsKey,
  ScheduleListDefinitionQueryParamsKey,
  ScheduleParamsKey
} from "@laba/nexup-api";
import { TextInput } from "components/generic/TextInput/TextInput";
import { tkCP } from "translation/i18n";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { DateTimeInput } from "components/generic/DateTimeInput/DateTimeInput";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import {
  locationOptionText,
  practitionerOptionText,
  scheduleDefinitionOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { IdentifierInput } from "components/generic/IdentifierInput/IdentifierInput";
import { TimeInput } from "components/generic/TimeInput/TimeInput";
import { isEmpty, isNumber, toNumber } from "lodash-es";
import {
  SelectInput,
  SelectInputChoice
} from "components/generic/SelectInput/SelectInput";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

export const tk = tkCP.adminPage[ResourceType.Schedule];

export const ScheduleMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    ScheduleFormDataParamsKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;

  const scheduleTypeSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ScheduleType,
    organizationId
  );

  const scheduleSpecialitySystem = useCodeSystemGroup(
    KnownCodeSystemSystem.ScheduleSpeciality,
    organizationId
  );

  const durationChoices: SelectInputChoice[] = useMemo(
    () =>
      Object.values(KnownScheduleDuration)
        .filter(isNumber)
        .map(minutes => ({
          name: minutes.toString(),
          id: minutes.toString()
        })),
    []
  );

  return (
    <>
      <TextInput
        disabled
        source={ScheduleParamsKey.id}
        label={t(tk.fields.id)}
      />
      <TextInput
        source={ScheduleParamsKey.name}
        label={t(tk.fields.name)}
        required
      />
      <ReferenceResourceInput<Location>
        source={ScheduleParamsKey.location}
        label={t(tk.fields.location)}
        resourceType={ResourceType.Location}
        optionTextKey={locationOptionText}
      />
      <ReferenceResourceInput<Practitioner>
        source={ScheduleParamsKey.practitioner}
        label={t(tk.fields.practitioner)}
        resourceType={ResourceType.Practitioner}
        optionTextKey={practitionerOptionText}
        extraFilters={{
          [PatientListQueryParamsKey.organization]: organizationId
        }}
      />
      {!isEmpty(scheduleTypeSystem?.concept ?? []) ? (
        <CodeSystemSelectorInput
          source={ScheduleParamsKey.type}
          label={t(tk.fields.type)}
          codeSystem={scheduleTypeSystem}
          organizationId={organizationId}
          withCreate
          required
        />
      ) : (
        <EnumSelectorInput
          source={ScheduleParamsKey.type}
          label={t(tk.fields.type)}
          enumValue={KnownScheduleType}
          required
        />
      )}
      <ArrayInput
        source={ScheduleParamsKey.speciality}
        label={t(tk.fields.speciality)}
      >
        <CodeSystemSelectorInput
          source=""
          label={t(tk.fields.speciality)}
          codeSystem={scheduleSpecialitySystem}
          organizationId={organizationId}
          withCreate
        />
      </ArrayInput>
      <ArrayInput
        source={ScheduleParamsKey.performer}
        label={t(tk.fields.performer)}
      >
        <ReferenceResourceInput<Practitioner>
          source={ScheduleParamsKey.practitioner}
          label={t(tk.fields.practitioner)}
          resourceType={ResourceType.Practitioner}
          optionTextKey={practitionerOptionText}
          extraFilters={{
            [PatientListQueryParamsKey.organization]: organizationId
          }}
        />
      </ArrayInput>
      <DateTimeInput
        source={ScheduleParamsKey.planningHorizon.start}
        label={t(tk.fields.planningHorizonStartDate)}
      />
      <DateTimeInput
        source={ScheduleParamsKey.planningHorizon.end}
        label={t(tk.fields.planningHorizonEndDate)}
      />
      <BooleanInput
        source={ScheduleParamsKey.active}
        label={t(tk.fields.active)}
      />
      <OrganizationReferenceInput
        source={ScheduleParamsKey.organization}
        label={t(tk.fields.organization)}
        type={OrganizationCombinedType.ProviderAndGroup}
        extraFilters={{
          [OrganizationListQueryParamsKey.active]: true
        }}
        required
        defaultValue={workspaceOrganizationId}
        disabled={workspaceOrganizationId !== undefined}
      />
      <SelectInput<number, number>
        choices={durationChoices}
        source={ScheduleParamsKey.availability.slotDuration}
        label={t(tk.fields.slotDuration)}
        parse={value => toNumber(value)}
        format={value => value?.toString()}
      />
      <ArrayInput
        source={ScheduleParamsKey.availability.availableTime}
        label={t(tk.fields.availableTime)}
      >
        <ArrayInput
          source={AvailableTimeFormDataParamsKey.daysOfWeek}
          label={t(tk.fields.daysOfWeek)}
        >
          <EnumSelectorInput
            source=""
            enumValue={Day}
            label={t(tk.fields.daysOfWeek)}
          />
        </ArrayInput>
        <BooleanInput
          source={AvailableTimeFormDataParamsKey.allDay}
          label={t(tk.fields.allDay)}
        />
        <TimeInput
          source={AvailableTimeFormDataParamsKey.startTime}
          label={t(tk.fields.availableTimeStartDate)}
        />
        <TimeInput
          source={AvailableTimeFormDataParamsKey.endTime}
          label={t(tk.fields.availableTimeStartDate)}
        />
      </ArrayInput>
      <ReferenceResourceInput<Practitioner>
        source={ScheduleParamsKey.practitioner}
        label={t(tk.fields.practitioner)}
        resourceType={ResourceType.Practitioner}
        optionTextKey={practitionerOptionText}
        extraFilters={{
          [PatientListQueryParamsKey.organization]: organizationId
        }}
      />
      <ReferenceResourceInput<Practitioner>
        source={ScheduleParamsKey.originalPractitioner}
        label={t(tk.fields.originalPractitioner)}
        resourceType={ResourceType.Practitioner}
        optionTextKey={practitionerOptionText}
        extraFilters={{
          [PatientListQueryParamsKey.organization]: organizationId
        }}
      />
      <IdentifierInput
        source={ScheduleParamsKey.identifier}
        organization={organizationId}
        system={KnownCodeSystemSystem.Identifier}
      />
      <ReferenceResourceInput<ScheduleDefinition>
        resourceType={ResourceType.AppointmentDefinition}
        label={ScheduleParamsKey.definition}
        source={ScheduleParamsKey.definition}
        optionTextKey={scheduleDefinitionOptionText}
        extraFilters={{
          [ScheduleListDefinitionQueryParamsKey.organization]:
            workspaceOrganizationId
        }}
      />
    </>
  );
};
