import { NexupRequestConfig } from "request/types";
import {
  baseRequest,
  ContentTypeHeader,
  DefaultError,
  getAuthorizationBearer,
  Header,
  HttpCode,
  isAxiosUnknownRequestError,
  ManageError,
  notUndefined,
  Optional,
  RequestConfig,
  RequestError,
  RequestResponse,
  sentryLogError,
  Validator
} from "@laba/ts-common";
import { omit, pickBy } from "lodash-es";
import { urlJoin } from "url-join-ts";

let nexupRequestConfig: Optional<NexupRequestConfig>;
let refreshTokenPromise: Promise<boolean> | undefined;

const timeoutMaxRetry = 1;
const timeoutRetryDelayMs = 100;

export const initNexupRequest = (config: NexupRequestConfig): void => {
  nexupRequestConfig = config;
};

const getOrCreateRefreshTokenPromise = async (): Promise<boolean> => {
  if (refreshTokenPromise === undefined) {
    const tokenRefreshEvent = async (): Promise<boolean> => {
      const tokenIsRefreshed = await nexupRequestConfig?.tryRefreshToken();
      refreshTokenPromise = undefined;
      return tokenIsRefreshed ?? false;
    };
    refreshTokenPromise = tokenRefreshEvent();
  }
  return refreshTokenPromise;
};

const getManageError =
  <E>(
    requestNeedCredentials: boolean,
    retryTimeout?: boolean
  ): ManageError<E> =>
  async (error: RequestError<E>, tryIndex: number) => {
    if (
      requestNeedCredentials &&
      error.response != null &&
      error.response.status === HttpCode.Unauthorized &&
      tryIndex < 100
    ) {
      const tokenRefreshed: boolean = await getOrCreateRefreshTokenPromise();
      return { error, retryRequest: tokenRefreshed, retryRequestDelayMs: 0 };
    }
    if (
      retryTimeout &&
      isAxiosUnknownRequestError(error) &&
      tryIndex < timeoutMaxRetry
    ) {
      // TODO HIS-13828 Borrar este log, solo deberiamos loggear si fallaron todos los retries
      sentryLogError({ error });
      return {
        error,
        retryRequest: true,
        retryRequestDelayMs: timeoutRetryDelayMs
      };
    }
    return { error, retryRequest: false, retryRequestDelayMs: 0 };
  };

const getTimeOutConfig = (options: RequestConfig) => {
  // TODO HIS-13828 Esta condición la puse así para usar el long timeout por defecto, para ir cambiando los pedidos de a poco
  const timeout =
    options.useLongTimeout !== false
      ? nexupRequestConfig?.longTimeout
      : nexupRequestConfig?.timeout;
  return (
    timeout ?? nexupRequestConfig?.longTimeout ?? nexupRequestConfig?.timeout
  );
};
export const request = async <R, E = DefaultError>(
  options: RequestConfig,
  responseValidator?: Validator<R>,
  errorValidator?: Validator<E>
): Promise<RequestResponse<R, E>> => {
  return baseRequest<R, E>(
    () => {
      const token = nexupRequestConfig?.getAccessToken();
      return {
        timeout: getTimeOutConfig(options),
        ...options,
        params: pickBy(options.params, notUndefined),
        baseURL: nexupRequestConfig?.getApiRootUrl(),
        headers: pickBy(
          {
            ...options.headers,
            [Header.Authorization]: getAuthorizationBearer(token),
            [Header.AcceptLanguage]: "es",
            [Header.AppVersion]: nexupRequestConfig?.version,
            [Header.DeviceType]: nexupRequestConfig?.deviceType
          },
          notUndefined
        )
      };
    },
    getManageError(true),
    responseValidator,
    errorValidator
  );
};
export const requestAnon = async <R, E = DefaultError>(
  options: RequestConfig,
  responseValidator?: Validator<R>,
  errorValidator?: Validator<E>
): Promise<RequestResponse<R, E>> => {
  return baseRequest<R, E>(
    () => ({
      baseURL: nexupRequestConfig?.getApiRootUrl(),
      timeout: getTimeOutConfig(options),
      ...options,
      headers: omit(options.headers, Header.Authorization)
    }),
    getManageError(false),
    responseValidator,
    errorValidator
  );
};
export const requestMultipart = async <R, E = DefaultError>(
  options: RequestConfig,
  responseValidator?: Validator<R>,
  errorValidator?: Validator<E>
): Promise<RequestResponse<R, E>> => {
  return request<R, E>(
    {
      ...options,
      baseURL: nexupRequestConfig?.getApiRootUrl(),
      headers: {
        ...options.headers,
        [Header.ContentType]: ContentTypeHeader.Multipart
      }
    },
    responseValidator,
    errorValidator
  );
};

export const getAppBaseUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getAppBaseUrl?.() ?? "", url);

export const getApiUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getApiRootUrl(), url);

export const getFrontApiUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getApiRootUrl(), "front", url);

export const getFrontPatientPortalApiUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getApiRootUrl(), "patient", url);

export const getReportApiUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getApiRootUrl(), "report", url);

export const getFrontPublicApiUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getApiRootUrl(), "public", url);

export const getIntegrationApiUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getApiRootUrl(), "integration", url);

export const getCommunicationApiUrl = (url: string): string =>
  urlJoin(nexupRequestConfig?.getApiRootUrl(), "communication", url);

export const getProvisionalApiUrl = (url: string): string =>
  urlJoin(
    nexupRequestConfig?.getApiProvisionalRootUrl?.() ??
      nexupRequestConfig?.getApiRootUrl(),
    url
  );

export const getInternalApiUrl = (url: string): string => {
  const apiUrl = nexupRequestConfig?.getApiRootUrl() ?? "";
  const fixedApiUrl = apiUrl.includes("/api")
    ? apiUrl.slice(0, apiUrl.indexOf("/api"))
    : apiUrl;
  return urlJoin(fixedApiUrl, "internal", url);
};

export const updateNexupRequestDeviceType = (deviceType?: string): void => {
  if (nexupRequestConfig) nexupRequestConfig.deviceType = deviceType;
};
