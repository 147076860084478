import { Buffer } from "buffer";
import { ContentTypeHeader } from "@laba/ts-common";
// Downloads files stored in memory, such as those received from the backend, to the user's device
export const saveBlobToDevice = (blob: Blob, filename: string): void => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const newBlob = new Blob([blob], { type: blob.type ?? "" });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const blobToBuffer = async (blob: Blob): Promise<Buffer> => {
  const blobContent = await blob.arrayBuffer();
  const blobData = new Uint8Array(blobContent);
  return Buffer.from(blobData);
};

export const saveJsonToDevice = (jsonObj: object, filename: string): void =>
  saveBlobToDevice(
    new Blob([JSON.stringify(jsonObj, null, 2)], {
      type: ContentTypeHeader.Json
    }),
    filename
  );
