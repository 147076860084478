import { ThemeProvider } from "@material-ui/core/styles";
import { useMuiTheme } from "model/useMuiTheme";
import {
  FC,
  getClassName,
  OnBlurEvent,
  stopPropagationWrapper,
  TypeVariant,
  useTheme,
  withMemo
} from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {
  applyCurrLocaleFormatToDate,
  currentLocale,
  DateTime,
  getDateFormatFromLocale,
  Optional,
  setDateToMidnight
} from "@laba/ts-common";
import { FormControl, FormHelperText } from "@material-ui/core";
import { CloseIcon, EventIcon } from "components/icons";
import {
  getDateTimeOverrides,
  useDateEndIconStyle,
  useDateInputEndButtonStyles,
  useDateInputStyles,
  useDateOutlinedInputStyles,
  useInputLabelStyle
} from "components/inputs/DateInput/DateInputStyle";
import { Theme } from "model/theme";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isNil } from "lodash-es";

type DateInputTypeVariant = TypeVariant.Outlined | TypeVariant.Contained;

export interface DateInputProps {
  style?: StyleVariant;
  className?: string;
  value?: DateTime;
  onChange: (d?: DateTime) => void;
  onBlur?: OnBlurEvent<HTMLInputElement | HTMLTextAreaElement>;
  isMobile?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  textFormat?: string;
  disabled?: boolean;
  errorText?: string;
  helperText?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  variant?: DateInputTypeVariant;
  clearable?: boolean;
  clearText?: string;
  shouldDisableDate?: (date?: DateTime) => boolean;
}

const materialUiDateToDateTime = (
  date: MaterialUiPickersDate
): Optional<DateTime> => {
  if (isNil(date)) return undefined;
  const stringDate = date.toString();
  return DateTime.fromApiDate(stringDate);
};

const DateInputInt: FC<DateInputProps> = ({
  onChange,
  onBlur,
  className,
  errorText,
  helperText,
  value,
  minDate,
  maxDate,
  label,
  placeholder,
  fullWidth = false,
  textFormat = getDateFormatFromLocale(),
  disabled = false,
  style = StyleVariant.Primary,
  showError = true,
  showHelperOrErrorText = true,
  variant = TypeVariant.Outlined,
  clearable = false,
  clearText,
  shouldDisableDate
}) => {
  const theme = useTheme<Theme>();
  const muiTheme = useMuiTheme(style, getDateTimeOverrides(theme));
  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const classes = useDateInputStyles({ hasError, fullWidth });
  const labelClasses = useInputLabelStyle();
  const outlinedInputClasses = useDateOutlinedInputStyles({
    disabled,
    variant
  });
  const endIconClasses = useDateEndIconStyle({ style });
  const endButtonClasses = useDateInputEndButtonStyles({ disabled, hasError });

  const shouldDisableDateWrapper = (date: MaterialUiPickersDate) => {
    return shouldDisableDate?.(materialUiDateToDateTime(date)) ?? false;
  };
  return (
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider locale={currentLocale} utils={LuxonUtils}>
        <FormControl className={getClassName(classes.container, className)}>
          <KeyboardDatePicker
            color="primary"
            disabled={disabled}
            shouldDisableDate={shouldDisableDateWrapper}
            variant="inline"
            inputVariant="outlined"
            label={label}
            InputLabelProps={{
              classes: labelClasses
            }}
            InputProps={{
              classes: outlinedInputClasses
            }}
            keyboardIcon={
              <>
                {clearable && value && !disabled && (
                  <CloseIcon
                    titleAccess={clearText}
                    onClick={stopPropagationWrapper(() => onChange(undefined))}
                    className={endButtonClasses.closeIcon}
                  />
                )}
                <EventIcon className={endButtonClasses.endButton} />
              </>
            }
            KeyboardButtonProps={{
              edge: "end",
              classes: endIconClasses,
              disableRipple: true
            }}
            format={textFormat}
            value={value ?? null}
            minDate={minDate}
            maxDate={maxDate}
            onChange={newValue => {
              onChange(newValue ? setDateToMidnight(newValue) : undefined);
            }}
            onBlur={onBlur}
            autoOk
            size="small"
            error={hasError}
            helperText={null}
            fullWidth={fullWidth}
            placeholder={placeholder}
            title={applyCurrLocaleFormatToDate(value)}
          />
          {showHelperOrErrorText && (
            <FormHelperText className={classes.errorText} margin="dense">
              {showableHelperText}
            </FormHelperText>
          )}
        </FormControl>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export const DateInput = withMemo(DateInputInt);
