import { ResourceType } from "model/primitives/resourceModel";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { EmailConfig } from "model/primitives/emailConfig";
import {
  ActivityDefinitionKind,
  ActivityDefinition
} from "../activity/activityDefinition";
import { CodeSystemSystem } from "../entities/codeSystem";
import { HealthcareService } from "../entities/healthcareService/healthcareService";

export enum AppointmentDefinitionTemplateCode {
  AppointmentCreatedPractitionerPhone = "AppointmentCreatedPractitionerPhone",
  AppointmentCreatedPractitionerEmailTitle = "AppointmentCreatedPractitionerEmailTitle",
  AppointmentCreatedPractitionerEmailBody = "AppointmentCreatedPractitionerEmailBody",
  AppointmentCreatedPatientPhone = "AppointmentCreatedPatientPhone",
  AppointmentCreatedPatientEmailTitle = "AppointmentCreatedPatientEmailTitle",
  AppointmentCreatedPatientEmailBody = "AppointmentCreatedPatientEmailBody",
  AppointmentEditedPractitionerPhone = "AppointmentEditedPractitionerPhone",
  AppointmentEditedPractitionerEmailTitle = "AppointmentEditedPractitionerEmailTitle",
  AppointmentEditedPractitionerEmailBody = "AppointmentEditedPractitionerEmailBody",
  AppointmentEditedPatientPhone = "AppointmentEditedPatientPhone",
  AppointmentEditedPatientEmailTitle = "AppointmentEditedPatientEmailTitle",
  AppointmentEditedPatientEmailBody = "AppointmentEditedPatientEmailBody",
  AppointmentCancelledPractitionerPhone = "AppointmentCancelledPractitionerPhone",
  AppointmentCancelledPractitionerEmailTitle = "AppointmentCancelledPractitionerEmailTitle",
  AppointmentCancelledPractitionerEmailBody = "AppointmentCancelledPractitionerEmailBody",
  AppointmentCancelledPatientPhone = "AppointmentCancelledPatientPhone",
  AppointmentCancelledPatientEmailTitle = "AppointmentCancelledPatientEmailTitle",
  AppointmentCancelledPatientEmailBody = "AppointmentCancelledPatientEmailBody",
  PatientAppointmentAccessLink = "PatientAppointmentAccessLink"
}

export enum AppointmentDefinitionNotificationChannel {
  PatientEmail = "PatientEmail",
  PatientPhone = "PatientPhone",
  PractitionerEmail = "PractitionerEmail",
  PractitionerPhone = "PractitionerPhone"
}

//  TO-DO: HIS-11629-delete-when-old-appointment-is-deprecated
export interface AppointmentDefinitionTemplate {
  code: AppointmentDefinitionTemplateCode;
  template: string;
}

export const AppointmentDefinitionTemplateKey: KeyObj<AppointmentDefinitionTemplate> =
  getKeyObj(createHydratedMock<AppointmentDefinitionTemplate>());

export enum AppointmentType {
  VideoChat = "VideoChat",
  Presential = "Presential"
}

export interface AppointmentDefinition
  extends Omit<
    ActivityDefinition<
      ResourceType.Appointment,
      ActivityDefinitionKind.Appointment
    >,
    "templateList"
  > {
  code: AppointmentType;
  slotConfig: {
    minDuration?: number;
    durationList: number[];
  };
  consultationReasonSystem?: CodeSystemSystem;
  availableServiceList: ModelReference<HealthcareService>[];
  notificationChannelList: AppointmentDefinitionNotificationChannel[];
  templateList: AppointmentDefinitionTemplate[];
  emailConfig?: EmailConfig[];
}

export const AppointmentDefinitionKey: KeyObj<AppointmentDefinition> =
  getKeyObj(createHydratedMock<AppointmentDefinition>());
