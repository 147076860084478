import { PhoneValidationError, validatePhoneNumber } from "phone/validation";
import { isArgentinianPhone } from "phone/validation/argentinianPhoneValidation";
import { CountryAreaPhoneCode } from "phone/countryAreaPhoneCode";
import {
  fixArgentinianPhoneNumber,
  removeArgentineanPhoneInternalAreaCode
} from "phone/fixPhone/fixArgentinianPhoneNumber";
import { isEmpty } from "lodash-es";
import { Optional } from "model/types";
import {
  removeSpacesFromText,
  removeSymbolsFromTextExceptPlus
} from "utils/string";
import { getPhoneNumberCountryCode } from "phone/validation/utils";

export interface PhoneNumberFixResult {
  originalNumber: string;
  fixedNumber?: string;
  errorList?: PhoneValidationError[];
  isValid: boolean;
}

const fixArgentinianPhone = (
  phoneNumber: string,
  ignoreInternalCode = false
): PhoneNumberFixResult => {
  const fixedPhone = fixArgentinianPhoneNumber(phoneNumber, ignoreInternalCode);
  const errorList = validatePhoneNumber(
    fixedPhone,
    CountryAreaPhoneCode.Argentina
  );

  const filteredErrorList = ignoreInternalCode
    ? errorList.filter(
        error => error !== PhoneValidationError.InvalidArgentinaInternalAreaCode
      )
    : errorList;

  return {
    originalNumber: phoneNumber,
    fixedNumber: isEmpty(filteredErrorList) ? fixedPhone : undefined,
    errorList,
    isValid: isEmpty(filteredErrorList)
  };
};

const removeExtraPlusSignIfPresent = (phoneNumber: string): string => {
  const sanitized = phoneNumber.replace(/\+/g, ""); // Delete all '+'
  return `+${sanitized}`; // Add '+' at the beginning
};
const sanitizePhoneNumber = (phoneNumber: string): string => {
  const textWithoutSymbols = removeSymbolsFromTextExceptPlus(phoneNumber);
  const textWithoutExtraPlusSign =
    removeExtraPlusSignIfPresent(textWithoutSymbols);
  return removeSpacesFromText(textWithoutExtraPlusSign);
};

export const fixPhoneNumber = (
  phoneNumber: string,
  defaultCountryCode?: CountryAreaPhoneCode,
  ignoreInternalCode = false
): PhoneNumberFixResult => {
  const countryCode = getPhoneNumberCountryCode(
    phoneNumber,
    defaultCountryCode
  );
  const sanitizedNumber = sanitizePhoneNumber(phoneNumber);
  const errorList = validatePhoneNumber(sanitizedNumber, countryCode);
  if (isEmpty(errorList)) {
    if (isArgentinianPhone(sanitizedNumber) && ignoreInternalCode) {
      return {
        originalNumber: phoneNumber,
        fixedNumber: removeArgentineanPhoneInternalAreaCode(sanitizedNumber),
        isValid: true
      };
    }
    return {
      originalNumber: phoneNumber,
      fixedNumber: sanitizedNumber,
      isValid: true
    };
  }
  if (
    isArgentinianPhone(sanitizedNumber) ||
    countryCode === CountryAreaPhoneCode.Argentina
  ) {
    return fixArgentinianPhone(sanitizedNumber, ignoreInternalCode);
  }
  return {
    originalNumber: sanitizedNumber,
    fixedNumber: isEmpty(errorList) ? sanitizedNumber : undefined,
    errorList,
    isValid: isEmpty(errorList)
  };
};

export const fixPhoneNumberOrUndefined = (
  phoneNumber: string,
  countryCode?: CountryAreaPhoneCode,
  ignoreInternalCode = false
): Optional<string> => {
  return fixPhoneNumber(phoneNumber, countryCode, ignoreInternalCode)
    .fixedNumber;
};
