import { useGetElementSize, UseGetElementSizeRef } from "hooks/useMeasure";
import { useWindowSize } from "react-use";

export interface SecondaryVideoPositionAndSizeReturnData {
  divRef: UseGetElementSizeRef;
  secondaryVideoTopPosition: number;
  secondaryVideoRightPosition: number;
  secondaryVideoDesktopWidth: number;
  secondaryVideoMobileHeight: number;
}

export const useGetVideoCallSecondaryVideoPositionAndSize = (
  isMobile: boolean
): SecondaryVideoPositionAndSizeReturnData => {
  const [bounds, divRef] = useGetElementSize();
  const { width: screenWidth } = useWindowSize();

  const secondaryVideoTopPosition =
    (bounds?.top ?? 0) + (bounds?.height ?? 0) * 0.03;

  const secondaryVideoRightPosition =
    screenWidth - ((bounds?.right ?? 0) - (bounds?.width ?? 0) * 0.03);

  const secondaryVideoDesktopWidth = isMobile
    ? (bounds?.width ?? 0) * 0.3
    : (bounds?.width ?? 0) * 0.2;

  const secondaryVideoMobileHeight = isMobile
    ? (bounds?.height ?? 0) * 0.3
    : (bounds?.height ?? 0) * 0.2;

  return {
    divRef,
    secondaryVideoTopPosition,
    secondaryVideoRightPosition,
    secondaryVideoDesktopWidth,
    secondaryVideoMobileHeight
  };
};
