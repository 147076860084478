import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  Appointment,
  AppointmentKey,
  NotificationLastVersion,
  NotificationLastVersionParamsKey,
  NotificationListQueryParamsKey,
  NotificationParamsKey,
  NotificationStatus,
  OrganizationCombinedType,
  OrganizationListQueryParamsKey,
  Patient,
  Practitioner,
  ReceiverType,
  ResourceType
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { TextField } from "components/generic/TextField/TextField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { ExternalResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { DateField } from "components/generic/DateField/DateField";
import { getDefaultJsonToModel } from "components/generic/ResourceAction/ResourceAction";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { TextInput } from "components/generic/TextInput/TextInput";
import {
  appointmentOptionText,
  patientOptionText,
  practitionerOptionText
} from "components/generic/ReferenceResourceInput/optionText";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";

const tk = tkCP.adminPage[ExternalResourceType.Notification];
export const NotificationList: FC = () => {
  const { t } = useI18n();
  const exportedFileName = useListExportedFileName(t(tk.title));

  return (
    <List
      resource={ExternalResourceType.Notification}
      exportedFileName={exportedFileName}
      jsonToModel={getDefaultJsonToModel()}
      extraFilters={[
        <TextInput
          source={NotificationListQueryParamsKey.id}
          label={t(tk.filters.id)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <EnumSelectorInput
          label={t(tk.filters.status)}
          source={NotificationListQueryParamsKey.status}
          enumValue={NotificationStatus}
          alwaysOn
          resettable
        />,
        <TextInput
          source={NotificationListQueryParamsKey.receiver}
          label={t(tk.filters.receiver)}
          alwaysOn
          clearAlwaysVisible
          resettable
        />,
        <EnumSelectorInput
          label={t(tk.filters.receiverType)}
          source={NotificationListQueryParamsKey.receiverType}
          enumValue={ReceiverType}
          alwaysOn
          resettable
        />,
        <OrganizationReferenceInput
          label={t(tk.filters.organization)}
          source={NotificationListQueryParamsKey.organizationId}
          type={OrganizationCombinedType.ProviderAndGroup}
          extraFilters={{
            [OrganizationListQueryParamsKey.active]: true
          }}
          alwaysOn
        />,
        <ReferenceResourceInput<Patient>
          resourceType={ResourceType.Patient}
          label={t(tk.filters.patient)}
          source={NotificationListQueryParamsKey.patientId}
          optionTextKey={patientOptionText}
          alwaysOn
        />,
        <ReferenceResourceInput<Practitioner>
          resourceType={ResourceType.Practitioner}
          label={t(tk.filters.practitioner)}
          source={NotificationListQueryParamsKey.practitionerId}
          optionTextKey={practitionerOptionText}
          alwaysOn
        />,
        <ReferenceResourceInput<Appointment>
          resourceType={ResourceType.Appointment}
          label={t(tk.filters.appointment)}
          source={NotificationListQueryParamsKey.appointmentId}
          optionTextKey={appointmentOptionText}
          alwaysOn
          searchFilterKey={AppointmentKey.id}
        />
      ]}
    >
      <Datagrid
        sx={{
          "& .column-messageBody": { "max-width": "600px" }
        }}
      >
        <FunctionField<NotificationLastVersion>
          label={t(tk.fields.sender)}
          render={record => `${record?.senderId} (${record?.receiverType})`}
        />
        <TextField
          source={NotificationParamsKey.receiver}
          label={t(tk.fields.receiver)}
        />
        <TextField
          source={NotificationParamsKey.messageBody}
          label={t(tk.fields.messageBody)}
          sx={{ "overflow-wrap": "break-word" }}
        />
        <TextField
          source={NotificationParamsKey.status}
          label={t(tk.fields.status)}
        />
        <DateField
          source={NotificationParamsKey.scheduledDate}
          label={t(tk.fields.scheduledDate)}
          showTime
        />
        <DateField
          source={NotificationLastVersionParamsKey.lastUpdatedDate}
          label={t(tk.fields.lastUpdatedDate)}
          showTime
        />
      </Datagrid>
    </List>
  );
};
