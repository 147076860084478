import { getModelOrUndefined } from "model/primitives/modelReference/utils";
import { Optional } from "@laba/ts-common";
import { head } from "lodash-es";
import { AppointmentEncounterStatus } from "api/appointment/appointmentWithExtraData";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import { KnownScheduleType } from "model/resource/schedule";
import { Appointment, AppointmentStatus } from "./appointment";
import { AppointmentWithExtraData } from "./appointmentWithExtraData";

export const isAppointmentBooked = (appointment?: Appointment): boolean =>
  appointment?.status === AppointmentStatus.Booked;

export const isAppointmentCancelled = (appointment?: Appointment): boolean =>
  appointment?.status === AppointmentStatus.Cancelled;

export const isAppointmentFulfilled = (appointment?: Appointment): boolean =>
  appointment?.status === AppointmentStatus.Fulfilled;

export const isAppointmentVirtual = (appointment?: Appointment): boolean => {
  return appointment?.type === KnownScheduleType.VideoChat;
};

export const isVideoChatAppointment = (
  appointmentWithExtraData: AppointmentWithExtraData
): boolean => {
  return isAppointmentVirtual(appointmentWithExtraData.appointment);
};

export const getFirstAppointmentSpeciality = (
  appointment?: Appointment
): Optional<string> => {
  return head(appointment?.speciality);
};

export const getAppointmentEncounterStatus = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<AppointmentEncounterStatus> => {
  const appointmentStatus = appointmentWithExtraData?.appointment?.status;
  const encounterStatus = getModelOrUndefined(
    appointmentWithExtraData?.encounter
  )?.status;
  if (
    encounterStatus === EncounterStatus.Arrived ||
    appointmentStatus === AppointmentStatus.Arrived
  )
    return AppointmentEncounterStatus.Admitted;
  if (
    encounterStatus === EncounterStatus.InProgress ||
    encounterStatus === EncounterStatus.Triaged
  )
    return AppointmentEncounterStatus.InProgress;
  if (
    appointmentStatus === AppointmentStatus.Booked &&
    encounterStatus === undefined
  )
    return AppointmentEncounterStatus.Booked;
  if (encounterStatus === EncounterStatus.Finished)
    return AppointmentEncounterStatus.Finished;
  if (appointmentStatus === AppointmentStatus.Cancelled)
    return AppointmentEncounterStatus.Cancelled;
  return AppointmentEncounterStatus.Cancelled;
};
