import { Property } from "csstype";
import { pxToRem } from "model/style/dimension";

export enum FontConfigCase {
  None = "none",
  UpperCase = "uppercase"
}

export interface TextMixinFontConfig {
  fontFamily: string[];
  fontSize: number;
  fontWeight: number;
  case: FontConfigCase;
  letterSpacing?: number;
}

export interface TextMixinExtraConfig {
  maxLines?: number;
  showLineBreaks?: boolean;
}

export interface TextMixinConfig
  extends TextMixinFontConfig,
    TextMixinExtraConfig {}

export interface TextMixinResult {
  "font-style"?: Property.FontStyle;
  "font-variant"?: Property.FontVariant;
  "font-weight"?: Property.FontWeight;
  "font-stretch"?: Property.FontStretch;
  "font-size"?: Property.FontSize;
  "font-family"?: Property.FontFamily;
  "line-height"?: Property.LineHeight;
  margin?: Property.Margin;
  "letter-spacing"?: Property.LetterSpacing;
  overflow?: Property.Overflow;
  "text-overflow"?: Property.TextOverflow;
  "white-space"?: Property.WhiteSpace;
  display?: Property.Display;
  "overflow-wrap"?: Property.OverflowWrap;
  "-webkit-line-clamp"?: Property.WebkitLineClamp;
  "-webkit-box-orient"?: Property.BoxOrient;
  "text-transform"?: Property.TextTransform;
}

export const textMixinWithoutFont = (maxLines?: number): TextMixinResult => {
  const result: TextMixinResult = {
    margin: 0,
    overflow: "hidden"
  };
  if (maxLines == null) {
    result["overflow-wrap"] = "break-word";
  } else if (maxLines === 1) {
    result["white-space"] = "nowrap";
    result["text-overflow"] = "ellipsis";
  } else {
    result["overflow-wrap"] = "break-word";

    result.display = "-webkit-box";
    result["-webkit-line-clamp"] = maxLines;
    result["-webkit-box-orient"] = "vertical";
  }
  return result;
};

export const textMixinWithoutMaxLine = (
  config: TextMixinConfig
): TextMixinResult => {
  return {
    "font-family": config.fontFamily.join(", "),
    "font-size": pxToRem(config.fontSize),
    "font-weight": config.fontWeight,
    "line-height": "normal",
    "letter-spacing":
      config.letterSpacing == null ? "normal" : pxToRem(config.letterSpacing),
    "text-transform": config.case
  };
};

const showLineBreaksMixin = () => {
  return {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap"
  };
};

export enum TextAlign {
  Left = "left",
  Right = "right",
  Justify = "justify",
  Center = "center",
  Start = "start",
  End = "end"
}

export const textMixin = (config: TextMixinConfig): TextMixinResult => {
  return {
    ...textMixinWithoutFont(config.maxLines),
    ...textMixinWithoutMaxLine(config),
    ...(config.showLineBreaks && showLineBreaksMixin())
  };
};

interface UnselectableTextResult {
  // Prevents user from selecting text by double-clicking items
  "-webkit-touch-callout"?: Property.WebkitTouchCallout;
  "user-select"?: Property.UserSelect;
  "-webkit-user-select"?: Property.UserSelect;
  "-khtml-user-select"?: Property.UserSelect;
  "-moz-user-select"?: Property.UserSelect;
  "-ms-user-select"?: Property.MsUserSelect;
}

export const useUnselectableTextMixin = (): UnselectableTextResult => ({
  "-ms-user-select": "none",
  "-webkit-touch-callout": "none",
  "user-select": "none"
});
