import { notUndefined } from "@laba/ts-common";
import { isEmpty } from "lodash-es";

export const getRedirectEmailLink = (
  email: string,
  emailSubject?: string,
  emailBody?: string
): string => {
  const emailStr = `mailto:${email}`;
  const subject = emailSubject ? `subject=${emailSubject}` : undefined;
  const body = emailBody ? `body=${emailBody}` : undefined;
  const params = [subject, body].filter(notUndefined).join("&");

  return isEmpty(params) ? emailStr : `${emailStr}?${params}`;
};
