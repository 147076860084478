import { FC, useI18n } from "@laba/react-common";
import React from "react";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useWatchResourceForm } from "components/hook/useResourceContext";
import { ActivityDefinitionCommonInput } from "components/generic/ActivityDefinition/ActivityDefinitionCommonInput/ActivityDefinitionCommonInput";
import { useSelector } from "react-redux";
import {
  AppointmentNotificationConfigCodes,
  getModelReferenceId,
  ModelReference,
  Organization,
  ResourceType,
  ScheduleDefinitionKey,
  ScheduleFormDataParamsKey
} from "@laba/nexup-api";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { tkCP } from "translation/i18n";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";

export const tk = tkCP.adminPage[ResourceType.ScheduleDefinition];

export const ScheduleDefinitionMainContent: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const organization = useWatchResourceForm<ModelReference<Organization>>(
    ScheduleFormDataParamsKey.organization
  );
  const organizationId =
    getModelReferenceId(organization) || workspaceOrganizationId;

  return (
    <>
      <ActivityDefinitionCommonInput organizationId={organizationId} />
      <ArrayInput
        source={ScheduleDefinitionKey.notificationConfig.practitioner.codeList}
        label={t(tk.fields.notificationConfigPractitioner.codeList)}
      >
        <EnumSelectorInput
          source=""
          label={t(tk.fields.notificationConfigPractitioner.code)}
          enumValue={AppointmentNotificationConfigCodes}
          withCreate
        />
      </ArrayInput>
      <BooleanInput
        source={
          ScheduleDefinitionKey.notificationConfig.practitioner.notifyPhone
        }
        label={t(tk.fields.notificationConfigPractitioner.notifyPhone)}
      />
      <BooleanInput
        source={
          ScheduleDefinitionKey.notificationConfig.practitioner.notifyEmail
        }
        label={t(tk.fields.notificationConfigPractitioner.notifyEmail)}
      />
      <BooleanInput
        source={ScheduleDefinitionKey.notificationConfig.practitioner.active}
        label={t(tk.fields.notificationConfigPractitioner.active)}
      />
      <ArrayInput
        source={
          ScheduleDefinitionKey.notificationConfig.practitioner.reminderList
        }
        label={t(tk.fields.notificationConfigPractitioner.reminderList)}
      >
        <NumberInput
          source=""
          label={t(tk.fields.notificationConfigPractitioner.reminder)}
          min={0}
          step={1}
        />
      </ArrayInput>
      <ArrayInput
        source={ScheduleDefinitionKey.notificationConfig.patient.codeList}
        label={t(tk.fields.notificationConfigPatient.codeList)}
      >
        <EnumSelectorInput
          source=""
          label={t(tk.fields.notificationConfigPractitioner.code)}
          enumValue={AppointmentNotificationConfigCodes}
          withCreate
        />
      </ArrayInput>
      <BooleanInput
        source={ScheduleDefinitionKey.notificationConfig.patient.notifyPhone}
        label={t(tk.fields.notificationConfigPatient.notifyPhone)}
      />
      <BooleanInput
        source={ScheduleDefinitionKey.notificationConfig.patient.notifyEmail}
        label={t(tk.fields.notificationConfigPatient.notifyEmail)}
      />
      <BooleanInput
        source={ScheduleDefinitionKey.notificationConfig.patient.active}
        label={t(tk.fields.notificationConfigPatient.active)}
      />
      <ArrayInput
        source={ScheduleDefinitionKey.notificationConfig.patient.reminderList}
        label={t(tk.fields.notificationConfigPatient.reminderList)}
      >
        <NumberInput
          source=""
          label={t(tk.fields.notificationConfigPatient.reminder)}
          min={0}
          step={1}
        />
      </ArrayInput>
    </>
  );
};
