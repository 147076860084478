import { ApiRequestResponse } from "request/types";
import { OrganizationInvitationData } from "model/resource/entities/organization";
import { getFrontPublicApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";

const practitionerBasePath = "/practitioner-invitation";

export const validatePractitionerInvitation = (
  invitation?: string
): Promise<ApiRequestResponse<OrganizationInvitationData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontPublicApiUrl(`${practitionerBasePath}/validate`),
    data: { invitation }
  });
