import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  flexRowMixin,
  getClassName,
  marginMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { getThemeStyleColor } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";
import { NexupColor } from "model/nexupColor";
import { profilePictureSizeMedium } from "components/profile/ProfilePicture/ProfilePictureStyle";

export interface UserMenuButtonStyleProps {
  style: StyleVariant;
  showProfileDataOnDropdown?: boolean;
}

interface UserMenuButtonStyleClassNames {
  profilePicture?: string;
  userContainer?: string;
  menu?: string;
}

const useUserMenuButtonStyleStatic = createStyle(
  (_theme: Theme) => ({
    profilePicture: {
      ...flexItemMixin({
        shrink: 0
      })
    },
    mobileMenuClassName: {
      ...paddingMixin({ bottom: 8 })
    },
    desktopMenuClassName: {
      ...paddingMixin({ topBottom: 8 })
    },
    menuClassName: {
      ...marginMixin({ top: 2 })
    }
  }),
  "UserMenuButtonStatic"
);

const useUserMenuButtonStyleDynamic = createStyle(
  (theme: Theme) => ({
    userContainer: (props: UserMenuButtonStyleProps) => ({
      backgroundColor: props.showProfileDataOnDropdown
        ? NexupColor.Transparent
        : getThemeStyleColor(
            theme,
            props.style,
            ThemeStyleColorVariant.Contrast
          ),
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: 16
      }),
      maxWidth: pxToRem(250),
      maxHeight: pxToRem(profilePictureSizeMedium)
    })
  }),
  "UserMenuButtonDynamic"
);

export const useUserMenuButtonStyle = (
  props: UserMenuButtonStyleProps
): UserMenuButtonStyleClassNames => {
  const staticClasses = useUserMenuButtonStyleStatic();
  const dynamicClasses = useUserMenuButtonStyleDynamic(props);

  return {
    profilePicture: staticClasses.profilePicture,
    userContainer: dynamicClasses.userContainer,
    menu: getClassName(
      staticClasses.menuClassName,
      props.showProfileDataOnDropdown
        ? staticClasses.mobileMenuClassName
        : staticClasses.desktopMenuClassName
    )
  };
};
