import { Property } from "csstype";
import { Dimension, getDimensionPropertyValue } from "model/style";

export enum FlexDirection {
  Column = "column",
  ColumnReverse = "column-reverse",
  Row = "row",
  RowReverse = "row-reverse"
}

export enum FlexWrap {
  Nowrap = "nowrap",
  Wrap = "wrap",
  WrapReverse = "wrap-reverse"
}

export enum FlexJustifyContent {
  FlexStart = "flex-start",
  FlexEnd = "flex-end",
  Center = "center",
  SpaceBetween = "space-between",
  SpaceAround = "space-around",
  SpaceEvenly = "space-evenly"
}

export enum FlexAlignItems {
  Stretch = "stretch",
  Center = "center",
  FlexStart = "flex-start",
  FlexEnd = "flex-end",
  Baseline = "baseline"
}

export enum FlexAlignSelf {
  Auto = "auto",
  Stretch = "stretch",
  Center = "center",
  FlexStart = "flex-start",
  FlexEnd = "flex-end",
  Baseline = "baseline"
}

export enum FlexAlignContent {
  Stretch = "stretch",
  Center = "center",
  FlexStart = "flex-start",
  FlexEnd = "flex-end",
  SpaceBetween = "space-between",
  SpaceAround = "space-around"
}

export interface FlexContainerResult {
  display?: Property.Display;
  "flex-direction"?: Property.FlexDirection;
  "flex-wrap"?: Property.FlexWrap;
  "justify-content"?: Property.JustifyContent;
  "align-items"?: Property.AlignItems;
  "align-content"?: Property.AlignContent;
  "min-width"?: Property.MinWidth;
  gap?: Dimension;
}

export interface FlexItemResult {
  "flex-grow"?: string;
  "flex-shrink"?: string;
  "flex-basis"?: string;
  "align-self"?: Property.AlignSelf;
  order?: string;
  "min-width"?: string;
  "min-height"?: string;
}

export interface FlexContainerBaseConfig {
  direction?: FlexDirection;
  wrap?: FlexWrap;
  justifyContent?: FlexJustifyContent;
  alignItems?: FlexAlignItems;
  alignContent?: FlexAlignContent;
  gap?: number;
}

export interface FlexItemConfig {
  grow?: number;
  shrink?: number;
  basis?: string;
  align?: FlexAlignSelf;
  order?: number;
}

const flexContainerMixin = (
  config: FlexContainerBaseConfig
): FlexContainerResult => {
  return {
    display: "flex",
    "flex-direction": config.direction,
    "flex-wrap": config.wrap,
    "justify-content": config.justifyContent,
    "align-items": config.alignItems,
    "align-content": config.alignContent,
    "min-width": 0,
    gap: getDimensionPropertyValue(config.gap || 0)
  };
};

export interface FlexContainerConfig
  extends Omit<FlexContainerBaseConfig, "direction" | "wrap"> {
  wrapContent?: boolean;
  reverseOrder?: boolean;
}

export const flexRowMixin = (
  config: FlexContainerConfig
): FlexContainerResult =>
  flexContainerMixin({
    justifyContent: FlexJustifyContent.FlexStart,
    alignItems: FlexAlignItems.Stretch,
    alignContent: FlexAlignContent.Stretch,
    ...config,
    direction: config.reverseOrder
      ? FlexDirection.RowReverse
      : FlexDirection.Row,
    wrap: config.wrapContent ? FlexWrap.Wrap : FlexWrap.Nowrap
  });

export const flexColumnMixin = (
  config: FlexContainerConfig
): FlexContainerResult =>
  flexContainerMixin({
    justifyContent: FlexJustifyContent.FlexStart,
    alignItems: FlexAlignItems.Stretch,
    alignContent: FlexAlignContent.Stretch,
    ...config,
    direction: config.reverseOrder
      ? FlexDirection.ColumnReverse
      : FlexDirection.Column,
    wrap: config.wrapContent ? FlexWrap.Wrap : FlexWrap.Nowrap
  });

export const flexItemMixin = (config: FlexItemConfig): FlexItemResult => {
  const safeConfig: FlexItemConfig = {
    grow: 0,
    shrink: 1,
    basis: "auto",
    align: FlexAlignSelf.Auto,
    ...config
  };
  return {
    "flex-grow": String(safeConfig.grow),
    "flex-shrink": String(safeConfig.shrink),
    "flex-basis": String(safeConfig.basis),
    "align-self": safeConfig.align,
    order: String(safeConfig.order),
    "min-width": 0 as unknown as string,
    "min-height": 0 as unknown as string
  };
};
