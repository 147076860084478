import { joinText, Optional } from "@laba/ts-common";

const asterisk = "*";

export const formatFieldTitle = (
  title?: string,
  required?: boolean
): Optional<string> => {
  if (!required) {
    return title;
  }
  if (!title?.endsWith(asterisk)) {
    return joinText([title, asterisk], "");
  }
  return title;
};
