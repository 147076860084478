import {
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface Props {
  titleVariant?: TextVariant;
  titleColorVariant?: ThemeStyleColorVariant;
  subTitleVariant?: TextVariant;
  subTitleColorVariant?: ThemeStyleColorVariant;
  gap?: number;
  isMobile?: boolean;
  fullWidth?: boolean;
}

interface GenericFieldWrapperStylesClassNames {
  root: string;
  title: string;
  subTitle: string;
  titleContainer: string;
  fieldHeading: string;
  urlLink: string;
}

const useGenericFieldWrapperStaticStyles = createStyle(
  (_theme: Theme) => ({
    rootMobile: {
      ...flexColumnMixin({ gap: 4 })
    },
    fieldHeading: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.SpaceBetween,
        alignItems: FlexAlignItems.Center,
        gap: 8
      })
    },
    titleContainer: {
      ...flexRowMixin({ gap: 4 })
    },
    urlLink: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexEnd })
    },
    fullWidth: {
      width: "100%"
    }
  }),
  "GenericFieldWrapperStatic"
);

const useGenericFieldWrapperDynamicStyles = createStyle(
  (theme: Theme) => ({
    rootDesktop: (props: Props) => ({
      ...flexColumnMixin({ gap: props.gap ?? 8 })
    }),
    title: (props: Props) => ({
      ...themeTextMixin(theme, props.titleVariant ?? TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.titleColorVariant ?? ThemeStyleColorVariant.GrayDark
      )
    }),
    subTitle: (props: Props) => ({
      ...themeTextMixin(
        theme,
        props.subTitleVariant ?? TextVariant.Subtitle2,
        1
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.subTitleColorVariant ?? ThemeStyleColorVariant.GrayLight
      )
    })
  }),
  "GenericFieldWrapperDynamic"
);

export const useGenericFieldWrapperStyles = (
  props: Props
): GenericFieldWrapperStylesClassNames => {
  const staticClasses = useGenericFieldWrapperStaticStyles();
  const dynamicClasses = useGenericFieldWrapperDynamicStyles(props);
  return {
    root: getClassName(
      props.isMobile ? staticClasses.rootMobile : dynamicClasses.rootDesktop,
      props.fullWidth ? staticClasses.fullWidth : undefined
    ),
    title: dynamicClasses.title,
    subTitle: dynamicClasses.subTitle,
    titleContainer: staticClasses.titleContainer,
    fieldHeading: staticClasses.fieldHeading,
    urlLink: staticClasses.urlLink
  };
};
