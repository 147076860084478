import {
  Location,
  LocationType
} from "model/resource/entities/location/location";
import {
  getCurrentDateTime,
  ModelId,
  Optional,
  Position
} from "@laba/ts-common";
import { currentHoursOfOperationIsAvailable } from "model/resource/utils/hoursOfOperation";
import {
  getModelOrUndefined,
  getModelReferenceId,
  ModelReference
} from "model/primitives";
import {
  getStatusFromHospitalizationWithExtraData,
  HospitalizationWithExtraData
} from "model/resource/medical";
import { head, isEqual } from "lodash-es";
import { Patient } from "model/resource/person";
import { LocationWithExtraData } from "model/resource/entities/location/locationWithExtraData";
import { EncounterLocation, EncounterStatus } from "../encounter";

export const isLocationAvailable = (location?: Location): boolean => {
  const currentDateTime = getCurrentDateTime();
  return (
    location?.hoursOfOperation?.some(hourOp =>
      currentHoursOfOperationIsAvailable(currentDateTime, hourOp)
    ) ?? false
  );
};

export const getLocationPosition = (
  location?: Location
): Optional<Position> => {
  if (!location) return undefined;
  return {
    latitude: location.latitude,
    longitude: location.longitude
  };
};

const isLocationPhysicalType = (
  physicalType: LocationType,
  location?: Location
): boolean => {
  return location?.type === physicalType;
};

export const isLocationBed = (location?: Location): boolean =>
  isLocationPhysicalType(LocationType.Bed, location);

export const isLocationBuilding = (location?: Location): boolean =>
  isLocationPhysicalType(LocationType.Building, location);

export const getHeadHospitalizationWithExtraDataFromlocationWithExtraData = (
  locationWithExtraData?: LocationWithExtraData
): Optional<HospitalizationWithExtraData> =>
  head(locationWithExtraData?.hospitalization);

export const getPatientModelFromLocationWithExtraData = (
  locationWithExtraData?: LocationWithExtraData
): Optional<Patient> =>
  getModelOrUndefined(
    getHeadHospitalizationWithExtraDataFromlocationWithExtraData(
      locationWithExtraData
    )?.hospitalization.patient
  );

export const getPatientIdFromLocationWithExtraData = (
  locationWithExtraData?: LocationWithExtraData
): Optional<ModelId> =>
  getModelReferenceId(
    getPatientModelFromLocationWithExtraData(locationWithExtraData)
  );

export const getFirstHospitalizationIdFromLocationWithExtraData = (
  locationWithExtraData?: LocationWithExtraData
): Optional<ModelId> =>
  getModelReferenceId(
    getHeadHospitalizationWithExtraDataFromlocationWithExtraData(
      locationWithExtraData
    )?.hospitalization
  );

export const getStatusFromLocationWithExtraDataFirstHospitalization = (
  locationWithExtraData?: LocationWithExtraData
): Optional<EncounterStatus> =>
  getStatusFromHospitalizationWithExtraData(
    getHeadHospitalizationWithExtraDataFromlocationWithExtraData(
      locationWithExtraData
    )
  );

export const findLocationByLocationType = (
  locationType: LocationType,
  locationList?: Location[]
): Optional<Location> =>
  locationList?.find(location => location.type === locationType);

export const findLocationFromEncounterLocationList = (
  locationList: EncounterLocation[],
  locationType: LocationType
): Optional<ModelReference<Location>> =>
  locationList.find(loc => isEqual(loc.physicalType, locationType))?.location;

export const getLocationName = (location?: Location): Optional<string> =>
  location?.name;
