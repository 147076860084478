export const schedule = {
  title: "Agenda",
  fields: {
    id: "Id",
    name: "Nombre",
    location: "Ubicacion",
    type: "Tipo",
    speciality: "Especialidad",
    performer: "Participante",
    planningHorizonStartDate: "Fecha de inicio",
    planningHorizonEndDate: "Fecha de finalizacion",
    organization: "Organización",
    availableTimeStartDate: "Fecha de inicio disponible",
    availableTimeEndDate: "Fecha de finalizacion disponible",
    practitioner: "Profesional",
    originalPractitioner: "Profesional original",
    active: "Activo",
    daysOfWeek: "Dias de la semana",
    allDay: "Todo el dia",
    availableTime: "Tiempo disponible",
    slotDuration: "Duracion slot"
  },
  tabs: {
    schedule: "Agendas"
  },
  filters: {
    organization: "Organizacion",
    practitioner: "Profesional",
    speciality: "Especialidad",
    active: "Activa"
  }
};
