import { head } from "lodash-es";
import { DateTime, Optional } from "@laba/ts-common";
import { Schedule } from "./schedule";

export const getFirstScheduleSpeciality = (
  schedule?: Schedule
): Optional<string> => {
  return head(schedule?.speciality);
};

export const getScheduleEndDateTime = (
  schedule?: Schedule
): Optional<DateTime> => {
  const endDate = schedule?.planningHorizon?.end;
  return DateTime.fromApiDateOrUndefined(endDate)?.startOf("day");
};

export const getScheduleEndDateFixed = (
  schedule?: Schedule
): Optional<DateTime> => {
  return getScheduleEndDateTime(schedule)?.minus({ days: 1 });
};

export const getScheduleStartDateTime = (
  schedule?: Schedule
): Optional<DateTime> => {
  const endDate = schedule?.planningHorizon?.start;
  return DateTime.fromApiDateOrUndefined(endDate)?.startOf("day");
};
