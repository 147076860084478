import { FCC, getClassName, IconC, ReactElement } from "@laba/react-common";
import React from "react";
import { SizeVariant, StyleVariant, TypeVariant } from "model/themeVariant";
import {
  ButtonType,
  DefaultButton
} from "components/buttons/DefaultButton/DefaultButton";
import { Noop } from "@laba/ts-common";
import { Divider, DividerSize } from "components/divider/Divider";
import { useCardStyleClasses } from "components/card/Card/CardStyle";
import { Spinner } from "components/spinners/Spinner/Spinner";
import { CardVariant } from "../cardVariants";

export interface CardProps {
  className?: string;
  childrenContainerClassName?: string;
  CardHeader?: ReactElement;
  primaryStyle?: StyleVariant;
  secondaryStyle?: StyleVariant;
  cardVariant?: CardVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: Noop;
  onSecondaryButtonClick?: Noop;
  PrimaryButtonIcon?: IconC;
  SecondaryButtonIcon?: IconC;
  fullWidth?: boolean;
  formId?: string;
  loading?: boolean;
  disablePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  isMobile?: boolean;
  withStickyHeader?: boolean;
  top?: number;
  showBottomButtons?: boolean;
}

export const Card: FCC<CardProps> = ({
  children,
  childrenContainerClassName,
  CardHeader,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  PrimaryButtonIcon,
  SecondaryButtonIcon,
  className,
  formId,
  isMobile,
  primaryStyle = StyleVariant.Primary,
  secondaryStyle = StyleVariant.Primary,
  cardVariant = CardVariant.Horizontal,
  fullWidth = false,
  disablePrimaryButton = false,
  disableSecondaryButton = false,
  loading = false,
  withStickyHeader = false,
  top,
  showBottomButtons = true
}) => {
  const showPrimaryButton =
    showBottomButtons &&
    Boolean(primaryButtonText) &&
    (Boolean(onPrimaryButtonClick) || Boolean(formId));
  const showSecondaryButton =
    showBottomButtons &&
    Boolean(secondaryButtonText) &&
    Boolean(onSecondaryButtonClick);
  const showButtons = showPrimaryButton || showSecondaryButton;

  const cardVariantValue = isMobile ? CardVariant.Vertical : cardVariant;

  const classes = useCardStyleClasses({
    style: primaryStyle,
    cardVariant: cardVariantValue,
    fullWidth,
    withStickyHeader,
    top,
    showBottomButtons: showButtons
  });

  return (
    <div className={getClassName(classes.root, className)}>
      <div className={classes.headerWrapper}>{CardHeader}</div>
      <div
        className={getClassName(
          classes.mainContent,
          childrenContainerClassName
        )}
      >
        {children}
        {loading && (
          <div className={classes.loadingScreen}>
            <Spinner />
          </div>
        )}
      </div>
      {showButtons && (
        <div className={classes.buttonAndDividerContainer}>
          {isMobile && <Divider size={DividerSize.Medium} />}
          <div
            className={
              cardVariantValue === CardVariant.Horizontal
                ? classes.horizontalButtonsContainer
                : classes.verticalButtonsContainer
            }
          >
            {showPrimaryButton && (
              <DefaultButton
                onClick={onPrimaryButtonClick}
                text={primaryButtonText}
                title={primaryButtonText}
                size={SizeVariant.Small}
                style={primaryStyle}
                StartIcon={PrimaryButtonIcon}
                formId={formId}
                buttonType={formId ? ButtonType.Submit : ButtonType.Button}
                disabled={disablePrimaryButton || loading}
              />
            )}
            {showSecondaryButton && (
              <DefaultButton
                className={classes.secondaryButton}
                onClick={onSecondaryButtonClick}
                text={secondaryButtonText}
                title={secondaryButtonText}
                size={SizeVariant.Small}
                type={TypeVariant.Outlined}
                style={secondaryStyle}
                StartIcon={SecondaryButtonIcon}
                disabled={disableSecondaryButton || loading}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
