import { Attachment } from "model/primitives/attachment/attachment";
import { Identifier } from "model/primitives/identifier";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ApiDate, getKeyObj, KeyObj, OpenCode } from "@laba/ts-common";
import { ResourceType } from "model/primitives/resourceModel";
import { createHydratedMock } from "ts-auto-mock";
import { User } from "model/resource/person/user";
import { Code } from "model/resource/entities/codeSystem";
import {
  Organization,
  OrganizationPayerCoverageStatus
} from "model/resource/entities/organization/organization";
import { BasePerson } from "model/resource/person/person";

export interface PatientWaitingListRecommendation {
  contact?: string;
  contactRelation?: string;
}

export interface PatientWaitingListData {
  recommendation?: PatientWaitingListRecommendation;
}

export enum KnownTaxType {
  ExemptTax = "IVA exento",
  VatTax = "IVA gravado"
}

export type TaxType = OpenCode<KnownTaxType>;

export interface PersonInCharge {
  firstName?: string;
  lastName?: string;
  relationship?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  birthDate?: string;
  legalIdentification?: Identifier;
  phoneHome?: string;
  emailHome?: string;
  profession?: string;
  employerOrganization?: string;
  jobPosition?: string;
  workCity?: string;
  workPostalCode?: string;
  workAddress?: string;
  workPhone?: string;
}

export interface HealthcarePayer {
  provider?: ModelReference<Organization>;
  credentialNumber?: string;
  credentialFiles?: Attachment[];
  plan?: string;
  status?: OrganizationPayerCoverageStatus;
  taxType?: TaxType;
}

export interface ManagedBy {
  id?: string;
  patient?: ModelReference<Patient>;
}

export const PatientManagedByKey: KeyObj<ManagedBy> = getKeyObj(
  createHydratedMock<ManagedBy>()
);

export interface PatientData {
  bloodGroup?: string;
  deathDate?: ApiDate;
  personInCharge?: PersonInCharge;
  healthcarePayer?: HealthcarePayer;
  medicalRecordId?: string;
  managedBy?: ManagedBy[];
}

export interface PatientRole {
  organization?: ModelReference<Organization>;
  role?: Code;
}

export const PatientRoleKey: KeyObj<PatientRole> = getKeyObj(
  createHydratedMock<PatientRole>()
);

export interface Patient extends BasePerson<ResourceType.Patient> {
  patientWaitingListData?: PatientWaitingListData;
  patientData?: PatientData;
  user?: ModelReference<User>;
  roleList?: PatientRole[];
  active?: boolean;
  organization: ModelReference<Organization>;
}

export const PatientKey: KeyObj<Patient> = getKeyObj(
  createHydratedMock<Patient>()
);
