import { GenericObject, isArray, isObject, notUndefined } from "model/types";
import { get, isEmpty, isEqual, isNil, isUndefined, merge } from "lodash-es";
import produce from "immer";

// eslint-disable-next-line @typescript-eslint/ban-types
type Obj = object;

const flattenArray = (array: unknown[]): GenericObject => {
  const result: GenericObject = {};
  array.forEach((v, i) => {
    if (!isObject(v) && !isArray(v)) {
      result[`${i}`] = v;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const temp = isObject(v) ? flattenObj(v) : flattenArray(v);
      Object.keys(temp).forEach(k => {
        result[`${i}.${k}`] = temp[k];
      });
    }
  });

  return result;
};

// TODO HIS-6787: check and improve typing
export const flattenObj = <T extends Obj, R = T>(ob: T): R => {
  const result: GenericObject = {};
  Object.keys(ob).forEach(key => {
    const o = get(ob, key);
    if (!isObject(o) && !isArray(o)) {
      result[key] = o;
    } else {
      const temp = isObject(o) ? flattenObj(o) : flattenArray(o);
      Object.keys(temp).forEach(key2 => {
        result[`${key}.${key2}`] = temp[key2];
      });
    }
  });

  return result as R;
};

const cleanUndefinedDeepInt = (draftObj: GenericObject) => {
  Object.keys(draftObj).forEach(key => {
    // Get this value and its type
    const value = draftObj[key];
    if (isObject(value)) {
      // Recurse...
      cleanUndefinedDeepInt(value);
      // ...and remove if now "empty" (NOTE: insert your definition of "empty" here)
      if (!Object.keys(value).length) {
        delete draftObj[key];
      }
    } else if (isArray(value)) {
      value.forEach(
        arrayValue => isObject(arrayValue) && cleanUndefinedDeepInt(arrayValue)
      );
      draftObj[key] = value.filter(notUndefined);
      if (isEmpty(draftObj[key])) {
        delete draftObj[key];
      }
    } else if (isNil(value)) {
      // Undefined, remove it
      delete draftObj[key];
    }
  });
};

export const cleanUndefinedDeep = <T extends Obj>(obj: T): T =>
  produce(obj, cleanUndefinedDeepInt);

export const isEqualWithoutUndefined = (a: unknown, b: unknown): boolean =>
  isEqual(
    isObject(a) ? cleanUndefinedDeep(a) : a,
    isObject(b) ? cleanUndefinedDeep(b) : b
  );

export const mergeObjects = <V extends Obj>(
  defaultObject: V,
  sourceObject?: V
): V => {
  if (isUndefined(sourceObject)) {
    return defaultObject;
  }
  const sourceObjectWithoutUndefined = cleanUndefinedDeep(sourceObject);
  const mergedObject = produce(defaultObject, draft =>
    merge(draft, sourceObjectWithoutUndefined)
  );

  return mergedObject;
};
